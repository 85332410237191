:root {
  --invoice-summary-sidebar-width: 420px;
}

.invoice-print-header {
  display: none;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 20px;
}

.invoice-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: calc(var(--margin-md) * 2);
}

.invoice-header>.address-column {
  flex: 1
}

.invoice-header .details-row {
  display: flex;

}

.invoice-header .details-row.info {
  cursor: help;
}

.invoice-header .details-row.info:hover {
  text-decoration: underline;
  color: var(--primary-color);
}

.invoice-header .details-row>.detail-label {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  margin-right: var(--margin-md);
  font-weight: var(--light-bold-font-weight);
  text-transform: uppercase;
}

.invoice-header .details-row>.detail-label>.fa {
  margin-right: 5px;
}

.invoice-header .details-row>.detail-value {
  display: inline-flex;
  width: 120px;
}

.clinic-invoices .invoice-tabs {
  padding-top: var(--padding-lg);
}

.table.invoices-table>tbody>tr {
  cursor: pointer;
}

.table.invoices-table>tbody>tr:hover {
  background-color: var(--primary-highlight-color);
}

table.line-items-table {
  margin-bottom: 100px;
}

.table.line-items-table>tbody {
  border-bottom: var(--table-border-width) var(--table-border-style) var(--table-border-color);
}

table.line-items-table>thead>tr>th {
  background-color: var(--background-color);
  padding-top: 10px;
  padding-bottom: 10px;
}

table.line-items-table>tbody>tr>td {
  border-bottom: var(--table-border-width) var(--table-border-style) var(--table-border-color);
}

table.line-items-table>tbody>tr.focused {
  outline: var(--primary-color) auto 4px;
}

table.line-items-table>tbody>tr.expandable-row {
  cursor: pointer;
}

table.line-items-table>tbody>tr.expandable-row:hover,
table.line-items-table>tbody>tr.expandable-row.opened {
  background-color: var(--primary-color);
  color: #FFF;
}

table.line-items-table>tbody>tr.expandable-row>td>.table-row-toggle {
  position: absolute;
  left: calc(var(--padding-lg) * -1);
  padding-right: var(--padding-md);
  padding-left: var(--padding-md);
  margin-top: 2px;
  color: var(--primary-color);
  line-height: var(--h3-line-height);
  font-size: 18px;
}

table.line-items-table>tbody>tr>td:last-child {
  text-align: right;
}

/* TABLE - table footer */
table.line-items-table tr.line-items-table-footer {
  background-color: var(--white-dark);
  border: none;
}

/* stylelint-disable-next-line */
table.line-items-table .line-items-table-footer>td {
  text-align: right;
  border-left: var(--table-border-width) var(--table-border-style) var(--table-border-color);
  border-bottom: none;
  border-top: none;
}

table.line-items-table .line-items-table-footer>td:last-child {
  border-right: var(--table-border-width) var(--table-border-style) var(--table-border-color);
  padding-right: var(--padding-lg);
}

table.line-items-table .line-items-table-footer.balance-row {
  background-color: var(--primary-color);
  color: #fff;
}

.invoice-title-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.invoice-title-row>h1 {
  display: inline-flex;
}

.invoice-actions {
  display: flex;
  align-items: center;
}

.invoice-actions .invoice-action {
  margin-bottom: var(--margin-lg);
  margin-top: var(--margin-lg);
}

.invoice-actions .invoice-action:not(:last-of-type) {
  margin-right: var(--margin-md);
}

.invoice-actions .invoice-action .btn-sm {
  padding: 3px var(--padding-md);
}

/** Admin line item actions **/
.line-item-action-trigger {
  position: absolute;
  left: -10px;
  height: 20px;
  width: 20px;
  font-size: 14px;
  background: none;
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.line-item-action-trigger:hover {
  background-color: var(--grey-light);
}

.line-item-action-trigger.selected {
  background-color: var(--black-color);
  color: #fff;
}

.line-item-action-trigger:focus {
  outline: none;
  box-shadow: 0 0 0 3px var(--primary-highlight-color);
}

ul.line-item-action-menu {
  padding: 0;
  list-style: none;
  margin: 0;
  background-color: var(--background-color);
}

ul.line-item-action-menu>li {
  padding: 10px;
  cursor: pointer;
}

ul.line-item-action-menu>li:hover {
  background-color: var(--grey-light);
}

/* Add Line Items Drawer */
.line-item-drawer {
  width: 500px;
  background-color: var(--foreground-color);
  height: 100%;
  padding-top: var(--padding-lg);
}

.line-item-drawer .line-item-drawer-content {
  padding-left: var(--padding-lg);
  padding-right: var(--padding-lg);
  padding-top: calc(var(--padding-lg) * 4);
}

/* Invoice Actions */
ul.invoice-action-menu {
  padding: 0;
  list-style: none;
  margin: 0;
  background-color: var(--foreground-color);
}

ul.invoice-action-menu>li {
  padding: 10px;
  cursor: pointer;
}

ul.invoice-action-menu>li:hover {
  background-color: var(--grey-light);
}

/* Invoice Notes */
.invoice-note {
  border-bottom: 1px solid var(--grey-color);
  margin-bottom: var(--margin-lg);
}

.invoice-note h3 {
  font-weight: 600;
  margin-bottom: var(--margin-md);
  margin-top: var(--margin-sm);
  display: flex;
  align-items: center;
}

.invoice-note h3 button {
  margin-left: 10px;
}

.invoice-note .note {
  padding-left: var(--padding-md);
  white-space: pre-wrap;
}

.modal-view.close-invoice-modal .modal-view-content,
.modal-view.update-invoice-modal .modal-view-content {
  width: 50%;
}

.invoice-payment-page {
  background-color: var(--white-dark);
  height: 100vh;
  width: 100vw;
  justify-content: center;
  display: flex;
  align-items: center;
}

.invoice-payment-page .activity .activity-container.active {
  opacity: 0.5;
}

.invoice-payment-page .activity .activity-overlay.visible {
  background-color: transparent;
}

.invoice-payment-page .error-view {
  text-align: center;
}

.invoice-payment-page .error-view .error-view-icon {
  font-size: 50px;
  margin-bottom: 15px;
  color: var(--error-color);
}

.invoice-payment-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  background-color: var(--background-color);
}

.invoice-payment-container .payment-section {
  height: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.invoice-payment-container .payment-section>.logo {
  position: absolute;
  top: 0;
  left: 0;
  margin: var(--margin-lg);
}

.invoice-payment-container .payment-section>.logo>img {
  height: 60px;
}

.invoice-payment-container .payment-section .stripe-payment-container {
  width: 40vw;
  padding: var(--padding-lg);
}

.invoice-payment-container .invoice-summary-sidebar {
  width: var(--invoice-summary-sidebar-width);
  height: 100vh;
  background-color: var(--foreground-color);
  border-left: 1px solid var(--grey-light);
  display: flex;
  flex-direction: column;
}

.invoice-summary-sidebar .summary-header {
  padding: 10px 15px;
  border-bottom: 1px solid var(--grey-color);
}

.invoice-summary-sidebar .summary-header>h1 {
  margin: 0;
}

.invoice-summary-sidebar .summary-line-items {
  flex: 1;
  overflow-y: scroll;
  list-style: none;
  margin: 0;
  padding: 0;
}

.invoice-summary-sidebar ul.summary-line-items>li.item {
  padding: 10px 15px;
}

.invoice-summary-sidebar ul.summary-line-items>li.item:not(:last-child) {
  border-bottom: 1px solid var(--grey-light);
}

.invoice-summary-sidebar ul.summary-line-items>li.item .item-row {
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.invoice-summary-sidebar .summary-footer {
  padding: 10px;
  border-top: 1px solid var(--grey-color);
}

.invoice-summary-sidebar .summary-footer .footer-row {
  display: flex;
  padding: 5px 15px;
  align-items: center;
  text-align: right;
}

.invoice-summary-sidebar .summary-footer .footer-row.large {
  font-size: 20px;
}

.invoice-summary-sidebar .summary-footer .footer-row>.row-label {
  flex: 1;
  justify-content: flex-end;
}

.invoice-summary-sidebar .summary-footer .footer-row>.row-value {
  width: 30%;
}

.paid-invoice-view {
  text-align: center;
}

.paid-invoice-view>.paid-invoice-icon {
  font-size: 50px;
  margin-bottom: 15px;
}

@media (max-width: 850px) {
  .invoice-payment-container .invoice-summary-sidebar {
    display: none;
  }

  .invoice-payment-container .payment-section .stripe-payment-container {
    width: 100%;
  }
}