:root {
  --impact-list-height: 275px;
}

.impact-recipients {
  overflow: hidden;
  padding: 15px 0;
  border-bottom: 1px solid var(--grey-light);
  border-top: 1px solid var(--grey-light);
}

.impact-recipients .impact-recipients-list {
  height: var(--impact-list-height);
  overflow: scroll;
}

.impact-recipients h3 {
  margin-bottom: var(--margin-sm);
}

.impact-csv-drop-area {
  position: relative;
  margin: 15px 0;
  padding: 0 10px;
  border: 2px dashed transparent;
  border-radius: var(--standard-border-radius);
}

.impact-csv-drop-area.over {
  border-color: var(--grey-color);
  background-color: var(--white-dark);
}

.impact-recipients-list .recipient {
  padding: 10px 15px;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
}

.impact-recipients-list .recipient:hover {
  background-color: var(--white-dark);
}

.impact-recipients-list .recipient:not(:last-child) {
  border-bottom: 1px solid var(--grey-color);
}

.impact-recipients-list .recipient .recipient-info {
  white-space: nowrap;
  overflow: hidden;
  display: block;
  width: 95%;
  text-overflow: ellipsis;
}

.impact-recipients-list .recipient .remove-recipient-button {
  appearance: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  height: 20px;
  width: 20px;
  border-radius: 20px;
  @extend .caption;
  color: var(--error-color);
}

.impact-recipients-list .recipient .remove-recipient-button:hover {
  background-color: var(--error-color);
  color: #fff;
}

.impact-recipients-list .recipient .remove-recipient-button:focus {
  outline: none;
}

.bulk-impact-baselines-purchase .submit-bulk-impact {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin-bottom: calc(var(--margin-lg) + var(--margin-sm));
}

.bulk-impact-baselines-purchase .recipients-download-button {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-top: var(--margin-lg);
}

.bulk-impact-baselines-purchase .purchase-description {
  padding-bottom: calc(var(--padding-sm) * 4);
}

.bulk-impact-baselines-popover {
  padding: 3px 0px;
}

.bulk-impact-baselines-popover .recipient-item {
  padding: 5px 10px;
  font-size: 12px;
}

.success-icon-container {
  display: flex;
  flex: 1;
  justify-content: center;
  margin-top: calc(var(--margin-lg) * 4);
  margin-bottom: calc(var(--margin-sm) * 4);
  color: var(--grey-light);
}

.success-icon-container .success-icon-stack {
  position: relative;
  font-size: 80px;
}

.success-icon-container .success-icon-stack .success-icon {
  position: absolute;
  bottom: -5px;
  right: -15px;
  font-size: 35px;
  color: var(--success-color);
}