@media print {

  .symptoms-list .symptom {
    margin-bottom: var(--margin-sm);
  }

  .symptoms-list>.row {
    page-break-inside: avoid;
  }

}