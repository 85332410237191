.injury-report-header {
  padding: var(--padding-sm);
}

.injury-report-section-row label {
  padding-right: var(--padding-sm);
}

.injury-report-display .fa {
  width: 22px;
  padding-right: var(--padding-sm);
}

.injury-report-display h1.display {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.no-display {
  display: none;
}

.user-injury-reports table>tbody>tr>td {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.injury-reports-test-section {
  margin-bottom: calc(var(--margin-lg) * 2);
}

.injury-report-information .injury-information-row {
  margin-bottom: var(--margin-lg);
}

.injury-information-row .injury-information-item label {
  min-width: 75%;
}

.injury-information-row .injury-information-item span {
  text-transform: capitalize;
}

.injury-information-row .injury-information-item .injury-information-item,
.injury-information-row .injury-information-item>p {
  padding-left: calc(var(--padding-sm) * 4);
  display: flex;
}

.injury-information-row .injury-information-item>p {
  margin-bottom: 0;
}

.injury-information-row .injury-information-item .injury-information-item label {
  font-weight: normal;
  min-width: 50%;
}

.injury-information-row .injury-information-item .injury-information-item span {
  text-transform: none;
}