:root {
  --user-list-item-height: 60px;
  --user-avatar-image-margins: 10px;
  --form-user-avatar-dimensions: 80px;
  --remembered-user-box-shadow-hover: 0px 2px 10px 1px rgba(12, 24, 38, 0.33);
}

.authentication h1,
.password-reset-request h1,
.password-reset h1 {
  text-align: center;
  color: var(--black-color);
}

.authentication .authentication-sales {
  text-align: center;
}

form.authentication-form .switch-accounts {
  @extend small;
  font-weight: bold;
}

form.authentication-form .form-footer,
form.password-reset-request-form .form-footer,
form.registration-returning-account-form .form-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

form.authentication-form .form-footer>a,
form.password-reset-request-form .form-footer>a,
form.registration-returning-account-form .form-footer>a {
  @extend .caption;
  /* flex: 1; */
  font-size: var(--semi-small-font-size);
  margin-right: var(--margin-lg);
  text-align: right;
}

.authentication .patient-registration-info {
  text-align: center;
  margin-bottom: var(--margin-sm);
}

.remembered-user-list {
  margin-bottom: var(--margin-lg);
}

.remembered-user-list .user-list-group {
  margin-bottom: var(--margin-sm);
}

.remembered-user-list .user-list-group .user-list-item {
  height: var(--user-list-item-height);
  position: relative;
  cursor: pointer;
  border-bottom: 1px solid var(--grey-light);
}

.remembered-user-list .user-list-group .user-list-item .remove-list-item {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  right: 10px;
  top: 10px;
  z-index: 1500;
  font-size: 12px;
}

.remembered-user-list .user-list-group .user-list-item .remove-list-item:hover {
  background-color: rgba(0, 0, 0, 0.65);
  color: #fff;
}

.remembered-user-list .user-list-group .user-list-item .user-list-content {
  width: 100%;
  display: flex;
  align-items: center;
}

.remembered-user-list .user-list-group .user-list-item .user-avatar {
  height: var(--user-list-item-height);
  width: var(--user-list-item-height);
  position: relative;
}

.remembered-user-list .user-list-group .user-list-item .user-avatar img {
  margin: var(--user-avatar-image-margins);
  border-radius: 50%;
  width: calc(100% - (var(--user-avatar-image-margins) * 2));
  height: calc(100% - (var(--user-avatar-image-margins) * 2));
}

.remembered-user-list .user-list-group .user-list-item:hover {
  background-color: var(--white-dark);
}

.remembered-user-list .user-list-group .user-list-item:hover .remove-list-item {
  visibility: visible;
}

.users-authentication .new-login-button {
  margin-bottom: var(--margin-lg);
  text-align: center;
}

.users-authentication {
  margin-top: var(--margin-lg);
}

.users-authentication-form .user-meta {
  text-align: center;
  position: relative;
}

.users-authentication-form .user-meta button.btn.list-return-button {
  height: 26px;
  width: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: none;
  background-color: var(--grey-light);
  color: var(--black-color);
  font-size: var(--semi-small-font-size);
  outline: none;
  box-shadow: none;
  text-decoration: none;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: calc(var(--margin-sm) * 2);
  padding: 0;
}

.users-authentication-form .user-meta button.btn.list-return-button:hover {
  background-color: var(--white-dark);
}

.users-authentication-form .user-meta .user-avatar {
  width: var(--form-user-avatar-dimensions);
  height: var(--form-user-avatar-dimensions);
  margin-right: auto;
  margin-left: auto;
  position: relative;
}

.users-authentication-form .user-meta .user-avatar img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
}

.mfa-verification-form {
  max-width: 420px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: var(--padding-md);
}

.mfa-verification-form .alert.error {
  margin: 0;
}

.mfa-verification-form .form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: var(--padding-md);
}

.mfa-verification-form .form .otp-input-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--padding-md);
}

.mfa-verification-form .form .form-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0;
}