:root {
  --switch-track-height: 20px;
  --switch-track-padding: 2px;
  --switch-toggle-diameter: 16px;
  --switch-track-width: calc((var(--switch-toggle-diameter) * 2) + (var(--switch-track-padding) * 2));
}

.switch {
  display: inline-block;
  border-radius: 20px;
  opacity: 1;
  cursor: pointer;
  width: var(--switch-track-width);
  margin-bottom: 0;
}

.switch.disabled {
  opacity: 0.4;
  cursor: not-allowed;
}

.switch > input[type=checkbox] {
  pointer-events: none;
  opacity: 0;
  position: absolute;
  margin: 0;
}

.switch > .track {
  position: relative;
  height: var(--switch-track-height);
  width: var(--switch-track-width);
  border-radius: var(--switch-track-height);
  padding: var(--switch-track-padding);
  margin: 2px;
  background-color: var(--grey-color);
  transition: background-color .1s linear;
}

.switch.toggled > .track {
  background-color: var(--success-color);
}

.switch > .track > .toggle {
  background-color: #fff;
  content: "";
  position: absolute;
  height: var(--switch-toggle-diameter);
  width: var(--switch-toggle-diameter);
  border-radius: 50%;
  pointer-events: none;
  transition: transform 0.1s linear;
}

.switch.toggled > .track > .toggle {
  transform: translateX(var(--switch-toggle-diameter));
}
