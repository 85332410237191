.print-meta {
  @extend small;
  border-bottom-width: var(--standard-border-width);
  border-bottom-style: var(--standard-border-style);
  border-bottom-color: var(--standard-border-color);
  display: none;
}

.print-meta ul {
  margin-bottom: var(--margin-sm);
}