.symptoms-list {
  margin-bottom: var(--margin-lg);
}

.symptoms-list .symptom {
  border-bottom-width: var(--standard-border-width);
  border-bottom-style: var(--standard-border-style);
  border-bottom-color: var(--standard-border-color);
  margin-bottom: var(--margin-lg);
}

.symptoms-list .symptom .symptom-meta {
  display: flex;
  align-items: center;
  margin-bottom: var(--margin-sm);
}

.symptoms-list .symptom .symptom-meta h3 {
  margin-top: 0;
  margin-bottom: 0;
}

.symptoms-list .symptom .symptom-meta h3:first-child {
  flex: 1;
}

.symptoms-list .symptom .notes {
  border-top-width: var(--standard-border-width);
  border-top-style: var(--standard-border-style);
  border-top-color: var(--standard-border-color);
  padding-top: var(--padding-sm);
  padding-bottom: var(--padding-sm);
  margin: 0;
}