.policy-scroll-box .policy-content {
  height: 300px;
  background-color: var(--foreground-color);
  overflow-x: hidden;
  overflow-y: auto;
  border-color: var(--grey-light);
  border-style: var(--input-border-style);
  border-width: var(--input-border-width);
  border-radius: var(--input-border-radius);
  padding-left: var(--padding-lg);
  padding-right: var(--padding-lg);
  margin-bottom: var(--margin-lg);
  margin-top: var(--margin-lg);
}

.policy-scroll-box .policy-content .policy-scroll {
  overflow: hidden;
}

.policy-scroll-box .policy-footer>p {
  margin-bottom: var(--margin-sm);
}