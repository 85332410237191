:root {
  --alert-notification-banner-max-height: 100px;
}

.alert {
  background-color: transparent;
  margin-bottom: var(--margin-lg);
}

.alert.alert-info {
  border-color: var(--primary-color);
  color: var(--primary-color);
}

.alert.alert-success {
  border-color: var(--success-color);
  color: var(--success-color);
}

.alert.alert-warning {
  border-color: var(--warning-color);
  color: var(--warning-color);
}

.alert.alert-danger {
  border-color: var(--error-color);
  color: var(--error-color);
}

.alert-container {
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  height: auto;
  z-index: 99999;
}

.alert-container:empty {
  display: none;
}

.alert-container .alert-banner {
  margin-top: var(--margin-sm);
}

.alert-banner {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 0;
  max-height: 0;
  padding: var(--padding-lg);
  color: #FFF;
  opacity: 0;
  transition: opacity 250ms linear, max-height 200ms linear;
  font-size: var(--h2-font-size);
  line-height: var(--h2-line-height);
  overflow: hidden;
}

.alert-banner.centered .alert-banner-message {
  text-align: center;
  flex: 1;
}

.alert-banner.visible {
  opacity: 1;
  height: auto;
  max-height: var(--alert-notification-banner-max-height);
}

.alert-banner.visible.clickable {
  cursor: pointer;
}

.alert-banner.visible.success {
  background-color: var(--success-color);
}

.alert-banner.visible.info {
  background-color: var(--primary-color);
}

.alert-banner.visible.warning {
  background-color: var(--warning-color);
}

.alert-banner.visible.error {
  background-color: var(--error-color);
}

.alert-banner.visible button.close {
  color: #fff;
  opacity: 1;
  text-shadow: none;
}