@media print {
  .table.line-items-table {
    font-size: 12px;
    display: table;
  }

  .invoice-print-header {
    display: flex;
  }
}
