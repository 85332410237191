.buffalo-test-container {
  margin-bottom: calc(var(--margin-lg) * 2);
}

.buffalo-test-instructions-quote {
  padding-top: calc(var(--padding-lg) * 2);
  padding-bottom: calc(var(--padding-lg) * 2);
}

.buffalo-test-instructions-quote p {
  margin-bottom: 0;
}

.buffalo-test-form .buffalo-instructions {
  text-align: center;
  color: var(--primary-color);
  padding: var(--padding-lg);
}

.buffalo-test-form .buffalo-table {
  margin-bottom: 0;
}

.buffalo-test-form .cool-down-row {
  background-color: var(--primary-color);
  color: #FFF;
  font-weight: var(--bold-font-weight);
  margin-bottom: calc(var(--margin-lg) * 2);
  text-align: center;
}

.buffalo-test-form .cool-down-row>p {
  padding: var(--padding-sm);
}

.buffalo-test-form .symptom-exacerbation {
  padding-bottom: var(--padding-lg);
}

.buffalo-test-display .buffalo-test-view h2 {
  margin-top: 0;
}

.buffalo-test-display .buffalo-test-view .buffalo-test-header {
  margin-bottom: var(--margin-lg);
}

/* VERSION 2 */
.buffalo-test-v2-form .pre-test-scores {
  padding: calc(var(--padding-sm) * 2);
  margin-bottom: var(--margin-lg);
  background-color: var(--primary-color);
  border-radius: var(--standard-border-radius);
  transition: var(--transition-duration);
}

.buffalo-test-v2-form .pre-test-scores .form-group>label,
.buffalo-test-v2-form .pre-test-scores .form-group>.form-input-message,
.buffalo-test-v2-form .pre-test-scores>p {
  color: #fff;
}

.buffalo-test-v2-form .pre-test-scores.complete {
  background-color: inherit;
}

.buffalo-test-v2-form .pre-test-scores.complete>p {
  display: none;
}

.buffalo-test-v2-form .pre-test-scores.complete .form-group>label,
.buffalo-test-v2-form .pre-test-scores.complete .form-group>.form-input-message,
.buffalo-test-v2-form .pre-test-scores.complete>p {
  color: inherit;
}

.buffalo-test-v2-form .buffalo-table-v2 tfoot {
  border-right: 1px solid var(--standard-border-color);
  border-left: 1px solid var(--standard-border-color);
  border-bottom: 1px solid var(--standard-border-color);
}

.buffalo-test-v2-form .buffalo-table-v2 tfoot>tr button.edit-button {
  width: 100%;
  padding-top: calc(var(--padding-sm) * 2);
  padding-bottom: calc(var(--padding-sm) * 2);
}

.buffalo-test-v2-form .buffalo-table-v2 tfoot>tr>td {
  padding: 0px;
  border-top: none;
  vertical-align: middle;
}

.buffalo-test-v2-form .buffalo-table-v2 tfoot>tr button.edit-button:focus {
  outline: none;
}

.buffalo-test-v2-form .buffalo-table-v2 tfoot>tr {
  background-color: var(--background-color);
  transition: var(--transition-duration);
}

.buffalo-test-v2-form .buffalo-table-v2 tfoot>tr:hover {
  background-color: var(--primary-color);
}

.buffalo-test-v2-form .buffalo-table-v2 tfoot>tr:hover button.edit-button {
  color: #FFF;
}

.buffalo-test-v2-form table.buffalo-table-v2>thead>tr>th {
  padding-top: calc(var(--padding-sm) * 2);
  background-color: var(--dark-mask-color);
}

.buffalo-test-v2-form .symptom-exacerbation {
  padding-bottom: var(--padding-lg);
}

.buffalo-test-v2-form .symptom-exacerbation .symptom-exacerbation-inputs {
  display: flex;
  align-items: baseline;
}

.buffalo-test-help-link {
  padding-left: var(--padding-sm);
}

.borg-scale-image {
  width: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

table.buffalo-test-table,
table.buffalo-test-exacerbation-table {
  table-layout: fixed;
}

@media (max-width: 991px) {
  .buffalo-test-v2-form .symptom-exacerbation .symptom-exacerbation-inputs {
    display: block;
  }
}