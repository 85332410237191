@media print {

  .user-baseline-detail .baseline-section {
    margin-bottom: var(--margin-lg);
    break-inside: avoid;
  }

  .user-baseline-detail .user-baseline-test-selection {
    display: none;
  }

}
