:root {
  --clinic-deactivated-banner-max-height: 40px;
}

.clinic-deactivated-banner-container {
  max-height: var(--clinic-deactivated-banner-max-height);
  margin-bottom: calc(var(--clinic-deactivated-banner-max-height) + var(--margin-lg));
}

.clinic-deactivated-banner-container>.clinic-deactivated-banner {
  position: fixed;
  left: 0;
  top: var(--navigation-bar-height);
  background-color: var(--primary-color);
  width: 100%;
  z-index: 200;
}

.clinic-deactivated-banner-container>.clinic-deactivated-banner>.banner-content {
  padding: var(--padding-sm) var(--padding-lg);
}

.clinic-deactivated-banner-container>.clinic-deactivated-banner>.banner-content>h2 {
  margin: var(--margin-sm) 0;
  color: #fff;
}