.injury-stage-display .injury-stage-view {
  padding: 0;
}

.soap-symptom-display>div>div>div {
  padding-bottom: var(--padding-sm);
}

.score-view-score-display {
  flex: 0;
  color: var(--primary-color);
  font-size: var(--score-view-score-font-size);
  line-height: var(--score-view-score-line-height);
  font-weight: bold;
}

.soap-notes-list .soap-notes-list-header {
  margin-bottom: var(--margin-lg);
  border-bottom-width: var(--standard-border-width);
  border-bottom-style: var(--standard-border-style);
  border-bottom-color: var(--standard-border-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.soap-notes-list-content .soap-list-row {
  display: flex;
  justify-content: flex-start;
  padding-bottom: var(--padding-sm);
  margin-bottom: var(--margin-sm);
  width: 100%;
}

.soap-notes-list-content .soap-list-row>.soap-list-row-item {
  flex: 1;
}

.soap-symptoms-section .soap-symptoms-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: var(--margin-lg);
}

.note-plan-display .col-md-3 {
  padding-left: 0;
}

.plan-display-section .fa {
  width: 20px;
}

.plan-display-section .plan-item:not(.checked) {
  color: var(--black-light);
}

.injury-stage-display .bold {
  padding-right: var(--padding-sm);
}

.soap-notes-display .fa {
  width: 22px;
  padding-right: var(--padding-sm);
}

.note-section-display .bold {
  padding-right: var(--padding-sm);
}

.note-section-display .notes {
  padding-bottom: var(--padding-md);
}

.note-section-display .notes h2 {
  border-bottom: none;
}

.note-section-display .notes:not(.markdown) {
  white-space: pre-wrap;
}

ul.notes-navigation {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-top: var(--margin-lg)
}

ul.notes-navigation>.notes-nav-item {
  padding: var(--padding-sm) var(--padding-lg);
  width: 100%;
  margin-bottom: var(--margin-sm);
}

ul.notes-navigation>.notes-nav-item.active-item,
ul.notes-navigation>.notes-nav-item:hover {
  background-color: var(--primary-color);
  color: #fff;
  border-radius: var(--standard-border-radius);
  cursor: pointer;
  font-weight: var(--bold-font-weight);
}

.soap-symptom-score-display {
  float: right;
}

.soap-plan-section {
  margin-bottom: calc(var(--margin-lg) * 2);
}

.soap-plan-section .row {
  margin-bottom: var(--margin-sm);
}

.soap-plan-section textarea {
  min-width: 100%;
}

/* Objective Section Tabs */
.objective-tabs.tabs .tabs-bar {
  border-bottom: var(--standard-border-width) solid var(--grey-color);
  margin-top: calc(var(--margin-lg) * 2);
  margin-bottom: 0;
  margin-left: var(--margin-lg);
}

.objective-tabs.tabs .tabs-bar button.tab-button {
  padding: var(--padding-sm) var(--padding-lg);
  margin: 0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
  background-color: transparent;
  color: var(--tab-button-color);
  font-weight: var(--tab-button-font-weight);
}

.objective-tabs.tabs .tabs-bar button.tab-button:active,
.objective-tabs.tabs .tabs-bar button.tab-button:focus {
  outline: none;
}

.objective-tabs.tabs .tabs-bar button.tab-button.selected {
  background-color: var(--grey-light);
  border-bottom-color: transparent;
}

.objective-tabs.tabs .tabs-content .tab-component.selected {
  padding: var(--padding-lg);
  margin-bottom: var(--margin-lg);
}

/******************************************************************/

.soap-test-btn-group {
  display: flex;
  margin-top: 0;
  margin-bottom: calc(var(--margin-lg) * 2);
}

.soap-test-btn-group .btn {
  flex: 1;
}

.soap-objective-form {
  padding: var(--padding-lg);
}

.dropdown.soap-dropdown,
.dropdown.soap-dropdown button,
.dropdown.soap-dropdown ul {
  width: 100%;
}

.soap-form-display h2 {
  border-bottom: 1px solid var(--standard-border-color);
}

.soap-panels h2.soap-panel-header {
  margin-bottom: 0;
  margin-top: 0;
}

.soap-panels .panel>.panel-heading.collapse-toggle {
  background-color: var(--white-dark);
  z-index: 1
}

.soap-panels .panel>.panel-heading.collapse-toggle.opened {
  background-color: var(--grey-light);
}

.soap-panels .panel>.panel-heading.sticky {
  position: sticky;
}

.soap-panels .panel.disabled>.panel-heading.collapse-toggle {
  cursor: not-allowed;
}

.soap-note-stages-display {
  margin-bottom: var(--margin-lg);
}

.soap-panel-header-container {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
}

.soap-panel-header-container .soap-panel-header-row,
.soap-panel-header-container .soap-panel-header-test-badges {
  display: flex;
  flex: 1;
  align-items: center;
  flex-wrap: wrap;
}

.soap-panel-header-container .soap-panel-header-row {
  margin-top: var(--margin-xs);
}

.soap-panel-header-container .soap-panel-header-row .soap-creator-indicator {
  margin-right: var(--margin-md);
  margin-top: 2px;
  font-size: var(--semi-small-font-size);
}

.soap-panel-header-container .soap-panel-header-test-badges {
  margin-right: var(--margin-lg);
}

.panel-header-test-badge {
  height: 22px;
  width: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 11px;
  margin-left: 7px;
  margin-right: 7px;
  position: relative;
  box-shadow: var(--shadow-sm);
}

.panel-header-test-badge.buffalo {
  background-color: var(--primary-color);
}

.panel-header-test-badge.blackhawks {
  background-color: var(--chart-3-color);
}

.panel-header-test-badge.voms {
  background-color: var(--chart-4-color);
}

.panel-header-test-badge.ovs {
  background-color: var(--chart-1-color);
}

.panel-header-test-badge.baseline {
  background-color: var(--black-color);
}

.panel-header-test-badge.symptoms {
  background-color: var(--warning-color);
}

.panel-header-test-badge .test-badge-label {
  color: #fff;
  font-size: 10px;
  line-height: 10px;
  font-weight: bold;
}

.panel-header-test-badge .test-badge-indicator {
  position: absolute;
  right: -7px;
  bottom: -2px;
  height: 14px;
  width: 14px;
  border-radius: 7px;
  color: #fff;
  font-size: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: var(--shadow-sm);
}

.panel-header-test-badge .test-badge-indicator.success {
  background-color: var(--success-color);
}

.panel-header-test-badge .test-badge-indicator.error {
  background-color: var(--error-color);
}

.btn-back {
  height: 36px;
  width: 36px;
  border-radius: 50%;
  border: none;
  background-color: var(--grey-light);
  color: var(--black-color);
  outline: none;
  margin-right: 15px;
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-back:hover {
  background-color: var(--grey-color);
}

.btn-back:active {
  background-color: var(--blue-light);
}

.soap-notes-archive-list>.archive-list-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  border-bottom: 1px solid var(--grey-light);
  margin-bottom: 10px;
}

.soap-notes-archive-list>.archive-list-header>.header-title {
  display: flex;
  align-items: center;
}

.soap-note-list-actions {
  height: 100%;
  position: sticky;
  top: calc(var(--navigation-bar-height) + var(--padding-md));
}

.soap-note-action-button {
  height: 30px;
  width: 30px;
  border: none;
  border-radius: 50%;
  outline: none;
  background-color: var(--grey-light);
  color: var(--primary-color);
  box-shadow: none;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
}

.soap-note-action-button .fa {
  margin-top: 2px;
  margin-left: 1px;
}

.soap-note-action-button.selected {
  background-color: var(--black-color);
  color: #fff;
}

.soap-note-action-button.selected,
.soap-note-action-button:focus {
  box-shadow: 0 0 0 3px var(--blue-light);
}

.soap-panel-header-container .action-separator {
  height: 15px;
  width: 1px;
  background-color: var(--black-color);
  margin-right: var(--margin-md);
  margin-left: var(--margin-md);
}

.start-note-alert .start-note-alert-message {
  display: flex;
  flex-direction: column;
  gap: var(--padding-md);
}

.start-note-alert .start-note-alert-message .start-note-open-notes {
  display: flex;
  flex-direction: column;
  gap: var(--padding-sm);
}

.start-note-alert .start-note-alert-message .start-note-open-notes > .note-list-item {
  padding: calc(var(--padding-sm) + var(--padding-xs)) var(--padding-lg);
  border: 1px solid var(--grey-light);
  border-radius: var(--radius-lg);
  font-weight: 600;
}

@media (max-width: 482px) {
  .soap-notes-drawer .panel .panel-heading.sticky {
    top: 100px !important;
  }
}