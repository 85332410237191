:root {
  --card-heading-font-size: 22px;
  --card-padding: var(--padding-lg);
  --card-row-margin: var(--margin-sm);

  --card-form-half-width: calc(50% - (var(--margin-lg) / 2));
  --card-form-one-quarter-width: calc(25% - (var(--margin-lg) / 2));
  --card-form-three-quarter-width: calc(75% - (var(--margin-lg) / 2));
}

.card-container {
  margin-bottom: var(--margin-lg);
}

.card-container>.card-container-header {
  display: flex;
  justify-content: space-between;
}

.card-container>.card-container-header>h2 {
  width: auto;
  position: relative;
  top: calc(var(--card-heading-font-size) / 2);
  margin: 0 calc((var(--card-padding) / 3) * 2);
  padding: 0 calc(var(--card-padding) / 3);
  z-index: 1;
  font-size: var(--card-heading-font-size);
  line-height: 1;
  color: var(--primary-color);
  background-color: var(--background-color);
}

.card-container>.card-container-header button.edit-button {
  font-size: 14px;
}

.card-container>.card-container-body {
  padding-top: var(--card-padding);
  padding-bottom: var(--card-padding);
  border-width: 1px;
  border-style: solid;
  border-color: var(--grey-light);
  border-radius: var(--radius-md);
}

.card {
  padding: var(--card-padding);
}

.card.card-preview {
  display: flex;
}

.card.card-preview .card-preview-column h2 {
  padding: 0;
}

.card.card-preview .card-preview-column:not(:first-child) {
  flex: 1;
}

.card.card-preview .card-preview-column:first-child {
  width: 120px;
  margin-right: var(--margin-lg);
}

.card.card-preview .card-preview-column:first-child img {
  max-width: 100%;
  max-height: 100%;
}

.card.underline:not(:last-child) {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: var(--grey-light);
}

.card .card-row {
  display: flex;
  margin-bottom: var(--card-row-margin);
}

.card .card-row.center {
  justify-content: center;
}

.card .card-row.right {
  justify-content: flex-end;
}

.card .card-row.card-table-row {
  display: block;
}

.card .card-row.card-table-row table {
  width: 100%;
  margin-bottom: var(--margin-lg);
}

.card.card-show .card-row label {
  width: 33%;
  margin-right: var(--margin-lg);
  margin-bottom: 0;
  text-align: right;
  text-transform: uppercase;
}

.card.card-show .card-row span {
  flex: 1;
}

.card.card-show .card-row span.multi-line {
  white-space: pre-wrap;
}

.card.card-show .card-row.image-row {
  justify-content: space-around;
  margin-bottom: var(--margin-lg);
}

.card.card-preview h2 {
  margin-top: 0;
  margin-bottom: var(--card-row-margin);
}

.card.card-form {
  margin-bottom: 0;
}

.card.card-form .address-form-inputs .fields {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.card.card-form .address-form-inputs .fields .form-input-group {
  width: var(--card-form-half-width);
  flex-basis: var(--card-form-half-width);
}

.card.card-form .address-form-inputs .fields .form-input-group:first-child {
  width: var(--card-form-three-quarter-width);
  flex-basis: var(--card-form-three-quarter-width);
}

.card.card-form .address-form-inputs .fields .form-input-group:nth-child(2) {
  width: var(--card-form-one-quarter-width);
  flex-basis: var(--card-form-one-quarter-width);
}