.section {
  margin-bottom: var(--margin-lg);
  padding: var(--padding-lg);
  border-radius: var(--radius-md);
}

.section .section-title {
  font-weight: var(--bold-font-weight);
}

.section p:last-of-type {
  margin-bottom: 0;
}

.section.section-primary {
  background-color: var(--primary-color);
  color: #FFF;
}

.section.section-light {
  background-color: var(--white-dark);
  color: inherit;
}

.section.section-light .section-title {
  color: var(--primary-color);
}

.section.section-dark {
  background-color: var(--black-color);
  color: #FFF;
}

.section.section-dark .section-title {
  color: var(--primary-color);
}