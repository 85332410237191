.modal.referral-modal>.dialog {
  max-width: 100%;
  max-height: 100%;
  width: 95%;
  height: 90%;
  border-radius: 15px;
  border: none;
  overflow: hidden;
}

.modal.referral-modal>.dialog iframe {
  border: none;
  border-radius: 18px;
}

.modal.referral-modal>.dialog .activity,
.modal.referral-modal>.dialog .activity .activity-container {
  height: 100%;
}

.modal.referral-modal>.dialog .activity .activity-indicator-container .activity-indicator {
  font-size: 50px;
}

.modal.referral-modal .close-button {
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px;
  border: none;
  box-shadow: none;
  outline: none;
  font-size: 22px;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--grey-color);
}

.modal.referral-modal .close-button:hover {
  background-color: var(--black-color);
  color: #fff;
}

.modal.referral-modal .loading-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  font-size: 50px;
  color: var(--primary-color);
}

.modal.referral-modal .alert-banner {
  position: absolute;
  bottom: 0;
}

.modal.referral-message-modal textarea {
  max-width: 100%;
}

/* Referral Card */

.referral-card {
  border: 1px solid var(--grey-light);
  padding: var(--padding-md);
  border-radius: 8px;
  background-color: var(--foreground-color);
  margin-bottom: var(--margin-md);
}

.referral-card .referral-card-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 5px;
}

.referral-card-header .referral-date {
  font-size: var(--semi-small-font-size);
  line-height: var(--semi-small-line-height);
  font-weight: 500;
}

.referral-card-header .referral-status {
  display: flex;
  align-items: center;
}

.referral-card-header .referral-status button.btn-cancel {
  padding: 0;
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  border: none;
  outline: none;
  appearance: none;
  box-shadow: none;
  background: transparent;
  margin-left: 10px;
}

.referral-card-header .referral-status button.btn-cancel:hover {
  text-decoration: underline;
}

.referral-card .referral-card-details {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.referral-card-details .referral-view {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: var(--margin-sm);
}

.referral-card-details .referral-view .user-details {
  display: flex;
  align-items: center;
  padding: 5px;
  width: 200px;
  line-height: 1;
}

.referral-card-details .referral-view .user-details>.profile-photo>img {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  margin-right: 10px;
}

.referral-card-details .referral-view .user-details>.profile-photo>.fa {
  font-size: 30px;
  color: var(--grey-color);
  margin-right: 10px;
}

.referral-card-details .referral-view .user-details>.details {
  font-size: 12px;
}

.referral-card-details .referral-view .user-details>.details>.name {
  font-size: 16px;
  font-weight: 500;
}

.referral-card-details .referral-view .separator {
  margin-left: var(--margin-lg);
  margin-right: var(--margin-lg);
  font-size: 28px;
}

.referral-card-details .referrer-details {
  text-align: center;
  font-size: 14px;
}

.referral-card-details .referral-professions {
  font-size: 12px;
  text-align: center;
  margin-bottom: 5px;
}

.referral-card-details .referral-actions {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.referral-card .referral-card-footer .referral-message {
  margin-top: 10px;
}

.referral-card .referral-card-footer .referral-message>.message-label {
  font-weight: 600;
  font-size: var(--small-font-size);
  line-height: var(--small-line-height);
}

.referral-card .referral-card-footer .referral-message>.message-label button:focus {
  outline: none;
}

.referral-card .referral-card-footer .referral-message>.message-label button .fa {
  font-size: 8px;
}

.referral-card .referral-card-footer .referral-message>.note {
  white-space: pre-wrap;
}

/* Injury Referrals */
.tabs.referrals-tabs.vertical .tabs-bar {
  min-width: 260px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  border: 1px solid var(--grey-light);
  position: sticky;
  top: calc(var(--navigation-bar-height) + var(--margin-lg));
  margin-right: 0;
}

.tabs.referrals-tabs.vertical .tabs-content {
  padding-left: var(--padding-md);
  height: 100%;
}

.tabs.referrals-tabs.vertical .tabs-bar button.tab-button:not(:last-of-type) {
  border-bottom: 1px solid var(--grey-light);
}

.tabs.referrals-tabs.vertical .tabs-bar .referral-status {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

@media (max-width: 767px) {
  .tabs.referrals-tabs.vertical .tabs-bar {
    min-width: 130px;
  }
}