:root {
  --badge-profile-photo-size: 45px;
  --badge-profile-photo-radius: calc(var(--badge-profile-photo-size) / 2);
}

.patient-badge {
  display: flex;
  align-items: center;
  border-radius: var(--radius-md);
  border: 1px solid var(--grey-light);
  padding: var(--padding-sm) var(--margin-md);
}

.patient-badge .profile-photo {
  margin-right: var(--margin-md);
  width: var(--badge-profile-photo-size);
  height: var(--badge-profile-photo-size);
  border-radius: var(--badge-profile-photo-radius);
}

.patient-badge .profile-photo>img {
  width: var(--badge-profile-photo-size);
  height: var(--badge-profile-photo-size);
}

.patient-badge .patient-info {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  margin-right: calc(var(--margin-md) * 2);
}