:root {
  --symptom-add-note-width: 100px;
  --column-checkbox-height: 300px;
  --column-checkbox-medium-height: 450px;
  --column-checkbox-small-height: auto;
}

.symptom-add-note {
  width: var(--symptom-add-note-width);
  padding-left: var(--padding-lg);
}

.symptom-level-group {
  flex: 1;
  flex-basis: 100%;
}

.symptom-select-input {
  padding-bottom: var(--padding-lg);
}

.column-checkbox-group {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: var(--column-checkbox-height);
}

.column-checkbox-group label {
  width: auto;
}

@media (max-width: 768px) {
  .column-checkbox-group {
    height: var(--column-checkbox-medium-height);
  }

}

@media (max-width: 640px) {
  .column-checkbox-group {
    height: var(--column-checkbox-small-height);
  }
}