:root {
  --returning-section-width: 400px;
  --account-section-width: 700px;
  --brand-logo-size: 80px;
  --top-spacing: 100px;
}

.registration-landing,
.registration-landing.container-fluid > .row,
.registration-landing .returning-section,
.registration-landing .account-section,
.registration-landing .registration-accounts {
  height: 100%;
}

.returning-section {
  padding-top: var(--top-spacing);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--foreground-color);
  box-shadow: var(--shadow-md);
}

.returning-section .authentication {
  max-width: var(--returning-section-width);
  margin: 0 auto;
}

.returning-section .authentication .brand-logo {
  padding: var(--padding-md);
  display: flex;
  justify-content: center;
}

.returning-section .authentication .brand-logo>img {
  height: var(--brand-logo-size);
}

.account-section {
  background-color: var(--white-color);
}

.account-section .registration-accounts {
  padding-top: var(--top-spacing);
  max-width: var(--account-section-width);
  margin: 0 auto;
}

.account-section h1,
.returning-section h1 {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: var(--padding-lg);
  padding-bottom: var(--padding-lg);
}

.account-section h3 {
  margin-top: 0;
}

.create-account-btn {
  visibility: hidden;
  justify-self: center;
  align-self: center;
  display: flex;
  border-radius: 999em;
  justify-content: center;
  text-align: center;
  width: 100%;
  padding: var(--padding-md);
  font-weight: 600;
  margin-top: calc(var(--padding-md) * 2);
  border: 1px solid var(--secondary-color);
  color: var(--secondary-color);
}

.create-account-btn:hover {
  text-decoration: none;
  cursor: pointer;
  color: var(--pink-dark);
  border-color: var(--pink-dark);
}

.language-selector-container {
  padding: var(--padding-lg);
  position: fixed;
  top: 0;
  right: 0;
  z-index: 100;
}

.language-selector {
  position: relative;
}

.language-selector>button.language-trigger {
  border-radius: 20px;
  padding: var(--padding-md) var(--padding-lg);
  background-color: var(--primary-color);
  box-shadow: var(--shadow-md);
  color: #fff;
  border: none;
}

.language-selector>button.language-trigger:focus {
  outline: none;
}

.language-selector>button.language-trigger>span {
  padding-left: var(--padding-md);
  padding-right: var(--padding-lg);
}

.language-selector>ul.language-menu {
  padding-left: 0;
  list-style: none;
  margin-top: var(--margin-sm);
  padding-top: var(--padding-sm);
  padding-bottom: var(--padding-sm);
  background-color: var(--foreground-color);
  border-radius: 4px;
  box-shadow: var(--shadow-md);
  min-width: 100px;
}

.language-selector>ul.language-menu>li {
  padding: var(--padding-md);
}

.language-selector>ul.language-menu>li:hover {
  cursor: pointer;
  background-color: var(--primary-highlight-color);
}

.language-selector>ul.language-menu>li.selected {
  background-color: var(--primary-color);
  color: #fff;
}

.registration-returning .return-confirmation-message {
  margin-bottom: var(--margin-lg);
}

.registration-returning .return-confirmation-message strong {
  text-decoration: underline;
  text-transform: uppercase;
}

.registration-step {
  margin-bottom: 120px;
}

.registration-returning .return-confirmation-links,
.registration-sports .sports-confirmation-links,
.physician-confirmation-links {
  display: flex;
  justify-content: space-between;
}

.registration-sports .sports-footer {
  display: flex;
  justify-content: flex-end;
  margin-bottom: var(--margin-lg);
}

.registration-returning-account-form .forget-password-link {
  margin-bottom: var(--margin-lg);
}

.registration-returning-account-form .forget-password-link a {
  display: block;
  text-align: right;
}

.registration-finish .guardian-children {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-wrap: wrap;
}

.registration-finish .guardian-children>.guardian-child {
  flex-basis: 30%;
  padding: 10px;
  background-color: var(--foreground-color);
  border-radius: var(--radius-lg);
  margin-bottom: var(--margin-md);
}

.registration-finish .guardian-children>.guardian-child h2 {
  margin: 0;
}

.registration-finish .guardian-children>.guardian-child hr {
  margin-bottom: var(--margin-sm);
  margin-top: var(--margin-sm);
}

main.registration {
  margin-top: 60px;
}

.header-small {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  padding-left: var(--padding-lg);
  height: var(--navigation-bar-height);
  width: 100%;
  margin-top: 0;
  border-bottom: 2px solid var(--primary-color);
  background-color: #fff;
  z-index: 100;
}

.header-small .navigation-brand {
  height: 100%;
}

.header-small img {
  padding: var(--navigation-brand-padding);
  height: 100%;
}

.registration-brand {
  width: 100%;
  height: 100%;
  margin-top: 80px;
}

.registration .section-sticky {
  position: sticky;
  top: 0px;
}

.registration-brand img {
  height: 160px;
}

.alert-highlight-warning {
  background-color: var(--flash-color);
  font-weight: bold;
  text-align: center;
  padding: var(--padding-lg) var(--padding-sm);
  margin-bottom: var(--margin-lg);
}

.account-cards .account-card {
  border: 1px solid var(--white-dark);
  padding: calc(var(--padding-sm) * 2);
  border-radius: 8px;
  background-color: var(--foreground-color);
  margin-bottom: var(--margin-lg);
  box-shadow: var(--shadow-sm);
}

.account-cards .account-card .account-card-content {
  height: 120px;
  display: flex;
  align-items: center;
}

.account-cards .account-card .account-card-footer>a {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.account-cards .account-card .account-card-footer>a>.fa {
  position: absolute;
  margin-left: 120px;
  opacity: 0;
  transform: translateX(-10px);
  transition: transform 0.2s ease-in-out, opacity 0.1s linear;
}

.account-cards .account-card .account-card-footer>a:hover>.fa {
  opacity: 1;
  transform: translateX(0px);
}

.decouple-child-modal .modal-view-content {
  max-width: 800px;
}

form .form-group.checkbox-input-group.terms-input-group>label {
  align-items: flex-start;
}

.privacy-consent-item {
  margin-bottom: var(--margin-lg);
}

.privacy-consent-item input[type=checkbox] {
  margin-top: 5px;
}

.privacy-consent-item .help-button {
  margin-left: 30px;
}

.alert.alert-danger.consent-error {
  margin-bottom: 0;
}

.create-family-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 0;
  max-height: 0;
  transition: max-height 500ms linear;
}

.create-family-banner.visible {
  height: auto;
  max-height: 400px;
}

.create-family-banner.visible .content-inner {
  position: relative;
  margin-left: 20px;
  margin-bottom: 15px;
  background-color: transparent;
  box-shadow: var(--shadow-sm);
  border-top: 3px solid var(--primary-color);
  border-radius: 8px;
}

.create-family-banner.visible .content-inner .content-header {
  height: 50px;
  width: 50px;
  color: var(--background-color);
  background-color: var(--primary-color);
  border-radius: 50%;
  position: absolute;
  font-size: 20px;
  top: -28px;
  left: calc(50% - 25px);
  z-index: 200;
  display: flex;
  justify-content: center;
  align-items: center;
}

.create-family-banner.visible .content-inner .content {
  width: 100%;
  padding: 15px;
  font-size: 14px;
  background-color: var(--grey-light);
  text-align: center;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100px;
  border-radius: 8px;
}

.create-family-banner.visible .content-inner .content>button.edit-button {
  margin-top: 10px;
  margin-left: 5px;
}

.create-family-banner.visible .content-inner .content .close-button {
  position: absolute;
  right: 0;
  top: 0;
  margin: 3px;
}

.create-family-banner.visible .content-inner .content .close-button button {
  color: var(--secondary-color);
  background-color: #fff;
  border-radius: 50%;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  width: 20px;
  text-decoration: none;
}

.create-family-banner.visible .content-inner .content .close-button button>.fa {
  margin: 0;
}

.create-family-success-banner {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: auto;
  z-index: 300;
  overflow: hidden;
}

.authentication-drawer button.close {
  position: absolute;
  top: 0;
  right: 0;
  margin: 15px;
  font-size: 32px;
}

.authentication-drawer {
  background-color: #fff;
  min-width: 550px;
}

.authentication-drawer .activity,
.authentication-drawer .activity-container,
.authentication-drawer .returning-section {
  height: 100%;
}

.alert.email-taken-error>div.title {
  font-weight: bold;
  margin-bottom: var(--margin-sm);
}

.alert.email-taken-error>p.message {
  margin-bottom: var(--margin-sm);
}

.registration-booking-links-container {
  margin-bottom: calc(var(--margin-md) * 4);
}

/* Profile Completion Modal */
.profile-completion-modal .dialog-body {
  display: flex;
  flex-direction: column;
}

.profile-completion-modal ul.completion-items {
  align-self: center;
  margin: var(--margin-md) 0;
}

.profile-completion-modal ul.completion-items li:not(:last-child) {
  margin-bottom: var(--margin-sm);
}

.profile-completion-modal ul.completion-items li .fa {
  font-size: 18px;
  color: var(--error-color);
  margin-right: var(--margin-md);
}

.profile-completion-modal ul.completion-items li .item-label {
  font-weight: var(--semi-bold-font-weight);
}

.registration-about.nav-visible nav.navigation-bar {
  box-shadow: none;
  background-color: var(--background-color);
}

.registration-mfa .mfa-onboard-view {
  max-width: 660px;
  padding-top: 20px;
  margin: 0 auto;
}

@media all and (max-width: 991px) {
  .registration-brand {
    display: none;
  }

  .header-small {
    display: block;
  }

  .create-family-banner {
    right: 0;
  }

  .create-family-banner.visible .content-inner {
    margin-left: 0;
    margin-bottom: 0;
    border-radius: 0;
  }

  .create-family-banner.visible .content-inner .content-header {
    height: 40px;
    width: 40px;
    top: -20px;
    left: calc(50% - 20px);
    font-size: 16px;
    margin-bottom: 10px;
  }

  .create-family-banner.visible .content-inner .content {
    flex-direction: column;
  }

  .create-account-btn {
    visibility: visible;
  }
}

@media all and (max-width: 580px) {
  .authentication-drawer {
    min-width: 100%;
  }
}