.pagination li > a {
  color: var(--primary-color);
  outline: none;
  border: 1px solid var(--grey-light);
  background-color: var(--foreground-color);
}

.pagination li > a:hover {
  background-color: var(--white-dark);
  color: var(--blue-dark);
}

.pagination > .active > a,
.pagination > .active > a:focus,
.pagination > .active > a:hover {
  background-color: var(--blue-dark);
  border-color: var(--blue-dark);
}

.pagination > .disabled > a,
.pagination > .disabled > a:focus,
.pagination > .disabled > a:hover {
  background-color: var(--foreground-color);
  border-color: var(--grey-light);
}

.pagination .active > a {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: #fff;
}

.pagination li.previous.disabled > a {
  color: var(--grey-color);
}

.pagination-center {
  display: flex;
  justify-content: center;
  background-color: var(--background-color);
  border: 1px solid var(--background-color);
  margin: 0px -5px;
}
