:root {
  --baseline-row-hover-color: rgba(33, 150, 241, 0.2);
  --baseline-row-background-color: var(--white-dark);
  --baseline-row-min-height: 80px;
}

.baseline-card {
  flex: 1;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.baseline-card .baseline-card-row:nth-child(odd) {
  background: var(--baseline-row-background-color);
}

.baseline-card .baseline-card-row:hover {
  background: var(--baseline-row-hover-color);
}

.baseline-card-row {
  display: flex;
  flex: 1;
  flex-basis: 100%;
  align-items: center;
  padding: var(--padding-lg);
  flex-wrap: wrap;
  min-height: var(--baseline-row-min-height);
}

.baseline-card-row .radio-group-container .alert {
  margin: 0;
}

.baseline-card-row label.severity-view {
  color: var(--primary-color);
  font-weight: var(--bold-font-weight);
}

.baseline-card-row .form-input-group,
.baseline-card-row .reaction-time-number {
  flex: 1;
}

.baseline-card-row .reaction-time-number {
  padding-left: calc(var(--padding-lg) * 2);
}

.baseline-card-row label {
  flex: 1;
  font-weight: var(--bold-font-weight);
  margin-bottom: 0;
}

.baseline-card-row .radio-group-container {
  flex: 1;
}

.baseline-radio-input {
  display: flex;
  margin: 0;
}

.baseline-select-input>label {
  padding-right: var(--padding-lg);
}

.baseline-radio-input>label {
  flex: 1;
  display: flex;
}

.baseline-radio-input>label>span {
  order: 2;
  font-weight: normal;
  padding-left: var(--padding-sm);
}

.baseline-radio-input>label>input[type=radio] {
  order: 1;
  margin-left: var(--margin-sm);
  margin-right: var(--margin-sm);
}

label.baseline-row-label {
  flex: 1;
  flex-basis: 11.5vw;
  text-transform: uppercase;
  margin: 0;
  font-size: var(--semi-small-font-size);
  line-height: var(--label-line-height);
}

.baseline-card .card-section {
  padding: var(--padding-lg);
  flex: 1;
  flex-basis: 100%;
}

@media (max-width: 690px) {

  label.baseline-row-label,
  .baseline-card-row label {
    width: 100%;
    flex-basis: 100%;
    flex-wrap: wrap;
  }
}