.bag-tag-batch form.bagtags-filter-form label {
  display: flex;
  flex-wrap: wrap;
}

.bag-tag-batch form.bagtags-filter-form label > span {
  width: 100%;
  flex-basis: 100%;
}

.bag-tag-batch form.bagtags-filter-form label > input {
  flex: 1;
  border-right-width: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.bag-tag-batch form.bagtags-filter-form label > button {
  @extend form .form-control;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: #FFF;
}

.bagtag-modal .dialog-header {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
}
