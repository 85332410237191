.protocol-stage .protocol-disabled-text {
  margin-top: var(--margin-md);
}

.protocol-stage .selector-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid var(--grey-light);
}

.protocol-stage .selector-header>h1 {
  margin-left: 10px;
  line-height: 1;
  margin-bottom: 5px;
  margin-top: 5px;
}

.protocol-stage .recovery-stage-selector {
  padding-top: 15px;
  padding-bottom: 15px;
}

.protocol-stage .previous-stage {
  margin-top: 5px;
}

.recovery-stage-details {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: var(--primary-font-family);
}

.recovery-stage-details .stage-number {
  height: 54px;
  width: 54px;
  border-radius: var(--radius-md);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-weight: bold;
  font-size: 32px;
  margin-right: 10px;
}

.recovery-stage-details .stage-details {
  flex: 1;
}

.recovery-stage-details .stage-details .stage-description {
  font-size: 14px;
  line-height: 1.4;
}

.recovery-stages-view {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.recovery-stages-view .recovery-stage-view {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 10px;
  margin: 5px;
  border: 1px solid var(--grey-light);
  border-radius: var(--radius-md);
  flex-wrap: wrap;
  margin-bottom: 10px;
  margin-right: 10px;
}

.recovery-stages-view .recovery-stage-view .stage-number {
  height: 46px;
  width: 46px;
  border-radius: var(--radius-md);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  font-weight: bold;
  color: #fff;
  margin-right: 10px;
}

.recovery-stages-view .recovery-stage-view .stage-label {
  font-size: 18px;
}

.stage-progress-indicator {
  margin: 10px 0;
}

.stage-progress-indicator .stage-progress-bar {
  display: flex;
  align-items: center;
  height: 20px;
  width: 100%;
}

.stage-progress-indicator .stage-progress-bar .dash {
  flex: 1;
  height: 100%;
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.stage-progress-indicator .stage-progress-bar .dash.active:hover {
  transform: scale(1.05);
  box-shadow: var(--shadow-md);
}

.stage-progress-indicator .stage-progress-bar .dash.default {
  background-color: var(--white-dark);
}

.stage-progress-indicator .stage-progress-bar .dash:first-child {
  border-top-left-radius: 999em;
  border-bottom-left-radius: 999em;
}

.stage-progress-indicator .stage-progress-bar .dash:last-child {
  border-top-right-radius: 999em;
  border-bottom-right-radius: 999em;
}

.stage-progress-indicator .stage-progress-bar .dash:not(:last-child) {
  margin-right: 0.4em;
}

.stage-progress-indicator .stage-progress-label {
  font-size: 12px;
  font-weight: bold;
  color: var(--black-light);
  margin: 3px 0;
}