.notes-drawer {
  height: 100vh;
  width: 100vw;
  background-color: var(--background-color);
  overflow-y: scroll;
}

.notes-drawer .notes-drawer-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: var(--background-color);
  z-index: 1;
}

.notes-drawer .notes-drawer-header .tabs,
.notes-drawer .notes-drawer-header .tabs .tabs-bar {
  height: 100%;
}

.notes-drawer .notes-drawer-header .tabs .tabs-bar {
  margin-bottom: 0;
}

.notes-drawer .notes-drawer-header .tabs .tabs-bar .tab-button {
  font-size: var(--h3-font-size);
  font-weight: var(--light-bold-font-weight);
}

.notes-drawer .notes-drawer-header .header-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.notes-drawer .notes-drawer-header button.btn-close {
  font-size: 28px;
  border: none;
  border-radius: 50%;
  color: var(--grey-color);
  background-color: transparent;
  height: 40px;
  width: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.notes-drawer .notes-drawer-header button.btn-close:hover {
  color: var(--black-color);
  background-color: var(--grey-color);
}

.notes-drawer .notes-drawer-content {
  padding-top: var(--padding-lg);
  margin-top: 80px;
}

.notes-drawer .notes-drawer-content .panel-controls {
  display: flex;
  justify-content: flex-end;
  margin-bottom: var(--margin-md);
}

.notes-drawer .notes-drawer-content .status-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.notes-drawer .notes-drawer-content .status-view>.fa.status-icon {
  font-size: 50px;
}

.notes-drawer .notes-drawer-content .panel-heading {
  top: 68px !important;
}