@media print {

  .post-injury-assessment-display .test-header,
  .post-injury-assessment-display .post-injury-section {
    page-break-inside: avoid;
  }

  .btn-read-more {
    display: none;
  }

}