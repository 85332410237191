.password-score {
  position: relative;
  width: 100%;
  padding: 5px;
  margin-bottom: var(--margin-lg);
  border-width: 2px;
  border-radius: 3px;
  border-style: solid;
  border-color: var(--grey-color);
}

.password-score.score-0 {
  border-color: var(--error-color);
}

.password-score.score-1 {
  border-color: var(--error-color);
}

.password-score.score-2 {
  border-color: var(--warning-color);
}

.password-score.score-3 {
  border-color: var(--success-color);
}

.password-score.score-4 {
  border-color: var(--success-color);
}

.password-score .password-score-value {
  position: absolute;
  top: 0;
  left: 0;
  width: 0%;
  height: 100%;
}

.password-score.score-0 .password-score-value {
  background-color: var(--error-color);
}

.password-score.score-1 .password-score-value {
  background-color: var(--error-color);
  width: 25%;
}

.password-score.score-2 .password-score-value {
  background-color: var(--warning-color);
  width: 50%;
}

.password-score.score-3 .password-score-value {
  background-color: var(--success-color);
  width: 75%;
}

.password-score.score-4 .password-score-value {
  background-color: var(--success-color);
  width: 100%;
}

.password-score span {
  @extend .caption;
  display: block;
  position: relative;
  color: #FFF;
  z-index: 1;
}

.password-score.score-0 span {
  color: var(--error-color);
}