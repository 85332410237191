.lock-screen {
  z-index: 9999;
}

.lock-screen .dialog {
  background-color: var(--background-color);
  min-width: 100%;
}

.lock-screen .logo {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 60px;
  height: 60px;
}

.lock-screen .logo > img {
  width: 60px;
  height: 60px;
}

.lock-screen .dialog-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.lock-screen .lock-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 440px;
  align-self: center;
  padding: 10px;
  padding-bottom: 60px;
  overflow: auto;
}

.lock-screen .lock-content .activity {
  width: 100%;
}

.lock-screen .lock-content .user-avatar {
  position: relative;
  height: 100px;
  width: 100px;
  border-radius: 50%;
}

.lock-screen .lock-content .user-avatar img {
  height: 100px;
  width: 100px;
  border-radius: 50%;
}

.lock-screen .lock-content .user-avatar .icon {
  position: absolute;
  bottom: 3px;
  right: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  border: 3px solid #fff;
  background-color: var(--warning-color);
  color: #fff;
}

.lock-screen .lock-content .user-details > h1 {
  margin-top: 10px;
  margin-bottom: 5px;
}

.lock-screen .lock-content .user-details > p {
  font-size: 14px;
}

.lock-screen .lock-content form.auth-form .form-group,
.lock-screen .lock-content form.auth-form .form-group label {
  margin-bottom: 0
}

.lock-screen .lock-content form.auth-form .form-group label input {
  border-radius: 8px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.lock-screen .lock-content form.auth-form .input-group .input-group-btn .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  height: 42px;
}

.lock-screen .lock-content form.auth-form {
  margin-bottom: 10px;
  width: 100%;
}

.lock-screen .lock-content .alert {
  padding: 10px;
  width: 100%;
  text-align: center;
}

@media (max-width: 421px) {
  .lock-screen .lock-content {
    width: 100%;
  }
}
