.test-header h2 {
  margin-top: 0;
}

.test-header .test-header-row {
  display: flex;
}

.test-header .test-header-row label {
  width: 33%;
  margin-right: var(--margin-lg);
  margin-bottom: 0;
  text-transform: uppercase;
}

.test-header .test-header-row span {
  flex: 1;
}

.test-header .test-header-row .header-clinic-address {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
}

.test-header .test-header-row .header-clinic-address>span {
  flex: 1;
  flex-basis: 100%;
}

.test-header .test-header-form-row {
  flex: 1;
  display: flex;
}

.test-header .test-header-form-row .form-group {
  flex: 3;
  flex-basis: 60%;
}

.test-header .test-header-form-row .form-button-group {
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: var(--padding-lg);
}