:root {
  --overlay-transition-duration: 200ms;
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  opacity: 0;
  transition: opacity var(--overlay-transition-duration) linear;
}

.open > .overlay {
  opacity: 0.6;
}

.overlay.light {
  background-color: #fff;
}

.overlay.dark {
  background-color: var(--black-color);
}

.scroll-lock {
  overflow: hidden;
}

@supports (filter: blur()) {
  .blur-content  {
    transition: blur 100ms linear;
    filter: blur(10px);
  }

  .open .overlay.blurred {
    opacity: 0.4;
  }

  .overlay.blurred.light {
    background-color: transparent;
  }
}
