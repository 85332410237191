.maple-consult-modal .dialog {
  min-width: 80%;
}

.maple-modal-header .progress-dots .dot {
  border-color: var(--maple-purple);
}

.maple-modal-header .progress-dots .dot.highlighted {
  background-color: var(--maple-purple);
}

.maple-modal-header {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  margin: 0 calc(var(--margin-sm) * 2);
}

.maple-modal-header .header-item {
  flex: 1;
}

.maple-modal-header .header-item .close {
  opacity: 1;
  color: var(--maple-purple);
  font-size: 26px;
  outline: none;
}

.maple-modal-body .select-patient-control .checkbox-input-group {
  margin-top: var(--padding-md);
}

.maple-modal-body {
  display: flex;
  flex-direction: column;
  flex: 1;
  color: var(--maple-purple);
  min-height: 405px;
}

.maple-modal-body .maple-disabled-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.maple-modal-body h1 {
  margin-top: 0;
}

.maple-modal-body .continue-action {
  display: flex;
  justify-content: flex-end;
  margin-right: var(--margin-md);
}

.maple-modal-body .maple-pricing {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.maple-modal-body .activity-indicator-wrapper {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.maple-pricing .pricing-disclaimer {
  margin-left: var(--margin-md);
  margin-right: var(--margin-md);
}

.maple-pricing .maple-pricing-cards {
  display: flex;
  flex: 1;
  justify-content: space-around;
  align-items: flex-start;
  flex-wrap: wrap;
}

.maple-pricing .maple-pricing-cards .pricing-card {
  margin-bottom: calc(var(--margin-sm) * 2);
  min-height: 230px;
  min-width: 30%;
  border-width: 1px;
  border-style: solid;
  border-color: var(--grey-light);
  border-radius: var(--standard-border-radius);
  padding: var(--padding-lg) calc(var(--padding-sm) * 4);
}

.maple-pricing .maple-pricing-cards .pricing-card.active {
  border-color: var(--maple-purple);
  border-width: 3px;
}

.maple-pricing-cards .pricing-card .maple-price-icon {
  height: 60px;
  width: 60px;
  margin-bottom: calc(var(--margin-sm) * 2);
}

.maple-pricing .maple-pricing-cards .pricing-card .category {
  margin-bottom: var(--margin-lg);
}

.maple-pricing-cards .pricing-card .price-row {
  font-size: 18px;
  margin: 10px 0
}

.maple-pricing-cards .pricing-card .price-row .price {
  font-weight: bolder;
  font-size: 28px;
}

.maple-modal-body .btn.btn-primary {
  background-color: var(--maple-purple);
  border-color: var(--maple-purple);
}

.maple-modal-body .btn.btn-primary:disabled {
  opacity: 0.5;
}

.maple-modal-body .btn.btn-primary:focus {
  outline: 5px auto var(--maple-purple);
}

.form-group.maple-external-checkbox>label {
  font-size: 15px;
  font-weight: bold;
}

.form-group.maple-external-checkbox>label>input {
  transform: scale(1.3);
}

.maple-consult-summary-step {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
}

.maple-consult-summary-step .consult-description {
  margin-top: calc(var(--margin-lg) * 2);
}

.maple-consult-summary-step .consult-description p {
  margin-top: var(--margin-lg);
  margin-bottom: calc(var(--margin-md) * 2);
}

.maple-consult-summary-step .consult-description a.btn {
  width: 100%;
}

.maple-select-patient-step {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.maple-select-patient-step .select-patient-control {
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: column;
}

.maple-select-patient-step .select-patient-control .clinic-search-form.compact {
  align-items: flex-start;
}


@media screen and (max-width: 720px) {
  .maple-pricing .maple-pricing-cards .pricing-card {
    min-width: 100%;
  }
}