:root {

  --transition-duration: 0.25s;

}

.flash {
  animation-name: flash;
  animation-duration: 3s;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes flash {
  0% { background-color: var(--flash-color) }
  100% { background-color: inherit }
}

@keyframes brand_colors {
  0% {
    color: var(--tertiary-color);
  }

  33% {
    color: var(--primary-color);
  }

  66% {
    color: var(--secondary-color);
  }

  100% {
    color: var(--tertiary-color);
  }
}