:root {
  --booking-link-card-padding: 15px;
}

.booking-link-modal .dialog {
  min-width: 60%;
}

.booking-links-list>.actions-header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-bottom: var(--margin-md);
}

.booking-link-card>.card-container-body {
  padding: var(--booking-link-card-padding);
  position: relative;
}

.booking-link-card .booking-link-actions {
  position: absolute;
  right: var(--booking-link-card-padding);
  top: var(--booking-link-card-padding);
}

.booking-link-actions .booking-links-actions-button {
  border-radius: 50%;
  border: 1px solid transparent;
  background-color: var(--white-dark);
  height: 32px;
  width: 32px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
}

.booking-link-actions .booking-links-actions-button:hover {
  background-color: var(--grey-light);
}

.booking-link-actions .booking-links-actions-button:focus,
.booking-link-actions .booking-links-actions-button:active {
  border-color: var(--primary-color);
  background-color: var(--primary-highlight-color);
}

.booking-link-card-title {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: var(--margin-md);
}

.booking-links-empty-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.booking-links-empty-view .booking-links-empty-icon {
  font-size: 60px;
  color: var(--grey-color);
  display: flex;
  justify-content: center;
  margin-bottom: var(--margin-lg);
  margin-top: var(--margin-lg);
}