
.label {
  padding: .2em .6em .2em;
  border-radius: 0.2em;
  font-size: 70%;
  line-height: 1.3;
}

.label + .label {
  margin-left: 5px;
}

.label.rounded {
  border-radius: 0.8em;
}

.label.label-primary {
  background-color: var(--primary-color);
}

.label.label-primary.inverted {
  background-color: #e6f2ff;
  color: var(--primary-color);
}

.label.label-default {
  background-color: var(--black-color);
}

.label.label-default.inverted {
  background-color: #f2f2f3;
  color: var(--black-color);
}

.label.label-light {
  background-color: var(--white-dark);
  color: var(--primary-color);
}

.label.label-success {
  background-color: var(--success-color);
}

.label.label-success.inverted {
  background-color: #ecf9ec;
  color: var(--success-color);
}

.label.label-warning {
  background-color: var(--warning-color);
}

.label.label-warning.inverted {
  background-color: #fff3e6;
  color: var(--warning-color);
}

.label.label-danger {
  background-color: var(--error-color);
}

.label.label-danger.inverted {
  background-color: #fce9e9;
  color: var(--error-color);
}

.label.label-secondary {
  background-color: var(--secondary-color);
}

.label.label-secondary.inverted {
  background-color: #fbeffb;
  color: var(--secondary-color);
}

.label.label-tertiary {
  background-color: var(--tertiary-color);
  color: var(--black-color);
}

.label.label-tertiary.inverted {
  background-color: #ecfdf9;
  color: var(--teal-dark);
}

.label.selectable {
  cursor: pointer;
}
