:root {
  --scroll-top-width: 60px;
  --scroll-top-icon-height: 60px;
  --scroll-top-icon-border-radius: 50%;
  --scroll-top-hover-background-color: rgb(60,122,190, .9); /* matches --primary-color */
}

.scroll-to-top {
  width: var(--scroll-top-width);
  background-color: transparent;
}

.scroll-to-top .scroll-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--scroll-top-width);
  height: var(--scroll-top-icon-height);
  border-radius: var(--scroll-top-icon-border-radius);
  color: var(--black-light);
}

.scroll-to-top .scroll-icon .fa {
  font-size: var(--h1-display-font-size);
  line-height: var(--h1-display-line-height);
  text-align: center;
}

.scroll-to-top p {
  visibility: hidden;
  text-align: center;
  text-transform: uppercase;
  font-weight: bolder;
  color: var(--primary-color);
}

.scroll-to-top:hover .scroll-icon {
  background-color: var(--primary-color);
}

.scroll-to-top:hover .scroll-icon .fa {
  color: var(--dark-mask-color);
}

.scroll-to-top:hover p {
  visibility: visible;
}