.guardian-account-lists .accounts-list {
  padding: 0;
  list-style: none;
}

.guardian-account-lists .accounts-list li.account {
  box-shadow: none;
  transition: transform .2s, box-shadow 0.2s;
  margin: auto;
  border-radius: var(--radius-md);
  border: 1px solid var(--grey-light);
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  align-items: center;
  cursor: pointer;
}

.guardian-account-lists .accounts-list li.account:hover {
  transform: scale3d(1.006, 1.006, 1);
  box-shadow: var(--shadow-sm);
}

.guardian-account-lists .accounts-list li.account:not(:last-child) {
  margin-bottom: 10px;
}

.guardian-account-lists .accounts-list li.account .account-info {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: var(--padding-md);
  color: var(--black-color);
}

.guardian-account-lists .accounts-list li.account .account-info .account-name {
  color: var(--primary-color);
  font-size: 18px;
  display: flex;
  align-items: center;
  margin-bottom: var(--margin-sm);
}

.guardian-btn {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-top: calc(var(--margin-lg) * 2);
}

.guardian-child-btn {
  color: var(--primary-color);
  background-color: transparent;
  padding: 0;
  border: none;
  text-align: left;
}

.guardian-child-rm-btn {
  font-size: var(--label-font-size);
  padding: var(--margin-sm) var(--padding-sm);
  background-color: var(--black-color);
  border-color: var(--black-color);
  margin-right: var(--padding-md);
  margin-left: var(--padding-md);
}

.add-existing-account-modal .add-existing-account-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.add-existing-account-modal .add-existing-account-header h1 {
  margin-top: 0;
  margin-bottom: 0;
}