.banner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  padding: var(--padding-lg);
  z-index: 1000;
  border-top: 1px solid #FFF;
  background-color: var(--primary-color);
  color: #FFF;
  transform: translateY(100%);
  transform-origin: bottom;
  transition-property: transform;
  transition-duration: var(--transition-duration);
  transition-timing-function: ease;
}

.banner.visible {
  transform: translateY(0);
}

.banner .btn.btn-primary {
  background-color: #FFF;
  color: var(--primary-color);
}

.account-deactivated-banner {
  position: fixed;
  left: 0;
  top: var(--navigation-bar-height);
  width: 100%;
  height: auto;
  max-height: var(--navigation-bar-height);
  background-color: var(--primary-color);
  color: #FFF;
  z-index: 100;
}

.account-deactivated-banner .banner-content {
  text-align: center;
  font-size: var(--h1-font-size);
  line-height: var(--h1-line-height);
  padding-top: var(--padding-lg);
  padding-bottom: var(--padding-lg);
}