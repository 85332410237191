:root {

  --table-border-width: var(--standard-border-width);
  --table-border-style: var(--standard-border-style);
  --table-border-color: var(--standard-border-color);

  --table-header-font-size: 12px;
  --table-header-font-weight: 400;
  --table-header-color: var(--primary-color);

  --table-cell-padding: var(--padding-sm) var(--padding-lg);

  --table-highlighted-font-weight: bold;
  --table-highlighted-color: var(--blue-dark);

}

.table {
  width: 100%;
}

.table>thead>tr>th,
.table>tbody>tr>td {
  border: none;
}

.table>thead>tr>th {
  font-size: var(--table-header-font-size);
  font-weight: var(--table-header-font-weight);
  color: var(--table-header-color);
  padding: var(--table-cell-padding);
  padding-top: 0;
  cursor: pointer;
}

.table>thead>tr>th.no-action {
  cursor: default;
  color: var(--black-color);
}

.table>tbody>tr {
  border-bottom-width: var(--table-border-width);
  border-bottom-style: var(--table-border-style);
  border-bottom-color: var(--table-border-color);
}

.table>tbody>tr:first-child {
  border-top-width: var(--table-border-width);
  border-top-style: var(--table-border-style);
  border-top-color: var(--table-border-color);
}

.table>tbody>tr:nth-child(2n + 1) {
  background-color: var(--white-color);
}

.table>tbody>tr>td {
  border-right-width: var(--table-border-width);
  border-right-style: var(--table-border-style);
  border-right-color: var(--table-border-color);
  padding: var(--table-cell-padding);
}

.table>tbody>tr>td:first-child {
  border-left-width: var(--table-border-width);
  border-left-style: var(--table-border-style);
  border-left-color: var(--table-border-color);
}

.table .highlighted {
  font-weight: var(--table-highlighted-font-weight);
  color: var(--table-highlighted-color);
}

.table.sticky-header>thead>tr>th {
  position: sticky;
  top: 0;
}

.table.sticky-header.with-nav-bar>thead>tr>th {
  top: var(--navigation-bar-height);
  z-index: 1;
}

@media screen and (max-width: 992px) {
  .table-responsive-md {
    width: 100%;
    overflow-x: auto;
    display: block;
  }

  .table-responsive-md table {
    white-space: nowrap;
  }

  .table-responsive-md table td {
    white-space: nowrap;
  }
}

@media screen and (max-width: 792px) {
  table.table .form-control {
    padding: var(--padding-sm) var(--padding-md);
  }

  table.table input[type=number].form-control {
    padding-right: 0;
  }
}