.modal {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal>.dialog {
  position: relative;
  max-width: 80%;
  max-height: 80%;
  min-width: 450px;
  background-color: #fff;
  border-radius: var(--radius-lg);
  border: 1px solid var(--grey-color);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  outline: none;
}

.modal>.dialog.slide-bottom {
  opacity: 0;
  transform: translateY(150px);
  transition: transform var(--overlay-transition-duration) ease-in-out,
    opacity 150ms linear;
}

.modal.open>.dialog.slide-bottom {
  opacity: 1 !important;
  transform: translateY(0) !important;
}

.modal>.dialog.slide-top {
  opacity: 0;
  transform: translateY(-100%);
  transition: transform var(--overlay-transition-duration) ease-in-out,
    opacity 150ms linear;
}

.modal.open>.dialog.slide-top {
  opacity: 1 !important;
  transform: translateY(0) !important;
}

.modal>.dialog.fade {
  opacity: 0;
  transition: opacity 0.3s linear;
}

.modal.open>.dialog.fade {
  opacity: 1 !important;
}


.modal>.dialog.zoom {
  opacity: 0;
  transform: scale(0);
  transition: transform 0.3s ease-in-out, opacity 0.15s linear;
}

.modal.open>.dialog.zoom {
  opacity: 1 !important;
  transform: scale(1);
}

.modal>.dialog.full {
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  border-radius: 0;
}

.modal>.dialog .dialog-header {
  padding: var(--padding-lg);
  border-bottom: 1px solid var(--grey-color);
}

.modal>.dialog .dialog-header button.close {
  outline: none;
}

.modal>.dialog .dialog-header>h1,
.modal>.dialog .dialog-header>h2,
.modal>.dialog .dialog-header>h3,
.modal>.dialog .dialog-header>h4,
.modal>.dialog .dialog-header>h5,
.modal>.dialog .dialog-header>h6 {
  margin: 0;
}

.modal>.dialog .dialog-body {
  padding: var(--padding-lg);
  flex: 1 1 auto;
  overflow-y: auto;
}

.modal>.dialog .dialog-footer {
  padding: var(--padding-lg);
  border-top: 1px solid var(--grey-color);
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.modal>.dialog form {
  margin-bottom: 0;
}

@media (max-width: 798px) {
  .modal>.dialog:not(.full) {
    width: 95%;
    min-width: 95%;
    max-width: 95%;
  }
}