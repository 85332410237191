.bess-timer-container {
  display: flex;
  flex: 1;
}

.bess-timer {
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
  flex: 1;
  padding: var(--padding-lg);
}

.bess-timer>p {
  font-size: var(--h1-font-size);
  line-height: var(--h1-line-height);
  padding-right: var(--padding-lg);
}

.balance-testing>.balance-header-row {
  padding: var(--padding-lg);
  display: flex;
  flex-wrap: wrap;
}

.balance-header-row>.form-input-group {
  flex: 1;
}

.balance-baseline-card .balance-test-type-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  margin-top: 10px;
}

.balance-baseline-card .balance-test-type-header h3 {
  margin: 0;
}

.balance-baseline-card .balance-test-type-header .balance-type-selector {
  display: inline-flex;
  flex-direction: column;
  padding: var(--padding-md) var(--padding-lg);
  border: 1px solid var(--primary-color);
  border-radius: var(--radius-md);
  background-color: var(--primary-highlight-color);
}

.balance-baseline-card .balance-type-selector .balance-type-switch {
  display: flex;
  align-items: center;
}

.balance-baseline-card .balance-type-selector .balance-type-switch .type-label {
  font-size: 14px;
  cursor: pointer;
}

.balance-baseline-card .balance-type-selector .balance-type-switch .switch {
  margin-left: var(--margin-md);
  margin-right: var(--margin-md);
  margin-bottom: var(--margin-xs);
}

.baseline-card.wess-testing-card .baseline-card-row .form-group {
  margin-left: var(--margin-md);
}

.baseline-card.wess-testing-card .baseline-card-row {
  align-items: flex-start;
}

.baseline-card.wess-testing-card .baseline-card-row .baseline-row-label {
  align-self: center;
}