.dana-tests-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dana-tests-header .title-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.dana-tests-header .title-container .view-selector {
  margin-left: var(--margin-lg);
  margin-bottom: var(--margin-lg);
  margin-top: var(--margin-md);
}

.dana-tests-header .title-container .view-selector .btn {
  padding: var(--padding-sm) var(--padding-md);
  font-size: var(--small-font-size);
}

.dana-tests-header .title-container h1 {
  margin-top: 0;
}

.dana-filters-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: var(--margin-lg) 0;
}

.btn-group.dana-metric-group {
  margin-bottom: var(--margin-md);
}

.btn-group.dana-metric-group .btn {
  flex: 1;
  outline: none;
  margin-bottom: 0;
}

/* DANA Test Selector */
.dana-filters-row .react-select {
  flex: 1;
  max-width: 450px;
  margin-bottom: var(--margin-md);
}

.dana-test-select-option {
  display: flex !important;
  flex-direction: row;
  align-items: center;
}

.dana-test-select-option .logo {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  border: 1px solid var(--primary-color);
  margin-right: 10px;
  background-color: var(--foreground-color);
}

.dana-test-select-option .logo.selected {
  border-color: var(--foreground-color);
}

.dana-test-select-option .logo.text {
  font-size: 8px;
  display: flex;
  color: var(--black-color);
  justify-content: center;
  align-items: center;
}

/* DANA Tests Chart */
.dana-test-chart-tooltip {
  background-color: #fff;
  padding: var(--padding-md) var(--padding-lg);
  border-radius: var(--standard-border-radius);
  box-shadow: var(--shadow-sm);
}

.dana-test-chart-tooltip .dana-label-group {
  margin-bottom: var(--margin-sm);
}

/* DANA Tests List */
.dana-test-list-container {
  display: flex;
  border: 1px solid var(--white-dark);
  min-width: 1000px;
  background-color: var(--foreground-color);
}

.dana-test-list-container .dana-test-list {
  height: 100%;
  flex: 1;
  margin-bottom: 0;
}

.dana-test-list-container .dana-test-list-details {
  flex: 1.5;
  overflow-y: scroll;
  position: sticky;
  top: var(--navigation-bar-height);
  height: calc(100vh - var(--navigation-bar-height));
  border-left: 1px solid var(--white-dark);
}

.dana-test-list>.tabs {
  position: sticky;
  top: var(--navigation-bar-height);
  background-color: var(--foreground-color);
}

.dana-test-list>.tabs>.tabs-bar {
  margin-bottom: 0;
}

.dana-test-list .dana-test-list-row {
  padding: var(--padding-md) var(--padding-lg);
  display: flex;
  flex: 1;
  align-items: center;
  cursor: pointer;
  outline: none;
  transition: all 0.2s linear;
  border-bottom: 1px solid var(--white-dark);
}

.dana-test-list .dana-test-list-row.selected {
  background-color: var(--primary-highlight-color);
}

.dana-test-list .dana-test-list-row:hover:not(.selected) {
  background-color: var(--white-dark);
}

.dana-test-list .dana-test-list-row:focus:not(.selected) {
  background-color: var(--grey-light);
}

.dana-test-list-row .dana-test-details {
  flex: 1;
}

.dana-test-list-row .dana-test-details .dana-test-details-header {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.dana-test-list-row .dana-test-details .dana-test-details-header .label {
  margin-left: var(--margin-md);
}

.dana-test-list-row .metric-value {
  margin: 0 var(--margin-md);
}

.dana-test-list-details .select-test-view {
  font-size: 18px;
  text-align: center;
  padding: var(--padding-lg) var(--padding-md);
}

/* DANA Battery Details Modal */
.modal.dana-battery-details-modal>.dialog {
  width: 50%;
  height: 100%;
  max-height: 100%;
}

.modal.dana-battery-details-modal>.dialog>.dialog-body {
  padding: 0px;
}

/* DANA Battery Details */
.dana-battery-details .battery-details-header {
  position: sticky;
  top: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
  padding: var(--padding-md);
  background-color: var(--foreground-color);
  border-bottom: 1px solid var(--grey-light);
}

.dana-battery-details .battery-details-header .title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dana-battery-details .battery-details-header .title-container>h3 {
  margin-bottom: var(--margin-md);
  margin-top: var(--margin-sm);
  font-weight: bolder;
  font-size: 22px;
}

.dana-battery-details .battery-details-header .title-container>h3>span.label {
  margin-left: var(--margin-md);
  font-weight: normal;
}

.dana-battery-details .battery-details-header .title-container .close-button {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  border: none;
  background-color: var(--grey-light);
  display: flex;
  justify-content: center;
  align-items: center;
}

.dana-battery-details .battery-details-header .title-container .close-button:hover {
  background-color: var(--grey-color);
}

.dana-details-section .section-header {
  display: flex;
  font-size: var(--small-font-size);
  align-items: flex-end;
  border-bottom: 1px solid var(--grey-light);
}

.panel-group.dana-panels .panel-heading {
  padding: 0;
}

.panel-group.dana-panels .panel {
  border: none;
}

.panel-group.dana-panels .panel-heading.opened {
  background-color: var(--white-dark);
}

.panel-group.dana-panels .panel .panel-body.collapse {
  border-bottom: 1px solid var(--grey-light);
  border-top: 1px solid var(--grey-light);
}

.panel-group.dana-panels .panel .panel-body.collapse:not(.in) {
  border-bottom: none;
}

.panel-group.dana-panels .dana-test-panel-header {
  display: flex;
  width: 100%;
  align-items: center;
}

.panel-group.dana-panels .dana-test-panel-header .test-type>.fa {
  width: 20px;
  margin-right: var(--margin-md);
}

.panel-group.dana-panels .dana-test-panel-header .test-stat,
.dana-details-section .section-header .test-stat {
  flex: 1;
  text-align: center;
  padding: var(--padding-md);
}

.panel-group.dana-panels .dana-test-panel-header .test-stat.description,
.dana-details-section .section-header .test-stat.description {
  flex: 2;
  text-align: left;
}

.panel-group.dana-panels .dana-test-panel-header .test-type,
.dana-details-section .section-header .test-type {
  display: flex;
  flex: 2;
  align-items: center;
  padding: var(--padding-md);
}

.panel-group.dana-panels .dana-test-panel-header .test-type img,
.panel-group.dana-panels .dana-test-panel-header .test-type .psych-label-img {
  margin-right: var(--margin-md);
  height: 36px;
  width: 36px;
  border-radius: 50%;
  border: 1px solid var(--primary-color);
  padding: 2px;
}

.panel-group.dana-panels .dana-test-panel-header .test-type .psych-label-img {
  font-size: var(--small-font-size);
  font-weight: bolder;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.panel-group.dana-panels .dana-test-panel-header .test-stat.percent-correct {
  text-align: right;
  color: var(--grey-color);
}

.panel-group.dana-panels .dana-test-panel-header .test-stat.percent-correct>.fa {
  margin-right: var(--margin-sm);
}

.psych-survey-responses {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.psych-survey-responses .psych-survey-response {
  width: 50%;
  padding: var(--padding-sm);
  font-size: var(--semi-small-font-size);
}

.dana-cognitive-table {
  margin-bottom: 0;
  font-size: var(--semi-small-font-size);
}

.dana-cognitive-table>tbody>tr,
.dana-cognitive-table>tbody>tr:first-child {
  border: none;
}

.dana-cognitive-table>tbody>tr:nth-child(2n + 1) {
  background-color: unset;
}

.dana-cognitive-table>tbody>tr>td:first-child {
  font-weight: var(--semi-bold-font-weight);
  border: none;
}

.dana-cognitive-table>tbody>tr>td {
  border: none;
}

.dana-info-legend {
  color: var(--grey-color);
  padding: 0 calc(var(--padding-md) * 2);
}

.dana-info-legend .legend-item {
  display: flex;
  align-items: center;
  padding-bottom: var(--padding-md);
}

.dana-info-legend .legend-item .fa {
  margin-right: var(--margin-md);
}

@media (max-width: 992px) {
  .dana-filters-row .react-select {
    max-width: 100%;
  }

  .dana-filters-row .btn-group.dana-metric-group {
    width: 100%;
  }

  .modal.dana-battery-details-modal>.dialog {
    width: 70%;
  }
}

@media (max-width: 550px) {
  .modal.dana-battery-details-modal>.dialog {
    width: 90%;
  }
}