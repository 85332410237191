:root {

  --primary-font-family: 'HK Grotesk', sans-serif;
  --heading-font-family: 'Calibre', sans-sarif;

  --normal-font-weight: 400;
  --bold-font-weight: bold;
  --light-bold-font-weight: 500;
  --semi-bold-font-weight: 600;

  --body-font-size: 16px;
  --body-line-height: 1.375;

  --h1-font-size: 32px;
  --h1-line-height: 1.2;

  --h1-display-font-size: 44px;
  --h1-display-line-height: 1.3;

  --h2-font-size: 26px;
  --h2-line-height: 1.2;

  --h3-font-size: 22px;
  --h3-line-height: 1.2;

  --caption-font-size: 12px;
  --caption-line-height: 17px;

  --small-font-size: 12px;
  --small-line-height: 17px;

  --semi-small-font-size: 14px;
  --semi-small-line-height: 20px;

}

html, body {
  font-size: var(--body-font-size);
  font-family: var(--primary-font-family);
  font-weight: var(--normal-font-weight);
  line-height: var(--body-line-height);
}

body {
  -webkit-font-smoothing: antialiased;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-family: var(--heading-font-family);
  color: var(--black-color);
}

h1, .h1 {
  font-size: var(--h1-font-size);
  font-weight: var(--normal-font-weight);
  line-height: var(--h1-line-height);
  margin-top: var(--margin-lg);
  margin-bottom: var(--margin-lg);
}

h1.display {
  font-size: var(--h1-display-font-size);
  line-height: var(--h1-display-line-height);
  font-weight: var(--semi-bold-font-weight);
}

h2, .h2 {
  font-size: var(--h2-font-size);
  font-weight: var(--normal-font-weight);
  line-height: var(--h2-line-height);
  margin-top: var(--margin-lg);
  margin-bottom: var(--margin-lg);
}

h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  font-size: var(--h3-font-size);
  font-weight: var(--normal-font-weight);
  line-height: var(--h3-line-height);
  margin-top: var(--margin-lg);
  margin-bottom: var(--margin-lg);
}

p {
  margin-bottom: var(--margin-lg);
  font-family: var(--primary-font-family);
}

.caption {
  font-size: var(--caption-font-size);
  font-weight: var(--bold-font-weight);
  line-height: var(--caption-line-height);
  font-family: var(--heading-font-family);
  text-transform: uppercase;
}

small {
  font-size: var(--small-font-size);
  font-family: var(--primary-font-family);
  font-weight: var(--normal-font-weight);
  line-height: var(--small-line-height);
}

.bold {
  font-weight: var(--bold-font-weight);
}

.light-bold {
  font-weight: var(--light-bold-font-weight);
}

.semi-bold {
  font-weight: var(--semi-bold-font-weight);
}

.capitalize {
  text-transform: capitalize;
}

.uppercase {
  text-transform: uppercase;
}

.fraction {
  font-size: 1em;
}

.fraction>sup {
  font-weight: var(--bold-font-weight);
}

blockquote {
  border-left: 3px solid var(--primary-color);
  background-color: var(--white-dark);
  border-radius: var(--standard-border-radius);
}

.multiline-text-display {
  white-space: pre-wrap;
}

.dot::after {
  content: '.';
  animation-name: brand_colors; /* animations.css */
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}

.strikethrough {
  text-decoration: line-through;
}

code {
  background-color: var(--grey-light);
  color: var(--black-light);
}

.font-size-semi-small {
  font-size: var(--semi-small-font-size);
  line-height: var(--semi-small-line-height);
}
