.balance-tests-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.balance-chart-tooltip {
  border-radius: var(--standard-border-radius);
  background-color: var(--foreground-color);
  padding: var(--padding-md) var(--padding-lg);
  box-shadow: var(--shadow-sm);
}

.balance-filters-row .react-select {
  flex: 1;
  max-width: 450px;
  margin-bottom: var(--margin-md);
}