:root {

  --label-font-size: 12px;
  --label-font-weight: normal;
  --label-line-height: 20px;
  --label-color: var(--font-color);

  --input-background-color: var(--white-color);
  --input-disabled-background-color: var(--grey-light);
  --input-border-color: rgba(96, 96, 96, 0.125);
  --input-disabled-border-color: var(--input-border-color);
  --input-placeholder-color: var(--grey-color);
  --input-text-color: var(--font-color);
  --input-icon-color: var(--grey-color);
  --input-focused-border-color: var(--primary-color);
  --input-box-shadow: 0 0 0 2px var(--input-focused-border-color);

  --form-font-family: var(--heading-font-family);
  --input-font-size: 16px;
  --input-font-weight: normal;
  --input-line-height: 20px;

  --input-border-style: solid;
  --input-border-radius: var(--radius-lg);
  --input-border-width: 1px;

  --input-padding: 10px 15px;

  --input-message-border-style: var(--input-border-style);
  --input-message-border-radius: var(--input-border-radius);
  --input-message-border-width: var(--input-border-width);

  --input-message-padding: var(--input-padding);

}

form {
  margin-bottom: var(--margin-lg);
}

form label,
form .form-control {
  font-family: var(--form-font-family);
}

form .form-footer {
  overflow: hidden;
  padding-top: var(--margin-lg);
  width: 100%;
}

form input.form-control::placeholder {
  color: var(--input-placeholder-color) !important;
}

/***********/
/* ADDRESS */
/***********/

form .address-form-inputs {
  margin-bottom: var(--margin-sm);
}

form .address-form-inputs .address-search-input-group>label>span {
  display: flex;
}

form .address-form-inputs .address-search-input-group>label>span>.address-toggle {
  flex: 1;
  text-align: right;
}

/**********/
/* LABELS */
/**********/

form label {
  width: 100%;
  font-size: var(--label-font-size);
  font-weight: var(--label-font-weight);
  line-height: var(--label-line-height);
  color: var(--label-color);
}

form .form-group:not(.checkbox-input-group):not(.radio-input-group) > label {
  text-transform: uppercase;
}

form label .required {
  color: var(--error-color);
}

form label .required:before {
  content: ' ';
}

form .form-group {
  margin-bottom: var(--margin-sm);
}

/************/
/* CONTROLS */
/************/

form .form-control {
  height: auto;
  font-size: var(--input-font-size);
  font-weight: var(--input-font-weight);
  line-height: var(--input-line-height);
  border-color: var(--input-border-color);
  border-style: var(--input-border-style);
  border-width: var(--input-border-width);
  border-radius: var(--input-border-radius);
  background-color: var(--input-background-color);
  color: var(--input-text-color);
  padding: var(--input-padding);
  box-shadow: none;
  max-width: 100%;
}

.form-control:focus {
  box-shadow: var(--input-box-shadow);
  border-color: transparent;
}

/* remove bootstraps form-control:focus box-shadow as it has strange outline effects on checkboxes */
form input[type=checkbox].form-control:focus {
  box-shadow: none;
}

form input[type=number].form-control {
  padding-right: var(--padding-sm);
}

form input[type=radio].form-control:focus {
  box-shadow: none;
}

form input[type="date"].form-control {
  min-width: calc(100% - var(--padding-lg));
}

form:not(.search-form) button[type="submit"] {
  float: right;
}

/****************/
/* INPUT GROUPS */
/****************/

form .form-input-group.invalid .form-control,
form .form-control.invalid {
  border-color: var(--error-color);
}

form .form-input-group.invalid .form-control:focus {
  box-shadow: 0 0 0 2px #ef918f;
  border-color: var(--error-color);
}

form .form-list-input-group .form-list-input {
  padding-bottom: var(--padding-sm);
}

form .form-list-input-group ul {
  list-style: none;
  padding: 0;
  margin: 0;
  border-radius: var(--input-border-radius);
  border: 1px solid var(--grey-color);
  border-bottom: none;
}

form .form-list-input-group ul>li {
  display: flex;
  align-items: center;
  line-height: 1.2;
  padding: var(--input-padding);
}

form .form-list-input-group ul>li:not(:last-child) {
  border-bottom: 1px solid var(--grey-color);
}

form .form-list-input-group ul>li>span {
  flex: 1;
}

form .form-list-input-group ul>li>button {
  appearance: none;
  background: none;
  border: 0;
}

form .form-list-input-group ul:empty {
  display: none;
  background-color: transparent;
  border: 0;
}

form .form-list-input-group label {
  margin-bottom: 0;
  text-transform: uppercase;
}

form .form-list-input-group .input-group {
  margin-bottom: var(--margin-sm);
  height: 100%;
}

form .form-list-input-group .input-group .input-group-btn {
  height: 100%;
}

form .form-list-input-group .input-group input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

form .form-list-input-group .input-group .input-group-btn .btn {
  border-radius: 0;
  height: 100%;
  margin-bottom: 0;
}

form .form-list-input-group .input-group .input-group-btn .btn:last-child {
  border-top-right-radius: var(--input-border-radius);
  border-bottom-right-radius: var(--input-border-radius);
}

.input-group-addon {
  border-color: var(--input-border-color);
  padding: var(--input-padding);
  background-color: var(--white-dark);
  color: var(--input-text-color);
}

.input-group-addon:not(:first-child):not(:last-child) {
  border-left-width: 0;
  border-right-width: 0;
}

/****************/
/* RADIO GROUPS */
/****************/

form .radio-group-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

form .radio-group-container>label {
  width: 100%;
  margin-bottom: 0;
  text-transform: uppercase;
}

form .radio-group-container .radio-input-group {
  width: auto;
  margin-bottom: 0;
}

form .radio-group-container .radio-input-group label {
  font-size: var(--input-font-size);
}

form .radio-group-container .radio-input-group:not(:last-child) {
  margin-right: var(--margin-lg);
}

form .radio-group-container .radio-input-group>label {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: var(--padding-sm);
}

form .radio-group-container .radio-input-group>label>span {
  margin-right: var(--margin-lg);
}

form .radio-group-container .radio-input-group>label>input {
  width: auto;
  margin: 0;
}

/*******************/
/* CHECKBOX GROUPS */
/*******************/

.checkbox-group-container>label {
  text-transform: uppercase;
}

form .checkbox-input-group>label {
  display: flex;
  align-items: center;
}

form .checkbox-input-group>label>input {
  width: auto;
  margin: 0;
  margin-right: var(--margin-lg);
}

form .checkbox-input-group.checkbox-inline input[type=checkbox] {
  position: relative;
  /* override bs position absolute (makes label off center with check box) */
}

.checkbox-inline,
.checkbox-inline+.checkbox-inline {
  margin-left: 0;
  margin-right: var(--margin-lg);
}

form input[type=checkbox].form-control {
  transform: scale(1.1);
  border-color: rgba(96, 96, 96, 0.125);
}

form .form-group.checkbox-input-group label {
  font-size: var(--input-font-size);
}

/***************/
/* DATE GROUPS */
/***************/

form .date-input-group-container .date-text-input-group {
  display: none;
}

@media (min-width: 768px) {

  form .date-input-group-container .date-input-group {
    display: none;
  }

  form .date-input-group-container .date-text-input-group {
    display: block;
  }

}

/************/
/* MESSAGES */
/************/

form .form-input-message {
  border-style: var(--input-message-border-style);
  border-width: var(--input-message-border-width);
  border-radius: var(--input-message-border-radius);
  padding: var(--input-message-padding);
  width: 100%;
  flex-basis: 100%;
}

/****************/
/* FILTER INPUT */
/****************/

form.filter-form {
  margin-bottom: calc(var(--margin-lg) * 2);
}

form.filter-form>label {
  display: flex;
  flex-wrap: wrap;
}

form.filter-form>label>span {
  width: 100%;
  flex-basis: 100%;
}

form.filter-form>label>input {
  flex: 1;
  border-right-width: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

form.filter-form>label>button {
  @extend form .form-control;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: #FFF;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

form .stripe-card-element {
  padding: var(--input-padding);
  border-radius: var(--input-border-radius);
  border: 1px solid var(--input-border-color);
  margin-bottom: var(--margin-md);
  font-size: var(--input-font-size);
  background-color: var(--input-background-color);
  transition: border-color ease-in-out .15s,
    box-shadow ease-in-out .15s;
}

form .stripe-card-element.focused {
  box-shadow: var(--input-box-shadow);
  border-color: transparent;
  outline: 0;
}

.form-input-group .react-select {
  padding-bottom: 5px;
}

.form-input-group.multi-select>label {
  margin-bottom: 0;
}

.form-input-group.multi-select {
  margin-bottom: 15px;
}