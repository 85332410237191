:root {

  --button-font-size: 16px;
  --button-small-font-size: 14px;
  --button-font-weight: 300;
  --button-line-height: 1.3;
  --button-text-color: #FFF;
  --button-active-text-color: rgba(255, 255, 255, 0.65);

  --button-border-radius: 999em;
  --button-padding: 12px 30px;
  --button-padding-small: 0px 0px;

  --primary-button-background-color: var(--primary-color);
  --primary-button-disabled-background-color: var(--blue-light);
  --primary-button-border-color: var(--primary-color);
  --primary-button-disabled-border-color: var(--blue-light);

  --default-button-background-color: var(--black-color);
  --default-button-disabled-background-color: var(--grey-dark);
  --default-button-border-color: var(--black-color);
  --default-button-disabled-border-color: var(--grey-dark);

  --secondary-button-background-color: var(--secondary-color);
  --secondary-button-disabled-background-color: var(--pink-light);
  --secondary-button-border-color: var(--secondary-color);
  --secondary-button-disabled-border-color: var(--pink-light);

  --tertiary-button-background-color: var(--tertiary-color);
  --tertiary-button-disabled-background-color: var(--teal-light);
  --tertiary-button-border-color: var(--tertiary-color);
  --tertiary-button-disabled-border-color: var(--teal-light);

  --icon-button-font-size: 32px;
  --icon-button-line-height: 1;
}

.btn {
  overflow: hidden;
  position: relative;
  font-size: var(--button-font-size);
  font-weight: var(--button-font-weight);
  line-height: var(--button-line-height);
  color: var(--button-text-color);
  border-radius: var(--button-border-radius);
  padding: var(--button-padding);
  margin-bottom: var(--margin-sm);
  outline: none;
  font-family: var(--primary-font-family);
}

.btn:not(:disabled)::after {
  position: absolute;
  top: 50%;
  left: 50%;
  content: " ";
  width: 100%;
  height: inherit;
  padding: 100% 0 0 0;
  opacity: 0;
  border-radius: 999em;
  transform: translate(-50%, -50%) scale(0);
  transition-delay: 200ms;
  transition-duration: 400ms;
  transition-timing-function: ease-out;
}

.btn:not(:disabled):hover::after {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1);
}

.btn.btn-sm {
  padding: var(--padding-sm) var(--padding-lg);
  font-size: var(--button-small-font-size);
}

.btn:hover,
.btn:active,
.btn:active:focus {
  color: var(--button-text-color);
}

.btn:focus,
.btn:active:focus {
  outline: none;
}

/** DEFAULT BUTTON */

.btn.btn-default {
  background-color: var(--default-button-background-color);
  border-color: var(--default-button-border-color);
}

.btn.btn-default:disabled {
  background-color: var(--default-button-disabled-background-color);
  border-color: var(--default-button-disabled-border-color);
  opacity: 1;
}

.btn.btn-default:hover::after {
  background: radial-gradient(rgba(172, 172, 172, 0.35), rgba(218, 218, 218, 0.35));
}

.btn.btn-default:focus {
  outline: 5px var(--primary-color);
}

.btn.btn-default:active:focus {
  color: #fff;
  background-color: var(--black-light);
  border-color: var(--black-light);
}

/** PRIMARY BUTTON */

.btn.btn-primary {
  background-color: var(--primary-button-background-color);
  border-color: var(--primary-button-border-color);
}

.btn.btn-primary:disabled {
  background-color: var(--primary-button-disabled-background-color);
  border-color: var(--primary-button-disabled-border-color);
  opacity: 0.8;
}

.btn.btn-primary:hover::after {
  background: radial-gradient(rgba(33, 150, 241, 0.35), rgba(90, 185, 249, 0.35));
}

.btn.btn-primary:active:focus {
  color: #fff;
  background-color: var(--blue-dark);
  border-color: var(--blue-dark);
}

/** SECONDARY BUTTON */

.btn.btn-secondary {
  background-color: var(--secondary-button-background-color);
  border-color: var(--secondary-button-border-color);
}

.btn.btn-secondary:disabled {
  background-color: var(--secondary-button-disabled-background-color);
  border-color: var(--secondary-button-disabled-border-color);
  opacity: 1;
}

.btn.btn-secondary:hover::after {
  background: radial-gradient(rgba(219, 100, 224, 0.35), rgba(219, 148, 224, 0.35));
}

.btn.btn-secondary:active:focus {
  color: #fff;
  background-color: var(--pink-dark);
  border-color: var(--pink-dark);
}

/** TERTIARY BUTTON */

.btn.btn-tertiary {
  background-color: var(--tertiary-button-background-color);
  border-color: var(--tertiary-button-border-color);
}

.btn.btn-tertiary:disabled {
  background-color: var(--tertiary-button-disabled-background-color);
  border-color: var(--tertiary-button-disabled-border-color);
  opacity: 1;
}

.btn.btn-tertiary:hover::after {
  background: radial-gradient(rgba(72, 240, 203, 0.35), rgba(138, 255, 227, 0.35));
}

.btn.btn-tertiary:active:focus {
  color: #fff;
  background-color: var(--teal-dark);
  border-color: var(--teal-dark);
}

/** SUCCESS BUTTON */

.btn.btn-success {
  background-color: var(--success-color);
  border-color: var(--success-color);
}

.btn.btn-success:disabled {
  background-color: var(--success-color);
  border-color: var(--success-color);
  opacity: 0.6;
}

.btn.btn-success:hover::after {
  background: radial-gradient(rgba(72, 240, 203, 0.35), rgba(138, 255, 227, 0.35));
}

.btn.btn-success:active:focus {
  color: #fff;
  background-color: var(--success-color);
  border-color: var(--success-color);
}

/** DANGER BUTTON */

.btn.btn-danger {
  background-color: var(--error-color);
  border-color: var(--error-color);
}

.btn.btn-danger:disabled {
  background-color: var(--error-color);
  border-color: var(--error-color);
  opacity: 0.6;
}

.btn.btn-danger:hover::after {
  background: radial-gradient(rgba(219, 100, 224, 0.35), rgba(219, 148, 224, 0.35));
}

.btn.btn-danger:active:focus {
  color: #fff;
  background-color: var(--error-color);
  border-color: var(--error-color);
}

.btn.btn-grey {
  background-color: var(--white-dark);
  color: var(--black-color);
  border-color: var(--white-dark);
}

.btn.btn-grey:disabled {
  background-color: var(--grey-light);
  border-color: var(--grey-light);
  opacity: 0.6;
}

.btn.btn-grey:hover::after {
  background: radial-gradient(rgba(172, 172, 172, 0.35), rgba(218, 218, 218, 0.35));
}

.btn.btn-grey:active:focus {
  background-color: var(--grey-color);
  border-color: var(--grey-color);
}

.btn.btn-light {
  background-color: #fff;
  border: 1px solid var(--grey-light);
  color: var(--black-color);
  box-shadow: none;
}

.btn.btn-light:hover {
  background-color: var(--white-dark);
  color: var(--blue-dark);
}

.btn.btn-light:active {
  background-color: var(--primary-highlight-color);
  color: var(--blue-dark);
}

.btn-group .btn.btn-light.selected {
  background-color: var(--primary-color);
  color: #fff;
}

button.edit-button,
a.edit-button {
  appearance: none;
  background: none;
  padding: 0;
  border: 0;
  @extend .caption;
  text-transform: uppercase;
  color: var(--primary-color);
}

button.edit-button:disabled,
a.edit-button:disabled {
  color: var(--primary-button-disabled-background-color);
}

button.edit-button:not(:disabled):hover {
  text-decoration: underline;
}

button.edit-button.warning,
a.edit-button.warning {
  color: var(--warning-color);
}

button.edit-button.danger,
a.edit-button.danger {
  color: var(--error-color);
}

button.edit-button > .fa {
  margin-right: var(--margin-sm);
}

button:not(:first-of-type),
a.btn:not(:first-of-type) {
  margin-left: var(--margin-lg);
}

button.icon-button,
button.help-button {
  appearance: none;
  background: none;
  padding: 0;
  border: 0;
  color: var(--primary-color);
}

button.help-button.inverted {
  color: #FFF;
}

button.icon-button > .fa {
  font-size: var(--icon-button-font-size);
  line-height: var(--icon-button-line-height);
}

.btn-group button:not(:last-of-type) {
  margin-right: 0;
}

.btn-group.soap-test-btn-group {
  display: flex;
  flex-wrap: wrap;
}

.btn-group.soap-test-btn-group>.btn {
  flex: 1;
}

button.btn-print,
button.btn-clinic,
button.btn-small-right-floated {
  float: right;
  font-size: var(--semi-small-font-size);
  padding: var(--margin-sm) var(--margin-lg);
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

button.btn-read-more {
  appearance: none;
  background: none;
  padding: 0;
  border: 0;
  color: var(--primary-color);
}

button.btn-read-more:hover {
  text-decoration: underline;
}

button.btn-read-more:focus {
  outline: none;
}

button.paid-invoices-btn {
  color: var(--primary-color);
  background-color: transparent;
  padding: var(--button-padding-small);
  border: none;
}

button.paid-invoices-btn:hover {
  color: var(--primary-color);
  text-decoration: underline;
}

button.bulk-purchase {
  font-size: var(--small-font-size);
  padding: var(--margin-sm) var(--margin-lg);
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.input-group .input-group-btn .btn {
  font-size: var(--input-font-size);
  line-height: var(--input-line-height);
  padding: calc(var(--padding-sm) + 1px) calc(var(--padding-sm) * 2);
  border-radius: var(--input-border-radius);
}

.btn.btn-edit {
  font-size: var(--small-font-size);
  padding: var(--margin-sm) var(--margin-lg);
  margin-right: var(--margin-lg);
  margin-left: var(--margin-lg);
  text-decoration: none;
}

.btn.btn-narrow {
  padding: var(--padding-sm) calc(var(--padding-sm) * 2);
}

.btn.btn-wide {
  width: 100%;
}

.btn-link {
  color: var(--primary-color);
  text-decoration: none;
  background-color: transparent;
  padding: 0;
  outline: none;
}

.btn-link:hover {
  text-decoration: underline;
}

.btn-link:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

button.close {
  color: var(--grey-dark);
  text-shadow: none;
  opacity: 0.6;
}

button.close:hover {
  color: var(--black-dark);
}