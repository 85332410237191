:root {
  --_dana-baseline-indicator-offset: 3px;
  --_dana-baseline-indicator-size: 14px;
  --_dana-baseline-indicator-border-size: 1px;
}

.clinic-patients-section {
  margin-bottom: var(--margin-lg);
  border-bottom-width: var(--standard-border-width);
  border-bottom-style: var(--standard-border-style);
  border-bottom-color: var(--standard-border-color);
}

.clinic-patients-section.clinic-patients-stats,
.clinic-patients-section.clinic-patients-list {
  border-bottom-width: 0;
}

.clinic-patients-stats ul {
  margin-bottom: 0;
}

.clinic-patients-stats ul>li {
  display: flex;
  padding-bottom: var(--padding-sm);
  margin-bottom: var(--margin-sm);
  border-bottom-width: var(--standard-border-width);
  border-bottom-style: var(--standard-border-style);
  border-bottom-color: var(--standard-border-color);
}

.clinic-patients-stats ul>li:last-child {
  margin-bottom: 0;
}

.clinic-patients-stats ul>li>strong {
  flex: 1;
}

.clinic-patients-account-retrieval-form .help-button {
  display: inline-block;
  margin-left: var(--margin-sm);
}

.clinic-patients .search-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.clinic-patients .search-header>.search-control {
  width: 450px;
}

.clinic-patients .search-header>.actions {
  display: flex;
}

.clinic-patients .search-header>.actions .btn {
  margin-left: 10px;
}

.clinic-patients .search-header .btn {
  margin-bottom: 0;
}

.clinic-patients .tabs.patient-tabs .tabs-bar {
  position: sticky;
  margin: 0 -1px;
  top: var(--navigation-bar-height);
  background-color: var(--background-color);
  margin-bottom: calc(var(--margin-md) * 2);
  z-index: 1;
}

.clinic-patients .tabs.patient-tabs .referrals-tab {
  display: flex;
  align-items: center;
}

.clinic-patients .tabs.patient-tabs .referrals-tab .badge {
  margin-left: var(--margin-sm);
  padding: 3px 5px;
}

.clinic-patients .tabs.patient-tabs .tabs-content {
  padding-bottom: calc(var(--margin-lg) * 3);
}

.clinic-patients-table .dana-baseline-count {
  width: 80px;
  text-align: center;
  vertical-align: middle;
}

.clinic-patients-table .dana-baseline-count .dana-baseline-indicators {
  display: flex;
  align-items: center;
  padding-inline-start: var(--_dana-baseline-indicator-offset);
}

.clinic-patients-table .dana-baseline-count .dana-baseline-indicators .indicator-item {
  background-color: var(--white-base);
  font-size: var(--_dana-baseline-indicator-size);
  color: var(--grey-light);
  height: calc((var(--_dana-baseline-indicator-border-size) * 2) + var(--_dana-baseline-indicator-size));
  width: calc((var(--_dana-baseline-indicator-border-size) * 2) + var(--_dana-baseline-indicator-size));
  border-radius: 50%;
  border: var(--_dana-baseline-indicator-border-size) solid var(--white-base);
  margin-inline-start: calc(var(--_dana-baseline-indicator-offset) * -1);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.clinic-patients-table .dana-baseline-count .dana-baseline-indicators .indicator-item.green {
  color: var(--success-color);
}

.clinic-patients-table .dana-baseline-count .dana-baseline-indicators .indicator-item.light {
  color: var(--grey-color);
}

@media (max-width: 992px) {
  .clinic-patients .search-header>.search-control {
    width: 100%;
  }

  .clinic-patients .search-header .actions {
    margin-left: auto;
  }
}