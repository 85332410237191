ul.notifications-list {
  list-style: none;
  padding: 0;
}

ul.notifications-list li.notification-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--padding-lg);
  margin-bottom: var(--margin-lg);
}