/** Bulk Credit Purchase Product Cards **/
.credit-products {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: calc(var(--margin-lg) * 2);
  gap: 5px;
}

.credit-products .product-card {
  border: 1px solid var(--grey-light);
  border-radius: var(--radius-md);
  min-height: 260px;
  min-width: 200px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  transition: transform 0.2s ease-in-out, box-shadow 0.25s linear;
}

.credit-products .product-card:hover {
  transform: scale(1.05);
  border-color: var(--primary-color);
  box-shadow: var(--shadow-md);
}

.credit-products .product-card .product-details {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.credit-products .product-card .product-details .detail-item {
  margin-bottom: var(--margin-sm);
  font-size: 14px;
}

.credit-products .product-card .product-details .product-heading {
  font-size: 48px;
  color: var(--primary-color);
  font-weight: 600;
  margin-bottom: calc(var(--margin-lg) * 2);
}

/** Bulk Credits Purchase Modal Views **/
.bulk-credits-purchase .views-container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.bulk-credits-purchase .views-container .loading-view {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.bulk-credits-purchase .views-container .confirmation-view {
  max-width: 650px;
}

.bulk-credits-purchase .views-container .confirmation-view,
.bulk-credits-purchase .views-container .status-view {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.bulk-credits-purchase .views-container .confirmation-view .confirmation-view-content {
  flex: 1;
}

.bulk-credits-purchase .views-container .status-view .status-view-content {
  flex: 1;
  text-align: center;
}

.bulk-credits-purchase .views-container .confirmation-view .balance-footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 15px;
  font-size: 18px;
}

.bulk-credits-purchase .views-container .confirmation-view .confirmation-view-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bulk-credits-purchase .views-container .status-view .status-view-footer {
  align-self: center;
  padding-top: var(--padding-lg);
}