.baseline-step-routes {
  padding-bottom: calc(var(--padding-xl) * 2);
  margin: 0 auto;
  max-width: 1280px;
}

.baseline-step-routes .baseline-step-layout {
  display: flex;
}

/* Baseline Header */
.baseline-step-routes .baseline-title {
  display: flex;
  align-items: center;
  margin-bottom: var(--margin-lg);
}

.baseline-step-routes .baseline-title .baseline-sidebar-toggle {
  display: none;
  justify-content: center;
  align-items: center;
  padding: 0;
  border: none;
  background-color: transparent;
  margin-right: var(--margin-lg);
  font-size: 22px;
  height: 30px;
  width: 30px;
}

.baseline-step-routes .baseline-title h1 {
  margin: 0;
}

.baseline-step-routes .back-link {
  margin-top: var(--margin-lg);
  display: block;
}

/* Baseline Steps Sidebar */
.baseline-step-layout .baseline-sidebar-section {
  width: 300px;
  margin-right: var(--margin-lg);
  overflow: hidden;
}

.baseline-sidebar-section .baseline-sidebar {
  width: 100%;
}

.baseline-sidebar .baseline-user-info {
  padding-top: var(--padding-md);
  padding-bottom: var(--padding-md);
}

.baseline-sidebar .baseline-user-info .profile-info {
  display: flex;
  line-height: 1;
}

.baseline-sidebar .baseline-user-info .profile-info .profile-image {
  height: 50px;
  width: 50px;
  object-fit: contain;
  border-radius: 50%;
  margin-right: var(--margin-md);
}

.baseline-sidebar ul.sidebar-sections {
  margin: 0;
  padding: var(--padding-lg) 0;
  list-style: none;
}

.baseline-sidebar ul.sidebar-sections li.sidebar-section {
  padding: var(--padding-md) 0;
}

.baseline-sidebar ul.sidebar-sections li.sidebar-section .section-title {
  font-size: var(--semi-small-font-size);
  font-weight: 500;
  font-family: var(--heading-font-family);
  margin-bottom: var(--margin-sm);
  padding: 0 var(--padding-md);
}

.baseline-sidebar ul.sidebar-sections li.sidebar-section ul.section-steps {
  margin: 0;
  padding: 0;
  list-style: none;
}

li.sidebar-section ul.section-steps li {
  position: relative;
}

li.sidebar-section ul.section-steps .step {
  padding: var(--padding-md) var(--padding-lg);
  border-radius: var(--radius-md);
  line-height: 1;
  margin-left: var(--margin-sm);
  cursor: not-allowed;
  transition: all 0.2s linear;
  opacity: 0.6;
}

li.sidebar-section ul.section-steps .step.active {
  color: var(--primary-color);
}

li.sidebar-section ul.section-steps .step.active::before {
  content: '';
  position: absolute;
  left: 0;
  top: 8px;
  bottom: 8px;
  width: 2px;
  background-color: var(--primary-color);
}

li.sidebar-section ul.section-steps a.step {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  opacity: 1;
}

li.sidebar-section ul.section-steps a.step:hover {
  background-color: var(--white-dark);
}

li.sidebar-section ul.section-steps .step .skipped {
  font-size: var(--semi-small-font-size);
}

li.sidebar-section ul.section-steps .step .complete-icon {
  margin-left: var(--margin-md);
}

.drawer.baseline-sidebar-drawer .drawer-content {
  overflow-y: scroll;
  height: 100%;
  background-color: var(--background-color);
  padding: var(--padding-xl) var(--padding-md);
  position: relative;
  max-width: 330px;
  width: 100%;
}

.drawer.baseline-sidebar-drawer .drawer-content .close-button {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: var(--white-dark);
  font-size: 20px;
  position: absolute;
  top: var(--padding-md);
  right: var(--padding-md);
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 0;
  margin: 0;
}

/* Baseline Steps Content */
.baseline-step-layout .baseline-step-content {
  flex: 1;
}

.baseline-step-content .baseline-step-header {
  display: flex;
  justify-content: flex-end;
  margin-bottom: var(--margin-lg);
}

@media (max-width: 992px) {
  .baseline-step-routes .baseline-sidebar-section {
    display: none;
  }

  .baseline-step-routes .baseline-title .baseline-sidebar-toggle {
    display: flex;
  }
}
