@media print {
  .panel,
  .panel .panel-heading,
  .panel .panel-body {
    border: none;
  }

  .panel .panel-heading {
    display: none;
    padding-left: 0;
  }

  .panel .panel-heading.opened {
    display: block;
  }

  .panel .panel-body {
    padding: none;
  }
}