.clinics form.clinics-search-form {
  margin-bottom: 0;
}

.clinics form.clinics-search-form label {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0;
}

.clinics .clinics-search-filters {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  position: sticky;
  flex-wrap: wrap-reverse;
  top: var(--navigation-bar-height);
  background-color: var(--background-color);
  z-index: 10;
  padding: 10px 5px 5px 5px;
  margin: 0 -5px;
}

.clinics .clinics-search-filters form.clinics-search-form label>input {
  flex: 1;
  border-right-width: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.clinics .clinics-search-filters .name-search-filter {
  width: 350px;
}

.clinics .clinics-search-filters form.clinics-search-form label>button {
  @extend form .form-control;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: #FFF;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.clinics .clinics-pagination-container {
  display: flex;
  justify-content: center;
  position: sticky;
  bottom: 0;
  background-color: var(--background-color);
  border: 1px solid var(--background-color);
  margin: 0px -5px;
}

.clinics .clinics-pagination-container .pagination {
  margin: 10px 0px 15px;
}

.clinics .results-count {
  font-size: 14px;
}

.clinics .clinics-stat {
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.clinics .clinics-stat>.stat-label {
  font-size: 14px;
  margin-bottom: 10px;
}

.clinics .clinics-stat .stat-value-group {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.clinics .clinics-stat .stat-value {
  font-size: 28px;
  margin-bottom: 10px;
  font-weight: bolder;
  color: var(--secondary-color);
}

.clinics .clinics-stat .stat-value>.currency {
  font-size: 18px;
}

.clinic-registration-success-icon {
  text-align: center;
  margin-bottom: var(--margin-lg);
}

.clinics-table .clinic-title-row {
  display: flex;
  justify-content: space-between;
}

.clinics-table .clinic-title-row .clinic-title a {
  display: flex;
  flex: 1;
  align-items: center;
}

.clinics-table .clinic-title-row .clinic-title img {
  border-radius: 50%;
  height: 28px;
  width: 28px;
  object-fit: cover;
  margin-right: 10px;
}

.clinics-table .clinic-title-row .clinic-title img.default {
  object-fit: contain;
}

.clinics-table .clinic-title-row .clinic-icons {
  display: flex;
  align-items: center;
}

.clinics-table .clinic-title-row .clinic-icons img.maple-icon {
  height: 16px;
  width: 16px;
  border-radius: 2px;
}

.clinics-table .clinic-title-row .clinic-icons img.axia-idt-provider-icon {
  height: 16px;
  width: 16px;
  object-fit: cover;
  object-position: left;
}

.clinics-table .clinic-title-row .clinic-icons .clinic-icon:not(:first-child) {
  margin-left: 10px;
}

.clinic-hours-input-table>thead>tr>th {
  padding: 3px;
}

.clinic-hours-input-table>tbody>tr:nth-child(2n + 1) {
  background-color: transparent;
}

.clinic-hours-input-table>tbody>tr:hover {
  background-color: var(--grey-light);
}

.clinic-hours-input-table>tbody>tr,
.clinic-hours-input-table>tbody>tr>td,
.clinic-hours-input-table>tbody>tr:first-child,
.clinic-hours-input-table>tbody>tr>td:first-child {
  border: none;
}

.clinic-hours-input-table>tbody>tr>td,
.clinic-hours-input-table>tbody>tr>td:first-child {
  padding: 5px;
  vertical-align: middle;
}

.clinic-hours-input-table .hours-copy-btn {
  background-color: transparent;
  border: none;
  border-radius: 50%;
  outline: none;
  height: 30px;
  width: 30px;
}

.clinic-hours-input-table .hours-copy-btn:hover {
  background-color: var(--grey-color);
}

.clinic-hours-display {
  margin-bottom: 15px;
}

.clinic-hours-display .clinic-hours-title {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 1px solid var(--primary-color);
  margin-bottom: 5px;
}

.clinic-hours-display .clinic-hours-title>h3 {
  margin-bottom: 0px;
  margin-top: 0px;
}

.clinic-hours-display .clinic-hours-list {
  font-size: 14px;
}

.clinic-hours-display .clinic-hours-list .clinic-hours-list-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.invite-clinic-user-modal .dialog {
  min-width: 60%;
}

.clinic-users .subheading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.clinic-features-display {
  margin-bottom: var(--margin-lg);
}

.clinic-features-display .clinic-features-title {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-bottom: 1px solid var(--primary-color);
  margin-bottom: var(--margin-sm);
}

.clinic-features-display .clinic-features-title > h3 {
  margin-bottom: 0px;
  margin-top: 0px;
}

.clinic-features-display ul.clinic-features-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

.clinic-features-display ul.clinic-features-list li {
  display: flex;
  align-items: center;
  gap: var(--padding-md);
  font-size: var(--semi-small-font-size);
  margin-bottom: var(--margin-xs);
}

@media (max-width: 796px) {
  .clinics .clinics-search-filters {
    margin: 0;
  }

  .clinics .clinics-search-filters .name-search-filter {
    width: 100%;
  }
}