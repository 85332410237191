/*
 * Neurological Examination Section
 */
.neuro-exam-test {
  padding-bottom: var(--padding-lg);
}

/*
 * Current Rated Symptoms Section
 */
.overall-rating {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.overall-rating .rating-left {
  padding-right: var(--padding-lg);
}

.overall-rating .rating-right {
  padding-left: var(--padding-lg);
}

/*
 * Injury Stages Section
 */

.assessment-injury-stages .radio-group-container {
  flex-direction: column;
}

.assessment-injury-stages label>span {
  order: 2;
  margin-left: var(--margin-lg);
}

.assessment-injury-stages label>input[type=radio] {
  order: 1;
}

.initial-management-form .initial-management-row {
  padding-bottom: var(--padding-lg);
}

/* 
  * Assement Header Classes 
  */

.injury-assessment-header h3 {
  padding-bottom: var(--padding-sm);
  border-bottom: var(--standard-border-width) solid var(--grey-light);
}

.injury-assessment-header {
  margin-bottom: calc(var(--margin-lg) * 2);
  border-bottom: var(--standard-border-width) solid var(--grey-light);
  padding-bottom: var(--padding-lg)
}

.injury-assessment-tabs.tabs .tabs-bar button.tab-button {
  flex: 1;
}

.injury-assessment-tabs.tabs .tabs-bar button.tab-button.alert {
  font-weight: bolder;
}


.injury-assessment-tests .test-hidden {
  display: none;
}

.injury-assessment-tests .test-active {
  display: block;
  padding-top: 40px;
}

.injury-assessment-tests {
  padding-bottom: calc(var(--padding-lg) * 4);
}