:root {

  --activity-indicator-font-size: 36px;
  --activity-indicator-color: var(--primary-color);

}

.activity {
  position: relative;
}

.activity .activity-container {
  width: 100%;
}

.activity .activity-container.static.active {
  display: flex;
  justify-content: center;
  padding: var(--padding-lg);
}

.activity .activity-overlay {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(250, 250, 250, 0.3);
}

.activity .activity-overlay.visible {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
}

.activity .activity-indicator-container {
  text-align: center;
}

.activity .activity-indicator-container .activity-indicator {
  font-size: var(--activity-indicator-font-size);
  color: var(--activity-indicator-color);
  /* animation: rotate 1.5s linear infinite; */
}