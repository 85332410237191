.new-claims-container {
  padding-bottom: var(--padding-lg);
}

.idt-claim-header {
  margin-bottom: var(--margin-xl);
}

.idt-claim-header h1 {
  margin-bottom: 0;
}

.idt-claim-header .label {
  font-size: 85%;
}

.claim-section {
  width: 100%;
  height: 100%;
  border: 1px solid var(--white-dark);
  border-radius: var(--radius-lg);
  background-color: var(--white-light);
  padding: var(--padding-lg);
  margin-bottom: var(--margin-lg);
}

.claim-section .claim-section-header {
  display: flex;
  align-items: center;
}

.claim-section.documents-section .claim-section-header {
  justify-content: space-between;
}

.claim-section .claim-section-content {
  padding-top: var(--padding-xl);
}

.claim-section .claim-section-content .required-appt-indicators {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: var(--padding-lg);
}

.claim-section .claim-section-content .required-appt-indicators .ot-required-indicator {
  padding: var(--padding-xs) var(--padding-md);
  margin: calc(var(--padding-xs) * -1) 0;
  background-color: transparent;
  border-radius: var(--radius-md);
}

.claim-section .claim-section-content .required-appt-indicators .ot-required-indicator.warn {
  background-color: var(--error-highlight-color);
  color: var(--error-color);
}

.claim-section .claim-section-header .claim-section-title {
  margin-left: var(--margin-md);
  line-height: 1;
  flex: 1;
  font-size: 24px;
  font-weight: 600;
}

.claim-section .claim-section-header > .fa {
  color: var(--primary-color);
  font-size: 24px;
}

.claim-section .claim-section-row {
  display: flex;
  align-items: flex-end;
  margin-bottom: var(--margin-md);
  line-height: 1;
}

.claim-section .claim-section-row label {
  width: 40%;
  margin-right: var(--margin-lg);
  margin-bottom: 0;
  text-transform: uppercase;
  line-height: 1;
}

.claim-section .claim-section-header .claim-block-details {
  display: flex;
  align-items: center;
  gap: var(--padding-lg);
}

.appt-modal-header h2 {
  margin: 0;
}

.appt-modal-header .block-duration-label {
  display: flex;
  align-items: center;
  gap: var(--padding-sm);
  line-height: 1;
  margin-top: var(--margin-sm);
  font-size: var(--semi-small-font-size);
}

.claim-documents {
  display: flex;
  gap: var(--padding-xl);
  align-items: flex-start;
  flex-wrap: wrap;
}

.claim-documents a {
  color: inherit;
}

.claim-documents .document-link {
  display: flex;
  align-items: center;
  min-width: 200px;
  gap: var(--padding-md);
  border-radius: var(--radius-lg);
  background-color: transparent;
  padding: var(--padding-md);
  border: 1px solid var(--grey-light);
}

.claim-documents .document-link:hover {
  background-color: var(--white-color);
}

.initial-appt-banner {
  padding: calc(var(--padding-lg) * 2);
  background-color: var(--blue-dark);
  border-radius: var(--radius-lg);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: calc(var(--padding-xl) * 2);
  margin-bottom: var(--margin-lg);
}

.initial-appt-banner .banner-content {
  flex: 1;
  min-width: 60%;
  color: var(--white-light);
}

.initial-appt-banner .banner-content .title {
  font-weight: 700;
  font-family: var(--heading-font-family);
  font-size: var(--h3-font-size);
  line-height: 1;
  margin-bottom: var(--margin-md);
}

.initial-appt-banner .btn.btn-primary {
  background-color: var(--blue-light);
  font-weight: 600;
}

.modal.claim-appointment-modal > .dialog {
  width: 60%;
}

.claim-appt-form .appt-confirmation {
  font-size: var(--semi-small-font-size);
  line-height: 1;
  padding: var(--padding-sm);
}

.appt-providers-list {
  margin-bottom: var(--margin-lg);
}

.appt-providers-list .app-providers-list-row {
  display: flex;
  gap: var(--padding-xl);
  align-items: flex-start;
  padding: var(--padding-md);
  border: 1px solid var(--white-dark);
  border-radius: var(--radius-md);
  margin-bottom: var(--margin-md);
}

.appt-providers-list .app-providers-list-row .form-group,
.appt-providers-list .app-providers-list-row .form-group label {
  margin-bottom: 0;
}

.appt-providers-list .app-providers-list-row .app-providers-list-input {
  flex: 1;
}

.appt-providers-list .appt-providers-list-add {
  width: 100%;
  border: 2px dashed var(--grey-light);
  border-radius: var(--radius-md);
  padding: var(--padding-md) var(--padding-lg);
  background-color: var(--white-color);
  font-weight: 500;
}

.appt-providers-list .appt-providers-list-add:hover {
  background-color: var(--white-dark);
  color: var(--primary-color);
}

.appt-providers-list .app-providers-list-row .remove-btn {
  align-self: center;
  margin-top: var(--margin-lg);
  height: 30px;
  width: 30px;
  background-color: var(--error-color);
  border-radius: var(--radius-md);
  border: 1px solid var(--error-color);
  color: var(--white-light);
}

.idt-claim-treatment-blocks .block-order {
  height: 40px;
  width: 40px;
  font-weight: 600;
  font-family: var(--heading-font-family);
  font-size: 30px;
  border-radius: 50%;
  border: 1px solid var(--white-dark);
  display: flex;
  align-items: center;
  justify-content: center;
}

.claim-section .claim-section-header .block-duration-label {
  font-weight: 400;
  font-size: var(--semi-small-font-size);
  margin-top: 3px;
}

.idt-appointments-table {
  width: 100%;
  margin-bottom: var(--margin-md);
}

.idt-appointments-table tr {
  border-bottom: 1px solid var(--white-dark);
}

.idt-appointments-table td {
  padding: var(--padding-md) var(--padding-xl);
  vertical-align: middle;
}

.idt-appointments-table .idt-appt-status-row {
  display: flex;
  align-items: center;
  gap: var(--padding-lg);
}

.idt-appointments-table .idt-appt-status-row .dropdown {
  min-width: 180px;
}

.idt-appointments-table .idt-appt-status-button {
  display: flex;
  align-items: center;
  border: none;
  box-shadow: none;
  background-color: transparent;
  border-radius: var(--radius-md);
  gap: var(--padding-xl);
  line-height: 1;
  padding: var(--padding-sm) var(--padding-md);
  min-width: 180px;
}

.idt-appointments-table .idt-appt-status-button.selected {
  background-color: var(--black-base);
  color: var(--white-color);
}

.idt-appointments-table .idt-appt-status-button:disabled {
  opacity: 0.7;
}

.idt-appointments-table .idt-appt-status-button.selected .status .fa {
  color: inherit;
}

.idt-appointments-table .idt-appt-status-button:not(.selected):not(:disabled):hover {
  background-color: var(--white-dark);
}

.idt-appointments-table .idt-appt-status-button .status {
  display: flex;
  align-items: center;
  flex: 1;
  gap: var(--padding-sm);
}

.idt-appointments-table .idt-appt-status-button .status .status-label {
  margin-top: var(--margin-xs);
}

.table.idt-reports-table {
  margin-bottom: 0;
}

.table.idt-reports-table>thead>tr>th {
  color: var(--black-color);
  cursor: default;
}

.table.idt-reports-table > tbody > tr {
  background-color: transparent;
}

.table.idt-reports-table .report-action-cell {
  display: flex;
  align-items: center;
  gap: var(--padding-md);
}

.table.idt-reports-table .report-action-cell .comment-indicator {
  font-family: var(--heading-font-family);
  font-size: var(--small-font-size);
  line-height: 1;
  font-weight: var(--light-bold-font-weight);
}

.table.idt-reports-table .report-action-cell .comment-indicator span {
  font-size: var(--semi-small-font-size);
}

.modal.idt-embed-modal .dialog-body {
  padding: 0;
  overflow: hidden;
}

.modal.idt-embed-modal .dialog iframe {
  display: block;
  padding: 0;
  margin: 0;
  border: 0;
  height: 100dvh;
  width: 100%;
}

.modal.idt-embed-modal .dialog .modal-close-button {
  position: absolute;
  top: var(--padding-md);
  right: var(--padding-lg);
  height: 40px;
  width: 40px;
  font-size: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: var(--grey-light);
}

.modal.idt-embed-modal .dialog .modal-close-button:hover {
   background-color: var(--grey-base);
}

.modal.idt-embed-modal.report-view-modal .dialog {
  max-width: 1024px;
  height: 100%;
  border-radius: var(--radius-xl);
}

.modal.idt-document-modal .dialog {
  height: calc(100% - 40px);
  width: 80%;
  border-radius: var(--radius-xl);
  background-color: transparent;
  border: none;
  overflow: visible;
}

.modal.idt-document-modal.full .dialog {
  max-width: 85%;
  height: calc(100% - 20px);
  max-height: calc(100% - 40px);
  width: 85%;
}

.modal.idt-document-modal .dialog .dialog-body {
  padding: 0;
  position: relative;
}

.modal.idt-document-modal .dialog iframe {
  border: none;
  height: 100%;
  width: 100%;
  border-radius: var(--radius-xl);
  background-color: var(--white-light);
}

.modal.idt-document-modal .dialog .document-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.modal.idt-document-modal .dialog .document-container .frame-container {
  flex: 1;
}

.modal.idt-document-modal .dialog .document-container .footer-container {
  padding: var(--padding-md) var(--padding-xl);
  border-radius: var(--radius-xl);
  background-color: var(--white-light);
  margin-top: var(--margin-md);
}

.modal.idt-document-modal .btn-close {
  position: fixed;
  top: 10px;
  right: -45px;
  color: var(--black-color);
  height: 36px;
  width: 36px;
  font-size: 20px;
  border-radius: 50%;
  background-color: var(--grey-light);
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--grey-light);
}

.document-container .footer-container .approval-form-banner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--padding-xl);
}

.document-container .footer-container .approval-form-banner input.form-control[type=checkbox] {
  height: 20px;
  width: 20px;
  border-radius: var(--radius-md);
  margin-top: var(--margin-sm);
}

.document-container .footer-container .approval-form-banner .checkbox-input-group label {
  font-size: 18px;
  align-items: flex-start;
  font-weight: 500;
}

.idt-final-report-link {
  appearance: none;
  border: 0;
  padding: 0;
  background-color: transparent;
  color: var(--primary-color);
}

.idt-final-report-link:hover {
  color: var(--blue-dark);
  text-decoration: underline;
}

.modal.idt-documents-upload-modal .dialog {
  width: 50%;
}

.idt-document-dropzone {
  padding: var(--padding-lg);
  width: 100%;
  min-height: 140px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed var(--grey-light);
  border-radius: var(--radius-lg);
  cursor: pointer;
}

.idt-document-dropzone:hover {
  background-color: var(--white-dark);
}

.idt-document-dropzone .dropzone-inner {
  display: flex;
  align-items: center;
  gap: var(--padding-md);
  white-space: nowrap;
}

.idt-document-dropzone .dropzone-inner .fa {
  font-size: 24px;
}

.idt-document-dropzone .dropzone-inner .description {
  font-weight: 500;
}

.idt-documents-upload-list .upload-title {
  font-size: 20px;
  font-weight: 600;
  margin: var(--margin-md) 0;
}

.idt-documents-upload-list .idt-documents-upload-table {
  width: 100%;
  max-width:100%;
  white-space:nowrap;
}

.idt-documents-upload-list .idt-documents-upload-table td {
  padding: var(--padding-sm);
}

.idt-documents-upload-list .idt-documents-upload-table .trash-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 26px;
  width: 26px;
  border-radius: var(--radius-md);
  background-color: var(--error-color);
  border: 1px solid var(--error-color);
  color: var(--white-color);
  font-size: var(--semi-small-font-size);
}

table.table.idt-documents-table > tbody > tr {
  background-color: transparent;
}

.idt-documents-table  .idt-document-list-link {
  appearance: none;
  padding: 0;
  border: none;
  box-shadow: none;
  background-color: transparent;
}

.idt-documents-table  .idt-document-list-link:hover {
  text-decoration: underline;
  color: var(--primary-color);
}

.idt-resources-section {
  margin-bottom: var(--margin-xl);
}

.idt-videos-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: var(--padding-md);
  row-gap: var(--padding-xl);
  margin-top: var(--margin-lg);
}

.idt-video-dropdown {
  border: 1px solid var(--grey-light);
  border-radius: var(--radius-lg);
}


.idt-video-dropdown .idt-video-header {
  padding: var(--padding-md);
  display: flex;
  align-items: center;
  gap: var(--padding-sm);
  cursor: pointer;
}

.idt-video-dropdown .idt-video-header:hover {
  background-color: var(--white-base);
}

.idt-video-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-top: 1px solid var(--grey-light);
  background-color: var(--white-base);
  padding: var(--padding-md);
}

.idt-downloadable-document .content {
  padding: var(--padding-md) var(--padding-lg);
  border: 1px solid var(--grey-light);
  border-radius: var(--radius-lg);
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 1;
}

.idt-downloadable-document .download-indicator {
  display: flex;
  justify-content: flex-end;
  width: 40px;
}

.idt-downloadable-document .download-indicator .fa {
  display: none;
}

.idt-downloadable-document:hover .download-indicator .fa {
  display: block;
}

@media (max-width: 992px) {
  .idt-videos-list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 668px) {
  .idt-videos-list {
    grid-template-columns: 1fr;
  }
}
