:root {
  --min-editor-height: 240px;
  --editor-padding: 10px;
  --toolbar-action-active-color: var(--primary-highlight-color);
  --toolbar-action-hover-color: var(--white-dark);
  --toolbar-dropdown-hover-color: var(--grey-light);
  --toolbar-action-height: 25px;
  --toolbar-action-width: 30px;
}

.editor {
  font-size: var(--input-font-size);
  font-weight: var(--input-font-weight);
  font-family: var(--primary-font-family);
  line-height: var(--input-line-height);
  border: var(--input-border-width) var(--input-border-style) var(--input-border-color);
  border-radius: var(--input-border-radius);
  background-color: var(--input-background-color);
  color: var(--input-text-color);
  position: relative;
  margin-bottom: var(--margin-sm);
  margin-top: var(--margin-md);
}

.editor.focused {
  box-shadow: var(--input-box-shadow);
  border-color: transparent;
}

.editor .editor-container {
  position: relative;
  min-height: var(--min-editor-height);
  border: 0;
  overflow: auto;
  resize: vertical;
}

.editor .editor-container .editor-root {
  height: 100%;
  display: block;
  min-height: var(--min-editor-height);
  outline: none;
  cursor: text;
  padding: var(--editor-padding);
  overflow-y: scroll;
  max-height: calc(100vh - var(--navigation-bar-height) - 50px);
}

.editor .editor-container .editor-placeholder {
  position: absolute;
  top: var(--editor-padding);
  left: var(--editor-padding);
  pointer-events: none;
}

.editor .toolbar {
  display: flex;
  border-top-left-radius: var(--input-border-radius);
  border-top-right-radius: var(--input-border-radius);
  border-bottom: 1px solid var(--grey-light);
  padding: var(--padding-sm);
  position: sticky;
  top: var(--navigation-bar-height);
  background-color: #fff;
  z-index: 2;
}

.editor .toolbar .toolbar-actions {
  display: flex;
  flex: 1;
  align-items: center;
  flex-wrap: wrap;
}

.editor .toolbar .toolbar-action {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: normal;
  line-height: 1.5;
  max-width: 100%;
  text-align: center;
  white-space: nowrap;
  cursor: pointer;
  vertical-align: middle;
  border-width: 0px;
  text-decoration: none;
  border-radius: var(--standard-border-radius);
  transition: background 0.1s ease-out 0s,
    opacity 0.1s ease-out 0s;
  outline: none;
  opacity: 1;
  font-size: var(--semi-small-font-size);
  background: transparent;
  color: var(--black-color);
  width: var(--toolbar-action-width);
  height: var(--toolbar-action-height);
  margin-left: 0px;
}

.editor .toolbar .toolbar-action:disabled {
  opacity: 0.2;
  cursor: not-allowed;
}

.editor .toolbar .toolbar-action.toolbar-dropdown {
  justify-content: space-between;
  padding: var(--padding-sm) var(--padding-md);
}

.editor .toolbar .toolbar-action.toolbar-dropdown:not(.selected) {
  background: var(--toolbar-action-hover-color);
}

.editor .toolbar .toolbar-action.toolbar-dropdown:hover:not(.selected):not(:disabled) {
  background: var(--toolbar-dropdown-hover-color);
}

.editor .toolbar .toolbar-action:not(:last-of-type) {
  margin-right: 3px;
}

.editor .toolbar .toolbar-action:hover:not(.selected):not(:disabled) {
  background: var(--toolbar-action-hover-color);
  box-shadow: none;
}

.editor .toolbar .toolbar-action:active:not(.selected):not(:disabled),
.editor .toolbar .toolbar-action.toolbar-dropdown:active:not(.selected):not(:disabled) {
  background: var(--toolbar-action-active-color);
  color: var(--primary-color);
  box-shadow: none;
}

.editor .toolbar .toolbar-action.selected {
  background: var(--black-color);
  color: #fff;
}

.editor .toolbar .toolbar-action.toolbar-dropdown.font-style {
  min-width: 110px;
}

.editor .toolbar .divider {
  margin-right: var(--margin-sm);
  margin-left: 2px;
  height: 25px;
  width: 1px;
  background-color: var(--grey-light);
}

.dropdown.editor-font-style {
  line-height: 1;
  z-index: 1000 !important;
}

.dropdown.editor-font-style .menu-item {
  margin: 0;
}

.dropdown.editor-font-style .menu .menu-item.selected {
  color: var(--primary-color);
  background-color: var(--white-dark);
}

.editor .toolbar .shortcut-info {
  width: 280px;
}

.editor .toolbar .shortcut-info h3 {
  border-bottom: 1px solid var(--white-dark);
  padding: var(--padding-sm) var(--padding-md);
  margin: 0;
}

.editor .toolbar .shortcut-info .shortcut-items {
  max-height: var(--min-editor-height);
  overflow-y: scroll;
  padding: var(--padding-md);
}

.editor .toolbar .shortcut-info .shortcut-item {
  padding-top: var(--padding-sm);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.editor .toolbar .shortcut-info .shortcut-item:not(:last-of-type) {
  border-bottom: 1px solid var(--white-dark);
  padding-bottom: var(--padding-md);
}

.editor .toolbar .shortcut-info .shortcut-item kbd {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: var(--small-font-size);
  font-weight: 700;
  line-height: 1.55;
  padding: 3px var(--padding-sm);
  color: var(--black-color);
  margin-right: var(--margin-sm);
  background-color: #f2f2f3;
  border-width: 1px 1px 3px;
  border-style: solid;
  border-color: var(--grey-color);
  box-shadow: none;
}

.form-group > label + .editor {
  margin-top: 0;
}

/* Theme Styles */
.editor-theme__text-strikethrough {
  text-decoration: line-through;
}

.editor-theme__text-italic {
  font-style: italic;
}

.editor-theme__text-bold {
  font-weight: 700;
}

.editor-theme__list-listItem,
.markdown li {
  margin: 0 var(--margin-xl);
}

.editor-theme__list-listItem::marker,
.markdown li::marker {
  font-weight: 600;
}

.editor-theme__list-nested-listItem {
  list-style: none;
}

.editor-theme__list-ol1,
.editor-theme__list-ol2,
.editor-theme__list-ol3,
.editor-theme__list-ol4,
.editor-theme__list-ol5,
.markdown ol,
.markdown ul {
  padding: 0;
  margin: 0;
  list-style-position: inside;
}

.editor-theme-list-ol2,
.markdown ol ol {
  list-style-type: upper-alpha;
}

.editor-theme-list-ol3,
.markdown ol ol ol {
  list-style-type: lower-alpha;
}

.editor-theme-list-ol4,
.markdown ol ol ol ol {
  list-style-type: upper-roman;
}

.editor-theme-list-ol5,
.markdown ol ol ol ol ol {
  list-style-type: lower-roman;
}

.editor-theme__list-ul {
  padding: 0;
  margin: 0,;
  list-style-position: inside;
}
