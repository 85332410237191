:root {
  --dropdown-menu-border-radius: 3px;
  --dropdown-box-shadow: var(--shadow-md);
  --dropdown-min-width: 120px;
  --dropdown-icon-width: 30px;
}

.dropdown {
  text-align: left;
}

.dropdown ul.menu {
  padding-top: var(--padding-sm);
  padding-bottom: var(--padding-sm);
  border-radius: var(--dropdown-menu-border-radius);
  border: 1px solid var(--white-dark);
  background-color: var(--foreground-color);
  min-width: var(--dropdown-min-width);
  list-style: none;
  padding-left: 0;
  margin: 0;
  box-shadow: var(--dropdown-box-shadow);
}

.dropdown .menu .menu-item {
  cursor: pointer;
  outline: none;
}

.dropdown .menu .menu-item .item {
  padding: var(--padding-md);
}

.dropdown .menu .menu-item a {
  display: block;
  width: 100%;
  text-decoration: none;
}

.dropdown .menu .menu-item:hover {
  background-color: var(--white-dark);
}

.dropdown .menu .menu-item:active,
.dropdown .menu .menu-item:active a,
.dropdown .menu .menu-item.selected {
  background-color: var(--grey-light);
}

.dropdown .menu .menu-item.primary:hover {
  background-color: var(--primary-highlight-color);
}

.dropdown .menu .menu-item.primary:active,
.dropdown .menu .menu-item.primary:active a,
.dropdown .menu .menu-item.primary.selected {
  background-color: var(--primary-color);
  color: #fff;
}

.dropdown .menu .menu-item.danger {
  color: var(--error-color);
}

.dropdown .menu .menu-item.danger:hover {
  background-color: var(--error-highlight-color);
}

.dropdown .menu .menu-item.danger:active,
.dropdown .menu .menu-item.danger:active a,
.dropdown .menu .menu-item.danger.selected {
  background-color: var(--error-color);
  color: #fff;
}

.dropdown .menu .menu-item.warning {
  color: var(--warning-color);
}

.dropdown .menu .menu-item.warning:hover {
  background-color: var(--warning-highlight-color);
}

.dropdown .menu .menu-item.warning:active,
.dropdown .menu .menu-item.warning:active a,
.dropdown .menu .menu-item.warning.selected {
  background-color: var(--warning-color);
  color: #fff;
}

.dropdown .menu .menu-item .fa:first-child {
  width: var(--dropdown-icon-width);
}