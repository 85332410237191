:root {
  --clinic-select-list-scrollbar-width: 10px;
  --clinic-select-list-overflow-height: 150px;
}

ul.clinic-select-list {
  max-height: var(--clinic-select-list-overflow-height);
  overflow-y: auto;
}

ul.clinic-select-list::-webkit-scrollbar-track {
  background-color: var(--mask-color);
}

ul.clinic-select-list::-webkit-scrollbar {
  width: var(--clinic-select-list-scrollbar-width);
  background-color: var(--grey-color);
}

ul.clinic-select-list::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
}

.clinic-select label>span,
.clinic-select .form-input-message {
  display: none;
}

.clinic-select select {
  @extend form .form-control;
}

ul.clinic-select-list>li {
  display: flex;
  align-items: flex-start;
}

ul.clinic-select-list>li>.fa {
  margin-right: var(--margin-sm);
  font-size: 18px;
  margin-top: 2px;
  color: var(--primary-color);
}

ul.clinic-select-list>li>span {
  flex: 1;
}

.active-clinic-select>.Select-menu-outer {
  z-index: 100;
}