.progress-dots {
  display: flex;
  justify-content: center;
  margin-top: var(--margin-lg);
  margin-bottom: var(--margin-lg);
}

.progress-dots .dot {
  height: 20px;
  width: 20px;
  margin-left: 5px;
  margin-right: 5px;
  border-width: 1px;
  border-radius: 50%;
  border-style: solid;
  border-color: var(--primary-color);
}

.progress-dots .dot.highlighted {
  background-color: var(--primary-color);
}
