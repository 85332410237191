.ovs-test .ovs-test-header {
  margin-top: var(--margin-lg);
  margin-bottom: var(--margin-lg);
} 

.ovs-test .ovs-test-header h1,
.ovs-test .ovs-test-header h2,
.ovs-test .ovs-test-header h6 {
  margin: 0;
}

.ovs-input-group > label {
  margin-bottom: 0;
  line-height: 1;
}

.ovs-input-group.form-group {
  margin-bottom: 8px;
}

.ovs-test .ovs-test-result {
  font-size: 18px;
  line-height: 1.3;
  margin-top: var(--margin-md);
  margin-bottom: var(--margin-lg);
}

.ovs-test .ovs-test-description {
  border-radius: var(--radius-lg);
  background-color: var(--white-dark);
  padding: var(--padding-lg);
  margin-bottom: var(--margin-lg);
}

.ovs-test .ovs-test-description .title {
  color: var(--primary-color);
  font-size: 18px;
  font-weight: var(--semi-bold-font-weight);
  text-decoration: underline;
  margin-bottom: var(--margin-sm);
}

.ovs-form {
  padding-top: var(--margin-lg);
  padding-bottom: calc(var(--margin-lg) * 2);
}

.ovs-test-steps {
  border-left: 2px solid var(--white-dark);
  margin-left: var(--margin-md);
}

.ovs-test-steps .ovs-test-step {
  padding-top: calc(var(--margin-lg) * 2);
  padding-bottom: var(--margin-md);
  padding-left: calc(var(--margin-lg) * 2);
  padding-right: 0;
}

.ovs-test-steps .ovs-test-step:last-child {
  padding-bottom: calc(var(--margin-lg) * 2);
}

.ovs-test-steps .ovs-test-step .ovs-test-step-header {
  position: relative;
  display: flex;
  align-items: center;
}

.ovs-test-steps .ovs-test-step .ovs-test-step-header .ovs-test-step-index {
  position: absolute;
  left: -45px;
  height: 30px;
  width: 30px;
  font-weight: var(--semi-bold-font-weight);
  font-size: 18px;
  background-color: var(--grey-light);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ovs-test-steps .ovs-test-step .ovs-test-step-header .ovs-test-step-index.complete {
  background-color: var(--success-color);
  color: var(--white-light);
}

.ovs-test-steps .ovs-test-step .ovs-test-step-header .ovs-test-step-title {
  font-weight: var(--semi-bold-font-weight);
  font-size: 20px;
}

.ovs-test-steps .ovs-test-step .ovs-test-step-header .ovs-test-step-title > .step {
  color: var(--primary-color);
}

.ovs-test-steps .ovs-test-step .ovs-test-step-section {
  border: 1px solid var(--grey-light);
  border-radius: var(--radius-lg);
  padding: var(--padding-lg);
  margin-top: var(--margin-lg);
}

.ovs-test-result .ovs-calculations .edit-button {
  font-weight: var(--normal-font-weight);
}

.ovs-test-result .ovs-calculations button.edit-button .fa {
  font-size: 8px;
}

.ovs-test-result .ovs-calculations .ovs-results-description {
  padding: var(--padding-md);
  background-color: var(--white-dark);
  border-radius: var(--radius-lg);
  font-size: var(--semi-small-font-size);
  margin-bottom: var(--margin-xl);
}

.ovs-test-result .ovs-calculations .ovs-results-description ul {
  margin: 0;
  padding: 0;
  margin-left: var(--margin-lg);
}

.ovs-test-result .ovs-calculations .ovs-results-description > div > ul > li {
  padding-bottom: var(--padding-md);
}

.ovs-test-result .ovs-calculations .ovs-results-description strong {
  font-weight: var(--semi-bold-font-weight);
}

.ovs-test-result .ovs-calculations .ovs-results-description.normal .normal,
.ovs-test-result .ovs-calculations .ovs-results-description.orthostatic_tachycardia .orthostatic_tachycardia,
.ovs-test-result .ovs-calculations .ovs-results-description.borderline_orthostatic_tachycardia .borderline_orthostatic_tachycardia,
.ovs-test-result .ovs-calculations .ovs-results-description.orthostatic_hypotension .orthostatic_hypotension,
.ovs-test-result .ovs-calculations .ovs-results-description.hypovolemia .hypovolemia {
  display: block;
  background-color: #fff7da;
  border-radius: var(--radius-lg);
  padding: var(--padding-md);
}
