.tax-table {
  margin-top: var(--margin-lg);
  margin-bottom: var(--margin-lg);
}

table.tax-table>tbody>tr.tax-country {
  background-color: var(--primary-color);
  color: #fff;
}

table.tax-table>tbody>tr>td.tax-actions {
  display: flex;
  justify-content: flex-end;

}

table.tax-table>tbody>tr>td.tax-actions>button.edit-button {
  font-size: 14px;
}

table.tax-table>tbody>tr>td.tax-actions>button.edit-button:focus {
  outline: none;
}

.tax-table tr.tax-country h3 {
  margin-top: 0;
  margin-bottom: 0;
  color: #fff;
}

.tax-drawer-container {
  width: 550px;
  padding: calc(var(--padding-md) * 2);
  background-color: #fff;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.tax-rates .add-tax-rate {
  display: flex;
  justify-content: flex-end;
}