:root {

  --navigation-bar-height: 60px;

  --navigation-bar-border-width: 1px;
  --navigation-bar-border-color: var(--grey-light);
  --navigation-bar-active-border-width: 6px;
  --navigation-bar-border-style: solid;
  --navigation-bar-muted-border-color: var(--grey-light);
  --navigation-box-shadow: var(--shadow-sm);

  --navigation-brand-padding: 10px 10px 10px 0;
  --navigation-brand-img-max-height: 40px;

  --navigation-bar-item-padding: 5px 10px;
  --navigation-user-avatar-margin: 10px;
  --navigation-user-icon-margin: 10px;
  --navigation-user-icon-font-size: 24px;

  --navigation-languages-header-icon-font-size: 46px;
  --navigation-languages-icon-font-size: 18px;

  --navigation-bar-content-color: var(--grey-dark);

  --navigation-user-metadata-font-size: 12px;
  --navigation-user-metadata-line-height: 1.2;

  --navigation-background-color: var(--foreground-color);

  --navigation-notification-badge-inset: calc(var(--navigation-user-avatar-margin) / 2);
  --navigation-notification-badge-size: 22px;
  --navigation-notification-badge-border-width: 1px;
  --navigation-notification-badge-border-radius: 50%;
  --navigation-notification-badge-border-style: solid;
  --navigation-notification-badge-border-color: #FFF;
  --navigation-notification-badge-font-size: 10px;
  --navigation-notification-badge-line-height: 1;
  --navigation-notification-badge-icon-color: #FFF;
  --navigation-notification-badge-background-color: var(--error-color);

}

.page {
  padding-top: var(--padding-lg);
}

.page.nav-visible {
  padding-top: var(--navigation-bar-height);
}

nav.navigation-bar {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: var(--navigation-bar-height);
  background-color: var(--navigation-background-color);
  display: flex;
  color: var(--navigation-bar-content-color);
  border-bottom: 1px solid var(--navigation-bar-border-color);
}

nav.navigation-bar>.container {
  max-width: 100%;
  position: relative;
  display: flex;
}

nav.navigation-bar .navigation-header {
  display: flex;
  width: auto;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  min-height: var(--navigation-bar-height);
}

nav.navigation-bar .navigation-header .navigation-content-left {
  display: flex;
  align-items: center;
  height: 100%;
}

nav.navigation-bar .navigation-header .navigation-content-left .dashboard-dropdown-toggle {
  outline: none;
  background-color: transparent;
  border: none;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  font-size: 18px;
  line-height: 1;
  margin-left: 5px;
}

nav.navigation-bar .navigation-header .navigation-content-left .dashboard-dropdown-toggle.open {
  background-color: var(--primary-color);
  color: #fff;
}

nav.navigation-bar .navigation-header .navigation-content-left .dashboard-dropdown-toggle:not(.open):hover {
  background-color: var(--white-color);
  color: var(--primary-color);
}

nav.navigation-bar .navigation-header .navigation-content-left .dashboard-dropdown-toggle:not(.open):active {
  background-color: var(--primary-highlight-color);
}

nav.navigation-bar .navigation-brand {
  padding: var(--navigation-brand-padding);
}

nav.navigation-bar .navigation-brand img {
  display: block;
  max-height: var(--navigation-brand-img-max-height);
}

nav.navigation-bar button.navigation-bar-toggle {
  display: none;
  font-size: 22px;
  line-height: 1.1;
  background-color: transparent;
  color: var(--primary-color);
  padding: 15px 15px;
  outline: none;
  border: none;
}

/**********************/
/* Navigation Content */
/**********************/

nav.navigation-bar .navigation-content {
  display: flex;
  flex-direction: row;
  width: 100%;
}

nav.navigation-bar .navigation-content>ul.navbar-items {
  display: flex;
  list-style: none;
  margin-right: auto;
  flex-direction: row;
  margin-bottom: 0;
  padding-bottom: 0;
  height: 100%;
}

nav.navigation-bar .navigation-content>ul>li {
  display: flex;
  align-items: stretch;
  height: 100%;
}

nav.navigation-bar .navigation-content>ul>li:hover {
  background-color: var(--white-color);
}

nav.navigation-bar .navigation-content>ul>li>a,
nav.navigation-bar .navigation-content>ul>li>button,
.dropdown.impact-dropdown>.dropdown-toggle {
  display: flex;
  align-items: center;
  padding: var(--navigation-bar-item-padding);
  color: var(--navigation-bar-content-color);
  font-weight: 600;
  font-size: var(--semi-small-font-size);
  text-transform: uppercase;
  font-family: var(--heading-font-family);
  text-decoration: none;
  width: 100%;
}

nav.navigation-bar .navigation-content>ul>li:hover>a,
nav.navigation-bar .navigation-content>ul>li:hover>button {
  color: var(--primary-color);
}

nav.navigation-bar .navigation-content>ul>li>button {
  background-color: transparent;
  border-radius: 0;
  border: none;
}

.dropdown.impact-dropdown>.dropdown-toggle {
  min-width: unset;
  cursor: pointer;
}

.dropdown.impact-dropdown>.dropdown-toggle>.caret {
  margin-left: var(--margin-sm);
}

nav.navigation-bar .navigation-content>ul.navbar-items.right {
  margin-right: 0;
  margin-left: auto;
}

/***********************/
/* Navigation Dropdown */
/***********************/

nav.navigation-bar .navigation-content>ul>li.navigation-dropdown {
  overflow-y: visible;
  display: block;
  width: 250px;
}

nav.navigation-bar .navigation-content>ul>li.navigation-dropdown>ul {
  position: relative;
  width: 100%;
  z-index: 1;
  margin: 0;
  padding: 0;
  display: none;
  list-style: none;
  border-width: 0 var(--navigation-bar-border-width);
  border-style: var(--navigation-bar-border-style);
  border-color: var(--white-dark);
  background-color: var(--navigation-background-color);
  overflow: hidden;
}

nav.navigation-bar .navigation-content>ul>li.navigation-dropdown>ul>li:not(.navigation-dropdown-clinics):hover {
  background-color: var(--white-color);
}

nav.navigation-bar .navigation-content>ul>li.navigation-dropdown>ul>li:not(:last-child) {
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: var(--navigation-bar-muted-border-color);
}

nav.navigation-bar .navigation-content>ul>li.navigation-dropdown.expanded>ul {
  display: block;
  border-bottom-width: var(--navigation-bar-border-width);
  box-shadow: var(--shadow-sm);
}

nav.navigation-bar .navigation-content>ul>li.navigation-dropdown>ul>li:not(.navigation-dropdown-clinics) {
  color: var(--navigation-bar-content-color);
  border-left-width: var(--navigation-bar-active-border-width);
  border-left-style: solid;
  border-left-color: transparent;
  overflow: hidden;
  white-space: nowrap;
}

nav.navigation-bar .navigation-content>ul>li.navigation-dropdown>ul>li a {
  color: inherit;
}

nav.navigation-bar .navigation-content>ul>li.navigation-dropdown>ul>li a.red {
  color: var(--error-color);
}

nav.navigation-bar .navigation-content>ul>li.navigation-dropdown>ul>li:empty {
  display: none;
}

nav.navigation-bar .navigation-content>ul>li.navigation-dropdown>ul>li.active {
  border-left-color: var(--primary-color);
}

nav.navigation-bar .navigation-content>ul>li.navigation-dropdown>ul>li>a,
nav.navigation-bar .navigation-content>ul>li.navigation-dropdown>ul>li>button {
  display: flex;
  align-items: center;
  padding: 5px 10px 5px 5px;
  text-decoration: none;
}

nav.navigation-bar .navigation-content>ul>li.navigation-dropdown>ul>li>button {
  margin: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: none;
  border-radius: 0;
}

nav.navigation-bar .navigation-content>ul>li.navigation-dropdown>ul>li>a>.fa,
nav.navigation-bar .navigation-content>ul>li.navigation-dropdown>ul>li>button>.fa {
  margin-right: 5px;
}

nav.navigation-bar .navigation-content>ul>li.navigation-dropdown>ul>li .notification-link {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
}

nav.navigation-bar .navigation-content>ul>li.navigation-dropdown>ul>li .notification-link>.badge {
  background-color: var(--error-color);
}

/******************************/
/* Navigation Dropdown Header */
/******************************/

nav.navigation-bar .navigation-dropdown .navigation-dropdown-header {
  height: var(--navigation-bar-height);
  padding: 0;
  width: 100%;
  cursor: pointer;
  border-bottom: 1px solid var(--navigation-bar-border-color);
}

nav.navigation-bar .navigation-dropdown.expanded>.navigation-dropdown-header {
  border-bottom-color: var(--navigation-bar-muted-border-color);
}

nav.navigation-bar .navigation-dropdown>.navigation-dropdown-header * {
  pointer-events: none;
}

nav.navigation-bar .navigation-dropdown>.navigation-dropdown-header>.navigation-dropdown-header-inner {
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0 calc(var(--navigation-bar-border-width) * -1);
  background-color: var(--navigation-background-color);
}

nav.navigation-bar .navigation-dropdown>.navigation-dropdown-header .user-avatar {
  position: relative;
  width: calc(var(--navigation-bar-height) - var(--navigation-bar-border-width));
  height: calc(var(--navigation-bar-height) - var(--navigation-bar-border-width));
}

nav.navigation-bar .navigation-dropdown>.navigation-dropdown-header .user-avatar.has-notifications {
  pointer-events: auto;
}

nav.navigation-bar .navigation-dropdown>.navigation-dropdown-header .user-avatar.has-notifications * {
  pointer-events: none;
}

nav.navigation-bar .navigation-dropdown>.navigation-dropdown-header .user-avatar>img {
  display: block;
  width: calc(100% - (var(--navigation-user-avatar-margin) * 2));
  height: calc(100% - (var(--navigation-user-avatar-margin) * 2));
  margin: var(--navigation-user-avatar-margin);
  border-radius: 50%;
}

nav.navigation-bar .navigation-dropdown>.navigation-dropdown-header .user-avatar .notification-badge {
  position: absolute;
  top: var(--navigation-notification-badge-inset);
  right: var(--navigation-notification-badge-inset);
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--navigation-notification-badge-size);
  height: var(--navigation-notification-badge-size);
  border-width: var(--navigation-notification-badge-border-width);
  border-radius: var(--navigation-notification-badge-border-radius);
  border-style: var(--navigation-notification-badge-border-style);
  border-color: var(--navigation-notification-badge-border-color);
  background-color: var(--navigation-notification-badge-background-color);
}

nav.navigation-bar .navigation-dropdown>.navigation-dropdown-header .user-avatar .notification-badge>span {
  margin: 0;
  font-size: var(--navigation-notification-badge-font-size);
  font-weight: var(--bold-font-weight);
  line-height: var(--navigation-notification-badge-line-height);
  color: var(--navigation-notification-badge-icon-color);
}

nav.navigation-bar .navigation-dropdown>.navigation-dropdown-header .user-metadata {
  flex: 1;
  font-size: var(--navigation-user-metadata-font-size);
  line-height: var(--navigation-user-metadata-line-height);
}

nav.navigation-bar .navigation-dropdown>.navigation-dropdown-header .user-metadata>.name {
  font-weight: bold;
}

nav.navigation-bar .navigation-dropdown>.navigation-dropdown-header .user-metadata>.organization {
  font-weight: 300;
}

nav.navigation-bar .navigation-dropdown>.navigation-dropdown-header .fa {
  font-size: var(--navigation-user-icon-font-size);
  margin: var(--navigation-user-icon-margin);
}

/*******************************/
/* Navigation Dropdown Clinics */
/*******************************/

nav.navigation-bar .navigation-dropdown>ul>li.navigation-dropdown-clinics {
  padding: 0;
}

nav.navigation-bar .navigation-dropdown>ul>li.navigation-dropdown-clinics:empty {
  display: none;
}

nav.navigation-bar .navigation-dropdown>ul>li.navigation-dropdown-clinics>ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

nav.navigation-bar .navigation-dropdown>ul>li.navigation-dropdown-clinics>ul>li {
  padding: var(--navigation-bar-item-padding);
  cursor: pointer;
}

nav.navigation-bar .navigation-dropdown>ul>li.navigation-dropdown-clinics>ul>li:hover {
  background-color: var(--white-dark);
}

.navigation-dropdown-clinics .clinic-select-list .fa {
  margin-right: 5px;
}

/*******************************/
/* Navigation Languages Header */
/*******************************/

nav.navigation-bar .navigation-dropdown>.navigation-dropdown-header .navigation-languages-header {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left-width: var(--navigation-bar-border-width);
  border-left-style: var(--navigation-bar-border-style);
  border-left-color: var(--white-dark);
  border-right-width: var(--navigation-bar-border-width);
  border-right-style: var(--navigation-bar-border-style);
  border-right-color: var(--white-dark);
}

nav.navigation-bar .navigation-dropdown>.navigation-dropdown-header>.navigation-languages-header .fa {
  font-size: 18px;
}

nav.navigation-bar .navigation-dropdown>.navigation-dropdown-header>.navigation-languages-header span {
  font-weight: var(--bold-font-weight);
}

/* DEMO SITE DRAWER */
.demo-site-logins-drawer .drawer-content {
  max-height: 100%;
  overflow-y: scroll;
} 

.demo-site-logins-drawer .demo-site-logins-drawer-content {
  background-color: var(--white-color);
  padding: var(--padding-lg);
  position: relative;
}

.demo-site-logins-drawer-content .demo-site-logins {
  display: flex;
  align-items: stretch;
  margin: 0 auto;
  flex-wrap: wrap;
  gap: var(--padding-lg);
  max-height: 100%;
}

.demo-site-logins-drawer-content button.close {
  position: absolute;
  top: 0;
  right: 0;
  margin: var(--margin-xl) var(--margin-xl) 0 0;
  font-size: var(--h2-font-size);
  border-radius: 50%;
  background-color: var(--grey-light);
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.demo-site-logins-drawer-content button.close:active {
  background-color: var(--blue-light);
  color: var(--blue-dark);
}

.demo-site-logins .demo-site-login-item {
  flex: 1;
  padding: var(--padding-lg);
  border: 1px solid var(--grey-light);
  border-radius: var(--radius-lg);
  align-self: stretch;
  height: 100%
}

.demo-site-logins .demo-site-login-item.highlight {
  border-color: var(--secondary-color);
}

.demo-site-login-item .role-heading {
  font-size: var(--h3-font-size);
  font-weight: var(--semi-bold-font-weight);
  margin-bottom: var(--margin-md);
}

@media screen and (max-width: 992px) {
  nav.navigation-bar.collapsable .navigation-content>ul>li.dropdown.impact-dropdown {
    display: block;
  }

  .dropdown.impact-dropdown>.dropdown-toggle {
    min-width: 100%;
  }

  .dropdown.impact-dropdown.open>ul.dropdown-menu {
    position: relative;
    width: 100%;
    border: none;
  }

  nav.navigation-bar.collapsable button.navigation-bar-toggle {
    display: block;
  }

  nav.navigation-bar button.navigation-bar-toggle * {
    pointer-events: none;
  }

  nav.navigation-bar.collapsable {
    height: auto;
  }

  nav.navigation-bar.collapsable>.container {
    background-color: var(--navigation-background-color);
    flex-direction: column;
  }

  nav.navigation-bar.collapsable .navigation-content {
    display: none;
    flex-direction: column;
    background-color: var(--navigation-background-color);
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
    max-height: 400px;
    overflow-y: auto;
  }

  nav.navigation-bar.collapsable .navigation-content.visible {
    display: block;
  }

  nav.navigation-bar.collapsable .navigation-content>ul.navbar-items {
    flex-direction: column;
    padding: 0;
    width: 100%;
    height: auto;
  }

  nav.navigation-bar.collapsable .navigation-content>ul>li.navigation-dropdown {
    width: 100%;
  }

  nav.navigation-bar.collapsable .navigation-dropdown .navigation-dropdown-header {
    border-bottom: none;
  }

  nav.navigation-bar.collapsable .navigation-content>ul>li.navigation-dropdown>ul {
    border: none;
  }
}

@media screen and (max-width: 798px) {
  nav.navigation-bar.collapsable>.container {
    width: 100%;
  }
}