.card.card-form.contact-card-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.card.card-form.contact-card-form .form-input-group,
.card.card-form.contact-card-form .form-footer {
  width: 100%;
  flex-basis: 100%;
}
