.cookie-banner-container {
  background-color: var(--black-dark);
  color: var(--white-base);
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  transition: transform 200ms ease-in-out,
              opacity 200ms linear;
  transform: translateY(100%);
  opacity: 0;
  z-index: 9999;
}

.cookie-banner-container.active {
  transform: translateY(0);
  opacity: 1;
}

.cookie-banner-container .cookie-banner {
  position: relative;
  padding: var(--padding-lg) calc(var(--padding-xl) * 3) var(--padding-lg) var(--padding-xl);
  max-width: 1480px;
  margin: 0 auto;
  display: flex;
  align-items: center;
}

.cookie-banner-container .cookie-banner .message {
  flex: 1;
  font-size: var(--semi-small-font-size);
  line-height: 1.7;
  font-weight: var(--normal-font-weight);
  margin-right: var(--margin-lg);
}

.cookie-banner-container .cookie-banner .message a {
  border-bottom: 1px solid rgba(33, 150, 241, 0.65);
  padding-bottom: 2px;
  text-decoration: none;
  margin-left: var(--margin-sm);
  color: inherit;
  white-space: nowrap;
}

.cookie-banner-container .cookie-banner .message a:hover {
  border-bottom: 1px solid rgba(33, 150, 241, 0.95);
}

.cookie-banner-container .cookie-banner .btn-close {
  color: var(--white-base);
  background-color: transparent;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  box-shadow: none;
  border: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--h3-font-size);
}

.cookie-banner-container .cookie-banner .btn-close:hover,
.cookie-banner-container .cookie-banner .btn-close:active {
  background-color: var(--primary-color);
}

@media (max-width: 992px) {
  .cookie-banner-container .cookie-banner {
    padding-right: var(--padding-xl);
  }
}