:root {
  --flex-basis-max-width: 100%;
  --relative-max-width: 100%;
  --relative-max-height: 100%;
  --timer-font-size: 3em;
  --answer-key-row-font-size: 18px;
  --kd-disable-overlay-color: rgba(255, 255, 255, 0.75);
  --kd-content-left-min-width: 150px;
}

@custom-media --kd-small-viewport (max-width: 400px);

.kd-card-group {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: var(--padding-lg);
}

.kd-card {
  display: flex;
  flex-basis: var(--flex-basis-max-width);
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: var(--margin-lg);
  position: relative;
}

.kd-card-top {
  flex: 1;
  flex-basis: var(--flex-basis-max-width);
  display: flex;
  justify-content: flex-end;
}

.kd-card-top .alert {
  flex: 1;
  margin-bottom: 0;
  margin-top: var(--margin-lg);
}

.kd-card-title {
  flex: 1;
  flex-basis: var(--flex-basis-max-width);
  padding-left: var(--padding-lg);
}

.kd-card-title>label {
  text-transform: uppercase;
  color: var(--primary-color);
  padding: var(--padding-sm);
}

.kd-card-content {
  display: flex;
  flex: 1;
  height: auto;
  align-items: center;
  flex-wrap: wrap-reverse;
  border-bottom: 3px var(--standard-border-style) var(--primary-color);
  flex-basis: var(--flex-basis-max-width);
  margin-top: var(--margin-sm);
}

.kd-content-left {
  flex: 2;
  flex-basis: var(--kd-content-left-min-width);
  border-right: var(--standard-border-width) var(--standard-border-style) var(--standard-border-color);
  display: flex;
  justify-content: center;
  align-items: space-around;
  flex-wrap: wrap;
  margin-top: var(--margin-lg);
  margin-bottom: var(--margin-lg);
}

.kd-key {
  padding-top: var(--padding-lg);
  padding-bottom: var(--padding-lg);
}

.kd-key>* {
  font-size: var(--answer-key-row-font-size);
}

.kd-content-right {
  flex: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-left: var(--margin-lg);
}

.stop-watch-display,
.stop-watch-button-group,
.stop-watch-control {
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  flex-basis: var(--flex-basis-max-width);
  align-items: center;
}

.stop-watch-display {
  justify-content: space-around;
}

.stop-watch-button-group {
  justify-content: center;
}

.stop-watch-display>p {
  font-size: var(--timer-font-size);
}

.kd-error-input {
  margin: var(--margin-lg);
}

.kd-card .disable-overlay {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: var(--relative-max-width);
  height: var(--relative-max-height);
  background: var(--kd-disable-overlay-color);
}

@media (--kd-small-viewport) {
  .kd-content-left {
    border: 0;
    margin-top: 0;
  }
}