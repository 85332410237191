
.injury-insights .tabs .tabs-bar {
  border-bottom-color: var(--grey-light);
  border-bottom-width: 1px;
}

.injury-insights .tabs .tabs-bar button.tab-button {
  border-bottom-width: 2px;
}

.injury-insights .tabs .tabs-bar button.tab-button.selected {
  border-bottom-color: var(--secondary-color);
}

.injury-insights .tabs .tabs-bar .tab-heading h1 {
  margin-top: var(--margin-sm);
  margin-bottom: var(--margin-sm);
  margin-right: calc(var(--margin-xl) * 2);
}


.injury-insights .injury-insights-header {
  border-bottom: 1px solid var(--grey-light);
  display: flex;
  align-items: center;
  position: sticky;
  top: 60px;
  background-color: var(--background-color);
  z-index: 100;
  margin-bottom: var(--margin-xl);
  padding-top: var(--padding-lg);
}

.injury-insights .injury-insights-header h1 {
  margin-top: var(--margin-sm);
  margin-bottom: var(--margin-sm);
  margin-right: calc(var(--margin-xl) * 2);
}

.injury-insights .injury-insights-header .scroll-spy-item {
  padding: var(--padding-lg) var(--padding-xl);
  text-decoration: none;
  color: inherit;
  border-bottom: 3px solid transparent;
}

.injury-insights .injury-insights-header .scroll-spy-item:hover {
  cursor: pointer;
}

.injury-insights .injury-insights-header .scroll-spy-item.active {
  border-bottom-color: var(--secondary-color);
  font-weight: bolder;
}

.injury-insights #overview {
  padding-bottom: var(--padding-xl);
}

.injury-insights #symptoms > h2 {
  border-bottom: 1px solid var(--grey-light);
  color: var(--blue-dark);
  padding-bottom: var(--padding-md);
}

/* Insight Stats */
.insights-stat-cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: var(--padding-md);
  row-gap: var(--padding-md);
  margin-bottom: var(--margin-xl);
}

.insights-stat-cards .insight-stat-card {
  background-color: var(--foreground-color);
  border-radius: var(--radius-xl);
  border: 1px solid var(--white-dark);
  padding: var(--padding-lg);
  display: flex;
  height: 100%;
  flex: 1;
}

.insights-stat-cards .insight-stat-card .stat-icon {
  align-self: center;
  margin-right: var(--margin-xl);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  color: var(--grey-base);
}

.insights-stat-cards .insight-stat-card.primary .stat-icon {
  color: var(--primary-color);
}

.insights-stat-cards .insight-stat-card.secondary .stat-icon {
  color: var(--secondary-color);
}

.insights-stat-cards .insight-stat-card.tertiary .stat-icon {
  color: var(--tertiary-color);
}

.insights-stat-cards .insight-stat-card.danger .stat-icon {
  color: var(--error-color);
}

.insights-stat-cards .insight-stat-card.warning .stat-icon {
  color: var(--warning-color);
}

.insights-stat-cards .insight-stat-card .stat-details {
  flex: 1;
}

.insights-stat-cards .insight-stat-card .stat-details .stat-title {
  font-family: var(--heading-font-family);
  font-size: var(--semi-small-font-size);
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--black-light);
}

.insights-stat-cards .insight-stat-card .stat-details .stat-value {
  font-weight: bold;
  font-size: 38px;
  color: var(--black-color);
}

.insights-stat-cards .insight-stat-card .stat-details .stat-value .stat-unit {
  color: var(--grey-dark);
  font-weight: 300;
  text-transform: uppercase;
  font-size: var(--semi-small-font-size);
}

.insights-stat-cards .insight-stat-card .stat-details .sub-text {
  color: var(--grey-base);
  font-size: var(--small-font-size);
}

.insights-stat-cards .insight-stat-card .stat-details .recovery-stages-view {
  flex-direction: column;
  align-items: center;
}

@media (max-width: 992px) {
  .insights-stat-cards {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 550px) {
  .insights-stat-cards {
    grid-template-columns: 1fr;
  }
}

/* Insight Summary Cards */
.insights-summary-cards {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: var(--padding-md);
  row-gap: var(--padding-md);
  margin-bottom: var(--margin-xl);
}

.insights-summary-cards .summary-card {
  background-color: var(--foreground-color);
  border-radius: var(--radius-xl);
  border: 1px solid var(--white-dark);
  padding: var(--padding-lg);
  height: 100%;
}

.insights-summary-cards .summary-card .summary-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: var(--margin-md);
}

.insights-summary-cards .summary-card .summary-header button.view-history {
  border: none;
  background-color: transparent;
  color: var(--primary-color);
  padding: var(--padding-sm) var(--padding-md);
  border-radius: 999em;
  line-height: 1;
  font-size: var(--semi-small-font-size);
}

.insights-summary-cards .summary-card .summary-header button.view-history:hover {
  color: var(--blue-dark);
}

.insights-summary-cards .summary-card .summary-header button.view-history:active {
  background-color: var(--white-dark);
}

.insights-summary-cards .summary-card .summary-header .summary-title {
  font-family: var(--heading-font-family);
  font-size: var(--semi-small-font-size);
  letter-spacing: 1px;
  text-transform: uppercase;
  color: var(--black-light);
}

@media (max-width: 992px) {
  .insights-summary-cards {
    grid-template-columns: 1fr;
  }
}

/* History Modal */
.modal.history-modal .dialog {
  min-width: 80%;
}

.modal.history-modal .dialog .history-list .history-item {
  margin-bottom: var(--margin-xl);
}

.modal.history-modal .dialog .history-list .history-item:not(:last-child) {
  border-bottom: 1px solid var(--grey-light);
}

.modal.history-modal .dialog .history-list .history-item .history-item-header {
  margin-bottom: var(--margin-sm);
  display: flex;
  align-items: center;
}

.modal.history-modal .dialog .history-list .history-item .history-item-header .item-title {
  font-weight: bold;
  font-size: var(--semi-small-font-size);
  text-decoration: underline;
  color: var(--black-light);
  margin-right: var(--margin-md);
}

.modal.history-modal .dialog .history-list .history-item .history-item-card {
  padding: var(--padding-md);
}

/* Symptoms Insights */

.symptoms-insights .symptom-categories-container {
  border-radius: var(--radius-xl);
  border: 1px solid var(--white-dark);
  padding: var(--padding-lg);
  background-color: var(--foreground-color);
  margin-bottom: var(--margin-xl);
  min-width: 100%;
  overflow-y: scroll;
}

.symptoms-insights .symptom-categories-container .categories-header {
  margin-bottom: var(--margin-xl);
}

.symptoms-insights .symptom-categories-container .categories-header .popover-content {
  padding: var(--padding-md);
  max-width: 300px;
}

.symptoms-insights .symptom-categories-container .categories-header button.info-toggle {
  border: none;
  background-color: transparent;
  font-size: 18px;
  margin: 0;
  padding: 0;
  margin-left: var(--margin-sm);
  box-shadow: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.symptoms-insights .symptom-categories-container .categories-header button.info-toggle:hover {
  color: var(--blue-dark);
}

.symptoms-insights .symptom-categories-container .categories-header h2 {
  margin-top: 0;
  margin-bottom: 0;
}

.symptoms-insights .symptom-categories-container .categories-header .btn-light {
  border: 1px solid transparent;
  margin-bottom: 0;
  box-shadow: none;
}

.symptoms-insights .symptom-categories-container .categories-header .btn-light:active {
  background-color: var(--primary-highlight-color);
  color: var(--blue-dark);
}

.symptoms-insights .symptom-categories-container .categories-header .btn-light .fa {
  margin-left: var(--margin-sm);
}

.symptoms-insights .symptom-categories-container .categories-header .btn-light.selected {
  background-color: var(--black-color);
  color: var(--white-color);
}

.symptoms-insights .symptom-categories-container .categories-header .btn-light:not(.selected):hover {
  border-color: var(--white-dark);
}

.symptoms-insights .symptom-categories-container .symptom-categories {
  display: flex;
  height: 100%;
}

.symptoms-insights .symptom-categories-container .symptom-categories .symptom-category-description {
  flex: 1;
  position: relative;
  display: flex;
  flex-direction: column;
}

.symptoms-insights .symptom-categories-container .symptom-categories .symptom-category-description .title {
  font-size: 20px;
  font-weight: bolder;
  text-align: center;
  padding: 0 var(--padding-md) var(--padding-md) var(--padding-md);
  background-color: var(--foreground-color);
}

.symptoms-insights .symptom-categories-container .symptom-categories .symptom-category-description .content-container {
  flex: 1;
  overflow: hidden;
  position: relative;
}

.symptoms-insights .symptom-categories-container .symptom-categories .symptom-category-description .content-container .content {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow-y: scroll;
  padding: var(--padding-md) var(--padding-lg);
  margin-top: 2px;
  z-index: 0;
  background-color: var(--white-base);
  border-radius: var(--radius-xl);
}

.symptoms-insights .symptom-categories-container .symptom-categories-list {
  flex: 1;
  padding-right: var(--padding-lg);
}

.symptoms-insights .symptom-categories-container .symptom-categories-list .symptom-category-item {
  border-radius: var(--radius-xl);
  border: 1px solid var(--white-dark);
  width: 100%;
  transition: all 0.2s linear;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--padding-md) var(--padding-lg);
}

.symptoms-insights .symptom-categories-container .symptom-categories-list .symptom-category-item:not(:last-child) {
  margin-bottom: var(--margin-lg);
}

.symptoms-insights .symptom-categories-container .symptom-categories-list .symptom-category-item:not(.selected):hover,
.symptoms-insights .symptom-categories-container .symptom-categories-list .symptom-category-item:not(.selected):focus {
  box-shadow: var(--shadow-md);
  transform: scale(1.015);
  border-color: var(--blue-light);
}

.symptoms-insights .symptom-categories-container .symptom-categories-list .symptom-category-item:hover,
.symptoms-insights .symptom-categories-container .symptom-categories-list .symptom-category-item:focus,
.symptoms-insights .symptom-categories-container .symptom-categories-list .symptom-category-item.selected {
  cursor: pointer;
  outline: none;
}

.symptoms-insights .symptom-categories-container .symptom-categories-list .symptom-category-item.selected {
  border-color: var(--primary-color);
}

.symptoms-insights .symptom-categories-container .symptom-categories-list .symptom-category-item .category-details {
  flex: 1;
  display: flex;
  align-items: center;
}

.symptoms-insights .symptom-categories-container .symptom-categories-list .symptom-category-item .category-details .item-label {
  font-weight: bold;
  color: var(--black-light);
} 

.symptoms-insights .symptom-categories-container .symptom-categories-list .symptom-category-item:hover .category-details .item-label,
.symptoms-insights .symptom-categories-container .symptom-categories-list .symptom-category-item.selected .category-details .item-label {
  color: var(--blue-dark);
}

.symptoms-insights .symptom-categories-container .symptom-categories-list .symptom-category-item .category-details .rank {
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background-color: var(--warning-color);
  color: var(--white-color);
  font-size: var(--semi-small-font-size);
  font-weight: bold;
  font-family: var(--heading-font-family);
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: var(--margin-md);
}

.symptoms-insights .symptom-categories-container .symptom-categories-list .symptom-category-item .category-details .rank.first {
  background-color: var(--error-color);
}

.symptoms-insights .symptom-categories-container .symptom-categories-list .symptom-category-item .score-overview .score-overview-label {
  font-size: var(--small-font-size);
  font-family: var(--heading-font-family);
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: center;
}

.symptoms-insights .symptom-categories-container .symptom-categories-list .symptom-category-item .score-overview .score-overview-value {
  font-size: 28px;
  font-weight: 600;
  font-family: var(--heading-font-family);
  letter-spacing: 1px;
  color: var(--warning-color);
  text-align: center;
}

.symptoms-insights .symptoms-container {
  background-color: var(--foreground-color);
  border-radius: var(--radius-xl);
  border: 1px solid var(--white-dark);
  padding: var(--padding-lg);
  margin-bottom: var(--margin-xl);
}

.symptoms-insights .symptoms-container > h2 {
  margin-top: 0;
}

.symptoms-insights .symptoms-container .chart-metric-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.symptoms-insights .symptoms-container .chart-metric-selector {
  max-width: 50%;
}

.symptoms-insights .symptoms-container .chart-container {
  display: flex;
  margin-top: var(--margin-md);
  flex-wrap: wrap;
}

.symptoms-insights .symptoms-container .chart-container .symptoms-chart {
  flex: 1;
  width: 100%;
  padding-right: calc(var(--padding-lg) * 2);
}

.symptoms-insights .symptoms-statistics-table {
  min-width: 350px;
  max-width: 350px;
}

.symptoms-insights .symptoms-statistics-table .symptoms-stats-metric {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: var(--margin-md);
  position: relative;
}

.symptoms-insights .symptoms-statistics-table .symptoms-stats-metric .stats-metric-selector {
  border: none;
  width: 100%;
  border-radius: var(--radius-lg);
  background-color: transparent;
  padding: var(--padding-sm) var(--padding-lg);
  box-shadow: none;
  margin: 0;
}

.symptoms-stats-metric-dropdown {
  width: 100%;
}

.symptoms-insights .symptoms-statistics-table .symptoms-stats-metric .stats-metric-selector.selected {
  background-color: var(--black-color);
  color: var(--white-color);
}

.symptoms-insights .symptoms-statistics-table .symptoms-stats-metric .stats-metric-selector:not(.selected):hover {
  background-color: var(--grey-light);
}

.symptoms-insights .symptoms-statistics-table .symptoms-stats-metric .stats-metric-selector > h2 {
  margin-top: 0;
  margin-bottom: var(--margin-sm);
  color: inherit;
  line-height: 1;
}

.symptoms-insights .symptoms-statistics-table .symptoms-stats-metric .stats-metric-selector > h2 > .fa {
  font-size: 80%;
  margin-left: var(--margin-md);
}

.symptoms-insights .symptoms-statistics-table .table > thead > tr > th {
  padding: 0 var(--padding-md) var(--padding-sm) var(--padding-md);
}

.symptoms-insights .symptoms-statistics-table .table > thead > tr > th:not(:last-of-type) {
  border-right: 1px solid var(--grey-light);
}

.symptoms-insights .symptoms-statistics-table .table > tbody > tr {
  cursor: pointer;
  background-color: transparent;
  border-color: var(--grey-light);
  border-bottom: none;
}

.symptoms-insights .symptoms-statistics-table .table > tbody > tr:focus {
  background-color: var(--primary-highlight-color);
  outline: none;
}

.symptoms-insights .symptoms-statistics-table .table > tbody > tr:hover {
  background-color: var(--grey-light);
}

.symptoms-insights .symptoms-statistics-table .table > tbody > tr.selected {
  background-color: var(--grey-light);
}

.symptoms-insights .symptoms-statistics-table .table > tbody > tr > td {
  border-right: none;
  padding: var(--padding-sm) var(--padding-md);
  font-size: 14px;
}

.symptoms-insights .symptoms-statistics-table .table > tbody > tr > td:first-child {
  border-left: none;
}

.symptoms-insights .symptoms-statistics-table .table > tbody > tr > td:not(:first-of-type) {
  border-left: 1px solid var(--grey-light);
}

.symptom-insight-chart-tooltip {
  background-color: var(--foreground-color);
  border: 1px solid var(--white-dark);
  border-radius: var(--radius-lg);
  box-shadow: var(--shadow-md);
}

.symptom-insight-chart-tooltip .heading {
  border-bottom: 1px solid var(--white-dark);
  padding: var(--padding-sm) var(--padding-lg);
  text-align: center;
  font-weight: bold;
  font-size: var(--semi-small-font-size);
}

.symptom-insight-chart-tooltip .content-container {
  padding: 0 var(--padding-lg) var(--padding-md) var(--padding-lg);
}

.symptom-insight-chart-tooltip .content-container .content {
  margin-top: var(--margin-sm);
}

.symptom-insight-chart-tooltip .content-container .content .metric {
  margin-top: var(--margin-sm);
}

.symptom-insight-chart-tooltip .content-container .content:not(:last-child) {
  margin-bottom: var(--margin-sm);
}

.symptoms-insights .recent-symptoms-comparison {
  padding: var(--padding-lg);
  background-color: var(--foreground-color);
  border-radius: var(--radius-xl);
  border: 1px solid var(--white-dark);
  margin-bottom: var(--margin-xl);
}

.symptoms-insights .recent-symptoms-comparison h2 {
  margin-top: 0;
}

.rtl-risk-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: var(--margin-xl);
}

.rtl-risk-stat {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: var(--padding-lg);
  width: 700px;
  background-color: var(--foreground-color);
  border-radius: var(--radius-xl);
  border: 1px solid var(--white-dark);
}

.rtl-risk-stat h2 {
  margin-top: 0;
}

.rtl-risk-stat .risk-details {
  display: flex;
  align-items: center;
}

.rtl-risk-stat .risk {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: var(--margin-md);
}

.rtl-risk-stat .risk .risk-label {
  font-size: 32px;
  line-height: 1.3;
  font-weight: bold;
  font-family: var(--heading-font-family);
  text-transform: uppercase;
  letter-spacing: 1px;
}

.rtl-risk-stat .risk img {
  height: 100px;
}

.rtl-risk-stat .description {
  flex: 1;
  font-size: var(--semi-small-font-size);
  margin-bottom: var(--margin-md);
}

.rtl-risk-stat .footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: var(--small-font-size);
}

@media (max-width: 991px) {
  .rtl-risk-stat {
    width: 100%;
  }
}
