ul.sports-team-list {
  margin-bottom: var(--margin-lg);
}

ul.sports-team-list li.sports-team-list-item {
  border-bottom-width: var(--standard-border-width);
  border-bottom-style: var(--standard-border-style);
  border-bottom-color: var(--standard-border-color);
  padding-bottom: var(--padding-sm);
  margin-bottom: var(--margin-sm);
}

ul.sports-team-list li.sports-team-list-item h3 {
  margin-top: 0;
  margin-bottom: 0;
}

ul.sports-team-list li.sports-team-list-item ul {
  margin-bottom: var(--margin-sm);
}

ul.sports-team-list li.sports-team-list-item ul li,
ul.sports-team-list li.sports-team-list-item ul li small {
  @extend small;
}

ul.sports-team-list li.sports-team-list-item ul li.sport,
ul.sports-team-list li.sports-team-list-item ul li.position {
  text-transform: capitalize;
}

ul.sports-team-list li.sports-team-list-item .sports-team-positions-form {
  margin-top: var(--margin-sm);
  padding-top: var(--padding-sm);
  border-top-width: var(--standard-border-width);
  border-top-style: var(--standard-border-style);
  border-top-color: var(--light-border-color);
}

ul.sports-team-list li.sports-team-list-item .sports-team-positions,
ul.sports-team-list li.sports-team-list-item .sports-team-positions-form {
  margin-bottom: var(--margin-sm);
}

ul.sports-team-list li.sports-team-list-item .sports-team-list-item-controls {
  display: flex;
  align-items: baseline;
}

.sports-team-list-item-delete .sports-team-list-item-controls {
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
}

.suggested-teams ul.sports-team-list li.sports-team-list-item {
  cursor: pointer;
}

.suggested-teams ul.sports-team-list li.sports-team-list-item:hover {
  color: var(--primary-color);
  border-color: var(--primary-color);
}