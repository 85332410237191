.badge.badge-error {
  background-color: var(--error-color);
}

.badge.badge-warning {
  background-color: var(--warning-color);
}

.badge.badge-success {
  background-color: var(--success-color);
}

.badge.badge-primary {
  background-color: var(--primary-color);
}
