:root {

  --tab-bar-bottom-border-width: 2px;
  --tab-bar-bottom-border-style: solid;
  --tab-bar-bottom-border-color: var(--primary-color);

  --tab-button-color: var(--font-color);
  --tab-button-font-weight: 300;

  --tab-button-padding: 15px;

  --tab-button-bottom-border-width: 6px;
  --tab-button-bottom-border-style: solid;
  --tab-button-bottom-border-color: var(--primary-color);

}

.tabs.vertical {
  display: flex;
  align-items: flex-start;
}

.tabs .tabs-bar {
  border-bottom-width: var(--tab-bar-bottom-border-width);
  border-bottom-style: var(--tab-bar-bottom-border-style);
  border-bottom-color: var(--tab-bar-bottom-border-color);
  margin-bottom: var(--margin-lg);
  display: flex;
  flex-wrap: wrap;
}

.tabs .tabs-bar .tab-heading {
  margin-right: var(--margin-xl);
}

.tabs.vertical .tabs-bar {
  border-bottom: none;
  border-right: 1px solid var(--grey-light);
  flex-direction: column;
  margin-right: 10px;
  height: auto;
}

.tabs .tabs-bar button.tab-button,
.tabs .tabs-bar a.tab-button {
  appearance: none;
  border: none;
  padding: var(--tab-button-padding);
  margin: 0;
  border-bottom-width: var(--tab-button-bottom-border-width);
  border-bottom-style: var(--tab-button-bottom-border-style);
  border-bottom-color: transparent;
  background-color: transparent;
  color: var(--tab-button-color);
  font-weight: var(--tab-button-font-weight);
}

.tabs.vertical .tabs-bar button.tab-button,
.tabs.vertical .tabs-bar a.tab-button {
  border-bottom: none;
  border-right: var(--tab-button-bottom-border-width) solid transparent;
  text-align: left;
}

.tabs.vertical .tabs-bar button.tab-button.selected,
.tabs.vertical .tabs-bar a.tab-button.selected {
  border-right-color: var(--tab-button-bottom-border-color);
}

.tabs .tabs-bar a.tab-button {
  text-decoration: none;
}

.tabs .tabs-bar button.tab-button.selected,
.tabs .tabs-bar a.tab-button.selected {
  border-bottom-color: var(--tab-button-bottom-border-color);
  border-radius: 0;
}

.tabs .tabs-bar button.tab-button.alert,
.tabs .tabs-bar a.tab-button.alert {
  font-weight: var(--bold-font-weight);
  border-radius: 0;
}

.tabs .tabs-bar button.tab-button.right,
.tabs .tabs-bar a.tab-button.right {
  margin-left: auto;
}

.tabs .tabs-content .tab-component {
  display: none;
}

.tabs .tabs-content .tab-component.selected {
  display: block;
  margin-bottom: var(--margin-lg);
}

.tabs.vertical .tabs-content {
  flex: 1;
}

.tabs.vertical .tabs-content .tab-component.selected {
  flex: 1;
}


/******* Tab Alert Colors ********/

/**** Error Tab ******/
.tabs .tabs-bar button.tab-button.alert.error,
.tabs .tabs-bar a.tab-button.alert.error {
  color: var(--error-color);
}

.tabs .tabs-bar button.tab-button.alert.error.selected,
.tabs .tabs-bar a.tab-button.alert.error.selected {
  border-bottom-color: var(--error-color);
}

/**** Success Tab ******/
.tabs .tabs-bar button.tab-button.alert.success,
.tabs .tabs-bar a.tab-button.alert.success {
  color: var(--success-color);
}

.tabs .tabs-bar button.tab-button.alert.success.selected,
.tabs .tabs-bar a.tab-button.alert.success.selected {
  border-bottom-color: var(--success-color);
}

/**** Warning Tab ******/
.tabs .tabs-bar button.tab-button.alert.warning,
.tabs .tabs-bar a.tab-button.alert.warning {
  color: var(--warning-color);
}

.tabs .tabs-bar button.tab-button.alert.warning.selected,
.tabs .tabs-bar a.tab-button.alert.warning.selected {
  border-bottom-color: var(--warning-color);
}