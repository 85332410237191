
.start-baseline-header {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
}

.start-baseline-header > h1 {
  flex: 1;
}

