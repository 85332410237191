.baseline-badge {
  position: relative;
}

.baseline-badge .badge-icons {
  position: absolute;
  bottom: -6px;
  right: -8px;
}

.baseline-badge .badge-icons .fa {
  font-size: 12px;
}
