.dual-task-test-trials .test-trials-container {
  border-radius: var(--radius-lg);
  border: 1px solid var(--grey-light);
  padding: var(--padding-lg) var(--padding-md);
  margin-bottom: var(--margin-md);
}

.dual-task-test-trials .test-trials-container .trial-section-title {
  font-weight: 600;
  font-size: 18px;
  margin-bottom: var(--margin-md);
}

.dual-task-test-trials .test-scores-container {
  margin-top: var(--margin-lg);
}

.dual-task-number-selector {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  font-size: 18px;
}

.dual-task-number-selector .btn {
  margin: 0;
}

.dual-task-number-selector .btn.btn-stop {
  margin-top: calc(var(--margin-lg) * 2);
}

.unable-to-complete-box {
  padding: var(--padding-lg);
  background-color: var(--primary-highlight-color);
  border: 1px solid var(--primary-color);
  border-radius: var(--radius-lg);
  margin-bottom: var(--margin-md);
}

.unable-to-complete-box.checked {
  background-color: var(--error-highlight-color);
  border-color: var(--error-color);
}

.unable-to-complete-box .form-group,
.unable-to-complete-box .form-group label {
  margin-bottom: 0;
}

/* Number Slider */
.number-slider-container {
  width: 100%;
  font-size: 18px;
  margin-bottom: var(--margin-lg);
}

.number-slider-container button.increment,
.number-slider-container button.decrement {
  width: 100%;
  margin: 0;
  outline: none;
  border: none;
  background-color: var(--grey-light);
  padding-top: calc(var(--padding-sm) + var(--padding-xs));
  padding-bottom: calc(var(--padding-sm) + var(--padding-xs));
}

.number-slider-container button.increment {
  border-top-left-radius: var(--radius-lg);
  border-top-right-radius: var(--radius-lg);
}

.number-slider-container button.decrement {
  border-bottom-left-radius: var(--radius-lg);
  border-bottom-right-radius: var(--radius-lg);
}

.number-slider {
  position: relative;
}

.number-slider > .selection-indicator-container {
  position: absolute;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  pointer-events: none;
  display: flex;
  justify-content: center;
  padding-top: var(--padding-xs);
  padding-bottom: var(--padding-xs);
  backdrop-filter: contrast(100%);
}

.number-slider > .selection-indicator-container > .selection-indicator {
  height: 100%;
  width: 100%;
  border: 1px solid var(--success-color);
  border-radius: var(--radius-lg);
}

.number-slider > .keen-slider {
  height: 100%;
}

.number-slider > .keen-slider > .keen-slider__slide {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--radius-lg);
}

.number-slider > .keen-slider > .keen-slider__slide.active {
  font-weight: var(--bold-font-weight);
}

.number-slider > .keen-slider>.keen-slider__slide:not(.active) {
  cursor: pointer;
}

.number-slider > .keen-slider > .keen-slider__slide:not(.active):hover {
  background-color: var(--white-dark);
}


.number-slider > .shadow-top,
.number-slider > .shadow-bottom {
  height: 10%;
  position: absolute;
  left: 0;
  right: 0;
  pointer-events: none;
}

.number-slider > .shadow-top {
  top: 0;
  background: linear-gradient(to bottom,
      rgba(250, 250, 250, 0.9) 0%,
      rgba(250, 250, 250, 0.3) 100%);
}

.number-slider > .shadow-bottom {
  bottom: 0;
  background: linear-gradient(to top,
      rgba(250, 250, 250, 0.9) 0%,
      rgba(250, 250, 250, 0.3) 100%);
}

.dual-task-test-trials .test-trials-container .table.practise-trial-table {
  table-layout: fixed;
}

.dual-task-test-trials .test-trials-container .table.practise-trial-table > tbody > tr > td {
  font-size: var(--semi-small-font-size);
  text-align: center;
  vertical-align: middle;
  padding-left: var(--padding-xs);
  padding-right: var(--padding-xs);
}

/* Dual Task Trials Grid */
.dual-task-trials-grid-container {
  display: grid;
  grid-template-rows: 0.5fr repeat(3, 1fr);
  width: 100%;
}

.dual-task-trials-grid-container .trial-grid-row {
  display: grid;
  grid-template-columns: 1.2fr 0.5fr 2fr repeat(5, 0.5fr);
}

.dual-task-trials-grid-container .trial-grid-row:not(:first-child):nth-child(2n + 1) {
  background-color: var(--white-base);
}

.dual-task-trials-grid-container .trial-grid-row .grid-item {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: var(--padding-xs) var(--padding-sm);
  border-top: 1px solid var(--standard-border-color);
  border-right: 1px solid var(--standard-border-color);
  width: 100%;
  height: 100%;
  overflow: scroll;
}

.dual-task-trials-grid-container .trial-grid-row .grid-item.start {
  justify-content: flex-start;
  align-items: flex-start;
}

.dual-task-trials-grid-container .trial-grid-row .grid-item.action {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dual-task-trials-grid-container .trial-grid-row .grid-item.action .btn {
  margin: 0;
  font-size: var(--small-font-size);
}

.dual-task-trials-grid-container .trial-grid-row .grid-item.grid-label {
  font-size: var(--small-font-size);
  padding: 0 var(--padding-xs) var(--padding-xs);
  align-items: flex-end;
  justify-content: flex-start;
  border: none;
  height: auto;
}

.dual-task-trials-grid-container .trial-grid-row .grid-item:not(.grid-label):first-child {
  border-left: 1px solid var(--standard-border-color);
}

.dual-task-trials-grid-container .trial-grid-row:last-of-type {
  border-bottom: 1px solid var(--standard-border-color);
}

@media (max-width: 992px) {
  .dual-task-trials-grid-container {
    grid-template-columns: 0.5fr repeat(3, 1fr);
    grid-template-rows: 100%;
  }

  .dual-task-trials-grid-container .trial-grid-row {
    grid-template-columns: 100%;
    grid-template-rows: 1fr repeat(7, 0.75fr);
    font-size: var(--semi-small-font-size);
  }

  .dual-task-trials-grid-container .trial-grid-row:nth-child(2n + 1) {
    background-color: transparent;
  }

  .dual-task-trials-grid-container .trial-grid-row .grid-item:not(.action):not(.grid-label):nth-child(2n + 1) {
    background-color: var(--white-base);
  }

  .dual-task-trials-grid-container .trial-grid-row:last-of-type {
    border-bottom: none;
  }

  .dual-task-trials-grid-container .trial-grid-row:last-of-type .grid-item {
    border-right: 1px solid var(--standard-border-color);
  }

  .dual-task-trials-grid-container .trial-grid-row .grid-item {
    border-left: 1px solid var(--standard-border-color);
    border-right: none;
  }

  .dual-task-trials-grid-container .trial-grid-row .grid-item.action {
    flex-direction: column;
    padding-bottom: var(--padding-md);
  }

  .dual-task-trials-grid-container .trial-grid-row .grid-item.action span.item-label {
    font-weight: 600;
  }

  .dual-task-trials-grid-container .trial-grid-row .grid-item.action .btn {
    font-size: var(--small-font-size);
    padding-left: calc(var(--padding-xs) + var(--padding-sm));
    padding-right: calc(var(--padding-xs) + var(--padding-sm));
    margin-top: var(--margin-sm);
  }

  .dual-task-trials-grid-container .trial-grid-row > .grid-item:not(.grid-label):first-child {
    border: none;
  }

  .dual-task-trials-grid-container .trial-grid-row .grid-item:not(.grid-label):last-child {
    border-bottom: 1px solid var(--standard-border-color);
  }

  .dual-task-trials-grid-container .trial-grid-row .grid-item.grid-label {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: var(--padding-xs);
    width: 100%;
    height: 100%;
    padding-right: var(--padding-sm);
    text-align: right;
    font-weight: 600;
  }

  .dual-task-trials-grid-container .trial-grid-row .grid-item.grid-label:first-child {
    color: transparent;
    user-select: none;
  }
}

/* numbers input table */
.table.dual-task-numbers-input-table {
  margin-top: var(--margin-lg);
}

.table.dual-task-numbers-input-table td.number-input {
  padding: 0;
}

.table.dual-task-numbers-input-table td.number-input input {
  border: none;
  border-radius: 0;
  text-align: center;
  outline: none;
  box-shadow: none;
  background-color: transparent;
  height: 100%;
}

.table.dual-task-numbers-input-table td.number-input input:hover {
  background-color: var(--grey-light);
}

.table.dual-task-numbers-input-table td.number-input input:focus {
  background-color: var(--white-dark);
}

.table.dual-task-numbers-input-table td.number-input .fa {
  margin-left: var(--margin-xs);
  margin-right: var(--margin-xs);
}

.table.dual-task-numbers-input-table td {
  vertical-align: middle;
  text-align: center;
  white-space: nowrap;
}

.dual-task-numbers-input-table td.add-response {
  padding: 0;
}

.dual-task-numbers-input-table td.add-response > button {
  height: 100%;
  width: 100%;
  outline: none;
  border: none;
  box-shadow: none;
  padding: var(--padding-sm);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  color: var(--primary-color);
  font-weight: 600;
  font-size: var(--semi-small-font-size);
  cursor: pointer;
}

.dual-task-numbers-input-table td.add-response > button:not(:disabled):hover {
  background-color: var(--white-dark);
}

.dual-task-numbers-input-table td.add-response > button:not(:disabled):active {
  background-color: var(--primary-highlight-color);
}

.dual-task-numbers-input-table td.add-response>button:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

/* trial modal */
.modal.dual-task-trial-modal {
  padding: var(--padding-lg);
}

.modal.dual-task-trial-modal .dialog {
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  width: 100%;
}

@media (max-width: 798px) {
  .modal.dual-task-trial-modal {
    padding: 0;
  }
  
  .modal.dual-task-trial-modal .dialog {
    border-radius: 0;
  }
}

.dual-task-trial-modal .dialog .dialog-header {
  padding-top: var(--padding-md);
  padding-bottom: var(--padding-md);
}

.dual-task-trial-modal .dialog .dialog-header .close {
  position: absolute;
  top: 0;
  right: 0;
  margin: var(--margin-md) var(--margin-xl) 0;
  height: 44px;
  width: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: var(--white-dark);
}

.dual-task-trial-modal .dialog .dialog-header .close:hover {
  background-color: var(--grey-light);
}

.dual-task-trial-modal .dialog .dialog-header .close:active {
  background-color: var(--primary-highlight-color);
  color: var(--blue-dark);
}

.dual-task-trial-modal .dialog .dialog-header h1 {
  margin: 0;
}

.dual-task-trial-modal .trial-walkthrough-container {
  height: 100%;
  width: 100%;
  display: flex;
}

.dual-task-trial-modal .trial-walkthrough-container .trial-walkthrough {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.dual-task-trial-modal .trial-walkthrough-container .trial-walkthrough .timer-display {
  font-size: 38px;
  font-weight: 600;
  text-align: center;
}

.dual-task-trial-modal .trial-walkthrough-container .trial-walkthrough .responses-table > .table {
  table-layout: fixed;
  margin-top: var(--margin-md);
}

.dual-task-trial-modal .trial-walkthrough-container .trial-walkthrough .responses-table > .table td {
  font-size: var(--semi-small-font-size);
  text-align: center;
  vertical-align: middle;
  padding-left: var(--padding-xs);
  padding-right: var(--padding-xs);
  position: relative;
}

.dual-task-trial-modal .trial-walkthrough-container .trial-walkthrough .responses-table > .table td .active-indicator {
  position: absolute;
  top: -2px;
  right: -2px;
  left: -2px;
  bottom: -2px;
  z-index: 10;
  background-color: transparent;
  border: 2px solid var(--blue-dark);
  pointer-events: none;
}

.dual-task-trial-modal .trial-walkthrough-container .trial-walkthrough .trial-steps {
  flex: 1;
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.dual-task-trial-modal .trial-walkthrough-container .trial-walkthrough .trial-steps .start-trial-action button {
  height: 200px;
  width: 200px;
  border-radius: 50%;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 44px;
}

.dual-task-trial-modal .trial-walkthrough-container .trial-walkthrough .trial-steps .trial-keyboard-controls {
  position: absolute;
  top: 0;
  right: 0;
  max-width: 300px;
  font-size: var(--semi-small-font-size);
}

.trial-steps .trial-keyboard-controls .controls-title {
  font-size: 18px;
  font-weight: 600;
  text-decoration: underline;
  margin-bottom: var(--margin-sm);
}

.trial-steps .trial-keyboard-controls ul li {
  display: flex;
  margin-bottom: calc(var(--margin-xs) + var(--margin-sm));
}

.trial-steps .trial-keyboard-controls ul li .fa {
  margin-right: var(--margin-sm);
  margin-top: 2px;
}

@media (max-width: 992px) {
  .dual-task-trial-modal .trial-walkthrough-container .trial-walkthrough .trial-steps .trial-keyboard-controls {
    display: none;
  }
}

.dual-task-trial-modal .trial-walkthrough-container .trial-complete-container {
  height: 100%;
  width: 100%;
}

.dual-task-trial-modal .trial-walkthrough-container .trial-complete-container .trial-complete {
  height: 100%;
  padding-top: 5%;
  margin: 0 auto;
  max-width: 750px;
}

.dual-task-trial-modal .trial-walkthrough-container .trial-complete-container .trial-complete .reset-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: var(--margin-md);
}

.dual-task-trial-modal .trial-walkthrough-container .trial-complete-container .trial-complete .reset-container .btn {
  font-weight: 600;
}

.dual-task-trial-modal .trial-walkthrough-container .trial-complete-container .trial-complete .completion-title {
  font-size: 38px;
  font-weight: 600;
  text-align: center;
  margin-bottom: var(--margin-sm);
}

.dual-task-trial-modal .trial-walkthrough-container .trial-complete-container .trial-complete .trail-results {
  display: flex;
  justify-content: center;
  align-items: stretch;
  gap: var(--padding-lg);
}

.dual-task-trial-modal .trial-walkthrough-container .trial-complete-container .trial-complete .trail-results .results-row {
  border: 1px solid var(--grey-light);
  background-color: var(--background-color);
  border-radius: var(--radius-lg);
  padding: var(--padding-md);
  margin-bottom: var(--margin-md);
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.dual-task-trial-modal .trial-walkthrough-container .trial-complete-container .trial-complete .trail-results .results-row .result-label {
  font-weight: 500;
  margin-right: var(--margin-sm);
  font-family: var(--heading-font-family);
  letter-spacing: 1px;
  margin-bottom: var(--margin-md);
}

.dual-task-trial-modal .trial-walkthrough-container .trial-complete-container .trial-complete .trail-results .results-row .result-item {
  font-size: 32px;
  font-weight: 600;
}

.dual-task-trial-modal .trial-walkthrough-container .trial-complete-container .trial-complete .done-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: var(--margin-md);
}

.dual-task-failure-input-items {
  padding: var(--padding-md);
  margin: var(--margin-md) 0;
  background-color: var(--primary-highlight-color);
  border-radius: var(--radius-lg);
}

.dual-task-failure-input-items .title {
  color: var(--blue-dark);
  font-weight: 600;
  margin-bottom: var(--margin-md);
  font-size: 18px;
}

.dual-task-failure-input-items .inline-checkboxes {
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}

@media (max-width: 798px) {
  .dual-task-failure-input-items .inline-checkboxes {
    flex-direction: column;
    align-items: flex-start;
  }
}

.modal.dual-task-edit-modal > .dialog {
  max-width: 700px;
  width: 100%;
  max-height: 100%;
}
