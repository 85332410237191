.post-injury-assessment-display .post-injury-section {
  margin-bottom: var(--margin-lg);
  border-bottom-width: var(--standard-border-width);
  border-bottom-style: var(--standard-border-style);
  border-bottom-color: var(--standard-border-color);
}

.post-injury-assessment-display .post-injury-section.post-injury-symptoms,
.post-injury-assessment-display .post-injury-section.post-injury-neurological {
  margin-bottom: 0;
  border-bottom-width: 0;
}

.post-injury-assessment-display .post-injury-section h2,
.post-injury-assessment-display .post-injury-section h3 {
  margin-top: 0;
}

.post-injury-assessment-display .post-injury-section .post-injury-group ul {
  margin-bottom: 0;
}

.post-injury-assessment-display .post-injury-section ul>li.checklist-item:not(.checked) {
  color: var(--black-light);
}

.post-injury-assessment-display .post-injury-section ul>li>.fa {
  width: 20px;
}

.post-injury-assessment-display .post-injury-section .symptoms-list {
  margin-bottom: 0;
}

.post-injury-assessment-display .post-injury-section .post-injury-group {
  margin-bottom: var(--margin-lg);
}

.post-injury-assessment-display .post-injury-section .post-injury-group.post-injury-table-group {
  margin-bottom: 0;
  /* Margin will be applied by the table */
}

.post-injury-assessment-display .post-injury-section .post-injury-group.post-injury-table-group strong {
  display: block;
  margin-bottom: var(--margin-sm);
}

.post-injury-assessment-display .post-injury-section.post-injury-return .injury-stage-view {
  padding: 0;
  margin-bottom: var(--margin-lg);
}

.post-injury-assessment-display .neurological-result {
  border-bottom-width: var(--standard-border-width);
  border-bottom-style: var(--standard-border-style);
  border-bottom-color: var(--standard-border-color);
  margin-bottom: var(--margin-lg);
}

.post-injury-assessment-display .neurological-result .neurological-result-meta {
  display: flex;
  align-items: center;
  margin-bottom: var(--margin-sm);
}

.post-injury-assessment-display .neurological-result .neurological-result-meta h3 {
  margin-top: 0;
  margin-bottom: 0;
}

.post-injury-assessment-display .neurological-result .neurological-result-meta h3:first-child {
  flex: 1;
}

.post-injury-assessment-display .neurological-result .notes {
  padding-top: var(--padding-sm);
  padding-bottom: var(--padding-sm);
}

.post-injury-assessment-display .neurological-result .notes p {
  margin: 0;
  border-top-width: var(--standard-border-width);
  border-top-style: var(--standard-border-style);
  border-top-color: var(--standard-border-color);
}

.post-injury-assessment-display .comparable-baseline-view,
.post-injury-assessment-display .voms-test-view,
.post-injury-assessment-display .buffalo-test-view,
.post-injury-assessment-display .ovs-test {
  border-bottom-width: var(--standard-border-width);
  border-bottom-style: var(--standard-border-style);
  border-bottom-color: var(--standard-border-color);
  margin-bottom: var(--margin-lg);
}

.post-injury-assessment-display .buffalo-test-view .buffalo-test-header {
  border-bottom-width: 0;
}