.user-baseline-detail .test-header {
  padding-bottom: var(--padding-lg);
  margin-bottom: var(--margin-lg);
}

.user-baseline-detail .baseline-details-header .label {
  margin-left: var(--margin-md);
  font-size: var(--semi-small-font-size);
}

.user-baseline-detail h2 {
  margin-top: 0;
}

.user-baseline-detail .user-baseline-test-selection h2 {
  margin: 0;
}

.user-baseline-detail .baseline-section ul {
  margin-bottom: var(--margin-lg);
}

.user-baseline-detail .baseline-section,
.user-baseline-detail .baseline-detail-row {
  border-bottom-width: var(--standard-border-width);
  border-bottom-style: var(--standard-border-style);
  border-bottom-color: var(--standard-border-color);
  margin-bottom: var(--margin-lg);
}

.user-baseline-detail .baseline-detail-row .baseline-section {
  border-bottom-width: 0;
  margin-bottom: 0;
}

.user-baseline-detail .baseline-section table>tbody>tr.totals,
.user-baseline-detail .baseline-section table>tbody>tr>td.total {
  background-color: var(--primary-color);
  color: #FFF;
}