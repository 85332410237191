:root {
  --concentration-numbers-list-min-width: 170px;
}

.number-list-wrapper {
  flex: 1;
  flex-basis: 100%;
  border: 1px solid var(--primary-color);
  border-radius: var(--standard-border-radius);
  padding: var(--padding-sm);
  margin-bottom: var(--margin-lg);
}

.number-list-container {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  width: 100%;
}

.number-list-container .number-list {
  flex: 1;
  min-width: var(--concentration-numbers-list-min-width);
  padding: calc(var(--padding-sm) * 2);

}

.number-list-container .number-list .active-number-row {
  background-color: var(--primary-color);
  color: #FFF;
  border: 1px solid var(--grey-color);
  border-radius: var(--standard-border-radius);
}

.number-list-container .number-list .active-number-row>span {
  margin-left: calc(var(--margin-sm) * 2);
  font-weight: var(--bold-font-weight);
}

.numbers-btn-group {
  display: flex;
  flex-wrap: wrap;
  padding: var(--padding-lg);
}

.numbers-btn-group .numbers-btn {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.numbers-btn-group .numbers-btn:first-of-type {
  justify-content: flex-start;
}

.numbers-btn-group .numbers-btn:last-of-type {
  justify-content: flex-end;
}


.months-in-reverse,
.months-in-reverse-timed {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: var(--padding-lg);
}

.months-in-reverse > .months-view,
.months-in-reverse-timed > .months-view {
  flex: 1;
  flex-basis: 100%;
  text-align: center;
}

.months-in-reverse-timed .months-in-reverse-container {
  flex: 1;
  flex-basis: 100%;
}

.months-in-reverse-timed .months-in-reverse-container .months-in-reverse-timer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: var(--padding-md);
}

.months-in-reverse-timed .months-in-reverse-container .months-in-reverse-timer p {
  margin-bottom: 0;
  margin-right: var(--margin-md);
  font-size: var(--h3-font-size);
}

.months-in-reverse-timed .months-in-reverse-container .months-answer-input {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.months-in-reverse-timed .months-in-reverse-container .months-answer-input .radio-group-container {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
