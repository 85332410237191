/* PRODUCT FORM */
.product-form {
  padding-bottom: 100px;
}

.product-form .input-group .form-control {
  border-radius: var(--radius-lg) 0 0 var(--radius-lg);
}

.product-form .input-group .input-group-btn {
  padding-top: 21px;
  vertical-align: top;
}

.product-form .input-group .input-group-btn .btn {
  border-radius: 0 var(--radius-lg) var(--radius-lg) 0;
  line-height: 1;
  height: 42px;
  margin-bottom: 0;
}

.product-form .form-footer-banner {
  position: fixed;
  z-index: 10;
  bottom: 0;
  left: 0;
  right: 0;
  padding-bottom: var(--padding-md);
  padding-top: var(--padding-xl);
  background-color: var(--foreground-color);
  border-top: 1px solid var(--grey-light);
}

.product-form .form-footer-banner .checkbox-input-group {
  margin-right: var(--margin-lg);
}

.product-images-dropzone {
  height: 300px;
  overflow: hidden;
  width: 100%;
  border: 1px solid var(--grey-light);
  border-radius: var(--radius-lg);
  margin-bottom: var(--margin-md);
  background-color: var(--white-base);
}

.product-images-dropzone.drag-over {
  border-style: dashed;
  background-color: var(--white-dark);
}

.product-images-dropzone .empty-files-view {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.product-images-dropzone .empty-files-view .empty-icon {
  margin-bottom: var(--margin-md);
  font-size: 38px;
  color: var(--grey-base);
}

.product-images-dropzone .images-list-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.product-images-dropzone .images-list-container .images-list {
  flex: 1;
  overflow-y: scroll;
  display: flex;
  flex-wrap: wrap;
  padding: var(--padding-lg) var(--padding-lg) 0 0;
}

.product-images-dropzone .images-list-container .images-list .product-image {
  height: 140px;
  width: 140px;
  position: relative;
  border: 1px solid var(--grey-light);
  margin-left: var(--margin-lg);
  margin-bottom: var(--margin-lg);
  border-radius: var(--radius-md);
  overflow: hidden;
}

.product-images-dropzone .images-list-container .images-list .product-image:hover {
  border-color: var(--primary-color);
}

.product-images-dropzone .images-list-container .images-list .product-image img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.product-images-dropzone .images-list-container .images-list .product-image button.destroy {
  position: absolute;
  top: 2px;
  right: 2px;
  width: 18px;
  height: 18px;
  border-radius: var(--radius-md);
  border: none;
  box-shadow: none;
  background-color: var(--error-color);
  color: var(--white-color);
  z-index: 1;
  font-size: var(--small-font-size);
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 1;
}

.product-images-dropzone .images-list-container .add-images-btn-container {
  padding: var(--padding-sm);
  display: flex;
  align-items: center;
  justify-content: center;
}

/* CREATE PARTNERSHIP MODAL */
.partnership-modal .dialog {
  width: 70%;
  max-height: 100%;
}

.create-partnership-form {
  padding-bottom: var(--padding-lg);
}

/* Admin Product Filters */
.admin-product-filters {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: var(--margin-md) 0;
}

.admin-product-filters form.product-search-form {
  width: 50%;
}

.admin-product-filters form.product-search-form label {
  display: flex;
  align-items: center;
}

.admin-product-filters form.product-search-form label > input {
  flex: 1;
  border-right-width: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.admin-product-filters form.product-search-form label > button {
  @extend form .form-control;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  color: var(--white-color);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}


@media (max-width: 796px) {
  .admin-product-filters {
    margin: 0;
  }

  .admin-product-filters form.product-search-form {
    width: 100%;
  }
}

/* Marketplace Products List */
.page.marketplace,
.page.marketplace main {
  height: 100%;
}

.page.marketplace .products-empty {
  margin-top: 40px;
  max-width: 500px;
}

.marketplace-products-container {
  display: flex;
  align-items: flex-start;
}

.marketplace-products-container .product-filter-sidebar {
  width: 300px;
  height: 100%;
  padding: 10px;
  position: sticky;
  top: 90px;
}

.marketplace-products-container .products-container {
  flex: 1;
  height: 100%;
  padding: var(--padding-xl) calc(var(--padding-lg) * 2);
}

.products-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: calc(var(--padding-lg) * 2);
  row-gap: var(--padding-lg);
  height: 100%;
  padding-top: var(--padding-md);
}

@media (max-width: 1192px) {
  .products-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 992px) {
  .marketplace-products-container .product-filter-sidebar {
    display: none;
  }
}

@media (max-width: 798px) {
  .marketplace-products-container .products-container {
    padding: var(--padding-xl) 0;
  }
}

@media (max-width: 590px) {
  .products-grid {
    grid-template-columns: 1fr;
  }
}

/* Product Filters */
.product-filters .filter-section {
  padding-bottom: var(--padding-md);
}

.product-filters .filter-section h2 {
  font-size: 18px;
}

.product-filters .filter-section .categories {
  margin-left: var(--margin-md);
}

.product-filters .filter-section .categories li {
  padding: var(--padding-xs) var(--padding-md);
  cursor: pointer;
  font-size: var(--semi-small-font-size);
}

.product-filters .filter-section .categories li.active {
  text-decoration: underline;
  font-weight: bolder;
  color: var(--primary-color);
}

.product-filters .filter-section .categories li:hover:not(.active) {
  text-decoration: underline;
}

.product-filters .filter-section .filter-checkbox {
  display: flex;
  align-items: center;
  margin-bottom: var(--margin-md);
  padding-top: var(--padding-sm);
}

.product-filters .filter-section .filter-checkbox input[type=checkbox] {
  margin: 0;
  cursor: pointer;
}

.product-filters .filter-section .filter-checkbox label {
  padding: 0 var(--padding-md);
  margin: 0;
  font-weight: normal;
  font-size: var(--semi-small-font-size);
  line-height: 1;
  cursor: pointer;
}

/* Products List Header */
.products-list-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--padding-md);
  background-color: var(--white-dark);
  margin-bottom: var(--margin-md);
  border-radius: var(--radius-lg);
}

.products-list-header h2 {
  margin: 0;
}

.products-list-header .product-filters-modal-button {
  display: none;
}

.products-list-header .product-filters-modal-button .btn {
  margin-bottom: 0;
}

@media (max-width: 992px) {
  .products-list-header .product-filters-modal-button {
    display: block;
  }
}

/* Product Cards */
.product-card {
  position: relative;
  border-radius: var(--radius-lg);
  max-width: 100%;
  overflow: hidden;
  text-decoration: none;
  color: inherit;
  background-color: var(--foreground-color);
}

.product-card:hover {
  text-decoration: none;
}

.product-card .discount-badge {
  position: absolute;
  top: 0;
  left: 0;
  margin: var(--margin-md) 0 0 var(--margin-md);
  padding: 3px 8px;
  font-size: var(--small-font-size);
  font-weight: 700;
  line-height: 1.3;
  border-radius: 999em;
  background-color: var(--secondary-color);
  color: var(--white-light);
}

.product-card .visibility-badge {
  position: absolute;
  top: 0;
  right: 0;
  margin: var(--margin-md) var(--margin-md) 0 0;
  height: 26px;
  width: 26px;
  border-radius: 50%;
  font-size: var(--semi-small-font-size);
  background-color: var(--white-dark);
  color: var(--grey-dark);
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.product-card .image-container {
  height: 200px;
  display: flex;
  justify-content: center;
  overflow: hidden;
}

.product-card .image-container img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.product-card .details-container {
  padding: var(--padding-md);
  position: relative;
}

.product-card .details-container .product-name {
  margin-top: var(--margin-sm);
  font-family: var(--heading-font-family);
  font-weight: 300;
}

.product-card:hover .product-name {
  text-decoration: underline;
  font-weight: 500;
}

.product-card .partner-view {
  display: inline-flex;
  border-radius: 999em;
  background-color: var(--white-dark);
  font-size: var(--small-font-size);
  padding: var(--padding-xs) var(--padding-md);
  margin: var(--margin-sm) 0;
}

.product-card .details-container .product-price {
  font-size: 18px;
  margin: var(--margin-sm) 0;
  font-weight: 600;
}

.product-card .details-container .product-price.discount {
  color: var(--error-color);
}

.product-card .details-container .product-price.discount .saving {
  text-transform: uppercase;
  font-size: var(--small-font-size);
}

/* Product Partner Tag */
.partner-tag-wrapper {
  display: inline-flex;
}

.partner-tag {
  display: inline-flex;
  border-radius: 999em;
  background-color: var(--white-dark);
  font-size: var(--small-font-size);
  padding: var(--padding-xs) var(--padding-md);
  margin: var(--margin-sm) 0;
}

.partner-popover-content {
  padding: var(--padding-md);
}

.partner-popover-content .partner-image {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  overflow: hidden;
}

.partner-popover-content .partner-image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.partner-popover-content .partner-details {
  flex: 1;
  padding-left: var(--padding-lg);
}

.partner-popover-content .partner-details .name {
  margin: var(--margin-sm) 0;
  font-size: 18px;
  font-weight: bold;
}


/* Product Partner Profile */
.product-partner-profile .partner-profile-header {
  display: flex;
  margin-top: var(--margin-xl);
  margin-bottom: var(--margin-md);
}

.product-partner-profile .partner-profile-header .partner-image {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  overflow: hidden;
}

.product-partner-profile .partner-profile-header .partner-image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.product-partner-profile .partner-profile-header .partner-details {
  flex: 1;
  padding-left: var(--padding-lg);
}

.product-partner-profile .partner-profile-header .partner-details h1 {
  margin-bottom: var(--margin-md);
  margin-top: var(--margin-sm);
}

/* Product Listing */
.product-listing-page .admin-actions {
  margin-top: var(--margin-lg);
}

.product-listing-page {
  padding-bottom: calc(var(--padding-xl) * 2);
}

.product-listing {
  display: grid;
  padding: var(--padding-xl) 0;
  column-gap: calc(var(--padding-lg) * 2);
  grid-template-columns: 1.75fr 1fr;
  grid-template-areas: 
    "carousel title"
    "carousel details"
    "description details";
}

.product-listing .product-title {
  grid-area: title;
  margin-bottom: var(--margin-md);
}

.product-listing .product-title .category-tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 5px;
}

.product-listing .product-title .category-tags .label {
  overflow: hidden;
  white-space: nowrap;
  margin-top: 5px;
  margin-right: 5px;
  border-radius: 999em;
}

.product-listing .product-title .category-tags .label + .label {
  margin-left: 0;
}

.product-listing .product-description {
  grid-area: description;
}

.product-listing .product-title h1 {
  margin-top: var(--margin-md);
  margin-bottom: var(--margin-sm);
}

.product-listing .product-details-container {
  grid-area: details;
}

.product-listing .product-details-container .product-price-details {
  background-color: var(--white-dark);
  border-radius: var(--radius-lg);
  margin: var(--margin-sm) var(--margin-sm) var(--margin-xl) var(--margin-sm);
  padding: calc(var(--padding-xl) + var(--padding-sm)) var(--padding-md);
}

.product-listing .product-details-container .product-price-details .price-section {
  text-align: center;
}

.product-listing .product-details-container .product-price-details .price-section:not(:last-child) {
  margin-bottom: var(--margin-xl);
}

.product-listing .product-details-container .product-price-details .price-section .heading {
  text-transform: uppercase;
  font-size: var(--small-font-size); 
  font-family: var(--heading-font-family);
  letter-spacing: 1px;
}

.product-listing .product-details-container .product-price-details .price-section .price {
  font-size: var(--h2-font-size);
}

.product-listing .product-details-container .coupon-section {
  text-align: center;
  margin-bottom: calc(var(--margin-lg) * 2);
}

.product-listing .product-details-container .coupon-section .title {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: var(--semi-small-font-size);
  font-family: var(--heading-font-family);
  margin-bottom: var(--margin-sm);
}

.product-listing .product-details-container .coupon-section .coupon-code-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-listing .product-details-container .coupon-section .coupon-code-container .coupon-code {
  display: flex;
  align-items: center;
  border: 1px solid var(--grey-light);
  border-radius: var(--radius-lg);
  height: 42px;
  overflow: hidden;
  background-color: var(--white-dark);
}

.product-listing .product-details-container .coupon-section .coupon-code-container .coupon-code .code {
  font-size: var(--h3-font-size);
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 var(--padding-lg);
}

.product-listing .product-details-container .coupon-section .coupon-code-container .coupon-code .clipboard-button {
  height: 100%;
  border-left: 1px solid var(--grey-light);
}

.product-listing .product-details-container .coupon-section .coupon-code-container .coupon-code .btn {
  margin: 0;
  padding: 0 var(--padding-md);
  height: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 0;
}

/* Slider */
.product-listing .image-slider {
  overflow: hidden;
  grid-area: carousel;
}

.product-listing .image-slider .navigation-container {
  position: relative;
  display: flex;
  background-color: var(--foreground-color);
  border-radius: var(--radius-lg);
  overflow: hidden;
}

.product-listing .image-slider .keen-slider.main {
  height: 400px;
  flex: 1;
}

.product-listing .image-slider .keen-slider.main .keen-slider__slide img {
  height: 400px;
  width: 100%;
  object-fit: contain;
}

.product-listing .image-slider .keen-slider.main .keen-slider__slide .react-player__preview {
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}

.product-listing .image-slider .keen-slider.main .keen-slider__slide .react-player__preview .play-icon {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--tertiary-color);
  font-size: var(--h2-font-size);
  color: var(--white-color);
  transition: all 0.2s ease-in-out;
}

.product-listing .image-slider .keen-slider.main .keen-slider__slide .react-player__preview .play-icon .fa {
  margin-left: var(--margin-xs);
}

.product-listing .image-slider .keen-slider.main .keen-slider__slide .react-player__preview:hover .play-icon {
  transform: scale(1.3);
}

.product-listing .image-slider .navigation-container button.slider-nav {
  margin: 0;
  border: 0;
  opacity: 1;
  background-color: transparent;
  box-shadow: none;
  padding: 0 var(--padding-sm);
  font-size: 24px;
  color: var(--grey-dark);
  transition: all 0.2s linear;
}

.product-listing .image-slider .navigation-container button.slider-nav:hover {
  background-color: rgba(27, 28, 29, 0.1);
}

.product-listing .image-slider .navigation-container .slide-dots {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--padding-sm) 0 var(--padding-md) 0;
}

.product-listing .image-slider .navigation-container .slide-dots .slide-dot {
  background-color: var(--tertiary-color);
  padding: 0;
  margin: 0;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  box-shadow: none;
  border: none;
  pointer-events: auto;
  transition: all 0.2s linear;
}

.product-listing .image-slider .navigation-container .slide-dots .slide-dot.active {
  background-color: var(--secondary-color);
}

.product-listing .image-slider .navigation-container .slide-dots .slide-dot:not(:last-child) {
  margin-right: var(--margin-md);
}

.product-listing .image-slider .keen-slider.preview {
  height: 80px;
  margin-top: var(--margin-lg);
  margin-bottom: var(--margin-lg);
}

.product-listing .image-slider .keen-slider.preview .keen-slider__slide {
  background-color: var(--foreground-color);
  border-radius: var(--radius-lg);
  border: 2px solid transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.product-listing .image-slider .keen-slider.preview .keen-slider__slide:hover {
  border-color: var(--grey-light);
}

.product-listing .image-slider .keen-slider.preview .keen-slider__slide.active {
  border-color: var(--primary-color);
}

.product-listing .image-slider .keen-slider.preview .keen-slider__slide img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.product-listing .image-slider .keen-slider.preview .video-thumb {
  pointer-events: none;
  height: 100%;
  width: 100%;
}

.product-listing .image-slider .keen-slider.preview .video-thumb .react-player__preview {
  background-repeat: no-repeat;
  background-size: contain !important;
}

.product-listing .image-slider .keen-slider.preview .video-thumb .react-player__preview .play-icon {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--tertiary-color);
  font-size: var(--small-font-size);
  color: var(--white-color);
}

.product-listing-page .product-not-found-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: calc(var(--margin-xl) * 2) 0;
}

.product-listing-page .product-not-found-view p {
  max-width: 500px;
  text-align: center;
}

.product-listing-page .product-not-found-view .header-icon {
  font-size: 56px;
  color: var(--grey-base);
}

@media (max-width: 798px) {
  .product-listing {
    column-gap: 0px;
    grid-template-columns: 1fr;
    grid-template-areas:
      "title"
      "carousel"
      "details"
      "description";
  }
}