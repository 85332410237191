.blackhawks-start .blackhawks-start-form .radio-input-group,
.blackhawks-start .blackhawks-start-form .radio-input-group>label {
  width: 100%;
}

.blackhawks-start .blackhawks-start-form .radio-input-group>label>input {
  margin-right: var(--margin-sm);
}

.blackhawks-test .test-header {
  padding-bottom: var(--padding-lg);
  margin-bottom: var(--margin-lg);
}

.blackhawks-test form.blackhawks-test-form h2 {
  padding-bottom: var(--padding-lg);
  border-bottom-width: var(--standard-border-width);
  border-bottom-style: var(--standard-border-style);
  border-bottom-color: var(--standard-border-color);
}

.blackhawks-test form.blackhawks-test-form label.test-timer {
  display: flex;
  flex-wrap: wrap;
}

.blackhawks-test form.blackhawks-test-form label.test-timer>span {
  width: 100%;
  flex-basis: 100%;
}

.blackhawks-test form.blackhawks-test-form label.test-timer>input {
  flex: 1;
  border-right-width: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.blackhawks-test form.blackhawks-test-form label.test-timer>button {
  @extend form .form-control;
  background-color: var(--success-color);
  border-color: var(--success-color);
  color: #FFF;
}

.blackhawks-test form.blackhawks-test-form table>thead>tr>th:nth-child(4) {
  width: 100px;
}

.blackhawks-test form.blackhawks-test-form .blackhawks-stationary-bike-rest-stage h3 {
  margin-top: 0;
}

.blackhawks-test form.blackhawks-test-form .blackhawks-plyometrics-stage {
  border-bottom-width: var(--standard-border-width);
  border-bottom-style: var(--standard-border-style);
  border-bottom-color: var(--standard-border-color);
}

.blackhawks-test form.blackhawks-test-form .blackhawks-plyometrics-stage ul.directions>li {
  margin-bottom: var(--margin-sm);
}

.blackhawks-test form.blackhawks-test-form .blackhawks-plyometrics-stage ul.rest-direction {
  margin-bottom: var(--margin-lg);
}

.blackhawks-test form.blackhawks-test-form .blackhawks-plyometrics-stage.stage-4 ul.rest-direction {
  display: none;
}

.blackhawks-test form.blackhawks-test-form .blackhawks-stationary-bike-rest-stage input,
.blackhawks-test form.blackhawks-test-form .blackhawks-plyometrics-stage input,
.blackhawks-test form.blackhawks-test-form table input {
  display: block;
  width: 100%;
  margin: 0;
}

.blackhawks-test form.blackhawks-test-form .blackhawks-stationary-bike-rest-stage textarea,
.blackhawks-test form.blackhawks-test-form .blackhawks-plyometrics-stage textarea,
.blackhawks-test form.blackhawks-test-form table textarea {
  display: block;
  height: 34px;
  margin: 0;
}

.blackhawks-test form.blackhawks-test-form .blackhawks-stationary-bike-rest-stage input,
.blackhawks-test form.blackhawks-test-form .blackhawks-stationary-bike-rest-stage textarea,
.blackhawks-test form.blackhawks-test-form .blackhawks-plyometrics-stage input,
.blackhawks-test form.blackhawks-test-form .blackhawks-plyometrics-stage textarea {
  margin-bottom: var(--margin-lg);
}

.blackhawks-plyometrics-cooldown {
  margin-bottom: var(--margin-lg);
}

.blackhawks-affix-timer.affix {
  left: 0;
  z-index: 100;
  padding-top: calc(var(--padding-lg) * 2);
  padding-left: calc(var(--padding-lg) * 2);
  padding-bottom: var(--padding-sm);
  background-color: #fff;
  opacity: 0.85;
  width: 100%;
}

.blackhawks-timer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 10px 0;
}

.blackhawks-timer .timer-display {
  font-size: 50px;
  line-height: 50px;
  margin-right: 30px;
  font-family: var(--heading-font-family);
  font-feature-settings: "tnum";
  font-variant-numeric: tabular-nums;
}

.blackhawks-plyometrics-stage>div>div>div {
  margin-bottom: var(--margin-lg);
}

.blackhawks-stationary-bike-rest-stage>div>div>div {
  margin-bottom: var(--margin-lg);
}

.blackhawks-test-display .blackhawks-test-content table>tbody>tr>td:last-child:not(:first-child) {
  width: 40%;
}

.blackhawks-cooldown-result .blackhawks-cooldown-result-row label {
  width: 35%;
}

@media screen and (max-width: 540px) {
  .blackhawks-timer .timer-display {
    font-size: var(--h1-display-font-size);
    line-height: var(--h1-display-font-size);
    margin-right: var(--margin-sm);
  }

  .blackhawks-affix-timer.affix {
    padding-top: var(--padding-lg);
    padding-left: var(--padding-lg);
  }

  .blackhawks-timer .btn {
    padding: var(--padding-sm) var(--padding-lg);
    font-size: inherit;
    line-height: inherit;
  }
}