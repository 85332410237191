@media print {

  .user-profile main>.row>.col-md-8 {
    width: 100%;
  }

  .user-profile main>.row>.col-md-4,
  .user-profile .tabs-bar {
    display: none;
  }

  .user-profile .patient-meta {
    page-break-inside: avoid;
    margin-bottom: var(--margin-lg);
  }

  .user-profile .card-container {
    page-break-inside: avoid;
  }

  .user-profile .card-container>.card-container-header {
    padding-bottom: var(--padding-sm);
    margin-bottom: var(--margin-lg);
    border-bottom-width: var(--standard-border-width);
    border-bottom-style: var(--standard-border-style);
    border-bottom-color: var(--standard-border-color);
  }

  .user-profile .card-container>.card-container-header h2 {
    color: var(--black-color);
    top: 0;
    padding: 0;
    margin: 0;
  }

  .user-profile .card-container>.card-container-body {
    padding: 0;
    border-width: 0;
  }

  .user-profile .card.card-show,
  .user-profile .card.card-preview {
    padding: 0;
  }

  .user-profile .card.card-show .card-row label {
    text-align: left;
  }

  .user-profile .card-active-clinic-select {
    display: none;
  }

  .user-profile .component-view {
    width: 100%;
  }

  .user-profile .actions-section {
    display: none;
  }

  .scroll-to-top {
    display: none;
  }

}