.start-test-search {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.start-test-search > .search-input-group {
  flex: 1;
  flex-basis: 100%;
}
