.continue-baseline-page .continue-baseline-header {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  padding-top: var(--padding-md);
}

.continue-baseline-page .continue-baseline-header .header-left {
  flex: 1;
}

.continue-baseline-page .continue-baseline-header .header-right {
  margin-bottom: var(--margin-lg);
}

.continue-baseline-page .continue-baseline-header .header-right .button-group {
  margin-top: var(--margin-sm);
  display: flex;
  align-items: center;
  justify-content: center;
}

table.table.continue-baseline-table>tbody>tr {
  cursor: pointer;
}

table.table.continue-baseline-table>tbody>tr:hover {
  background-color: var(--grey-light);
}

table.table.continue-baseline-table>tbody>tr:focus {
  background-color: var(--primary-highlight-color);
}

@media (max-width: 687px) {

  .continue-baseline-page .continue-baseline-header .header-left,
  .continue-baseline-page .continue-baseline-header .header-right {
    flex: 1 1 100%;
  }
}