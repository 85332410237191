:root {
  --drive-file-thumbnail-height: 150px;
}

.drive-resources .breadcrumb {
  background-color: transparent;
  z-index: 10;
}

.resources {
  padding-bottom: var(--padding-lg);
}

.resources-header {
  display: grid;
  grid-template-columns: 1fr 1.5fr 1fr;
  grid-template-rows: auto;
  grid-template-areas: "title search courses";
}

.resources-header>.resources-title {
  grid-area: title;
}

.resources-header>.resources-search {
  grid-area: search;
  display: flex;
  flex: 1;
  align-items: center;
}

.resources-header > .course-videos-link {
  grid-area: courses;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.drive-search-container {
  position: relative;
  background-color: var(--input-background-color);
  padding: var(--input-padding);
  padding-right: 30px;
  border: 1px solid var(--input-border-color);
  border-radius: var(--input-border-radius);
  display: flex;
  flex: 1;
  align-items: center;
  grid-area: search;
  transition: all 100ms linear;
  line-height: 1;
}

.drive-search-container:focus-within {
  border-color: var(--primary-color);
}

.drive-search-container>.fa.fa-magnifying-glass {
  margin-right: var(--margin-md);
  color: var(--input-border-color);
  transition: all 100ms linear;
}

.drive-search-container:focus-within>.fa.fa-magnifying-glass {
  color: var(--secondary-color);
}

.drive-search-container>input[type=search] {
  outline: none;
  background-color: transparent;
  border: none;
  box-shadow: none;
  width: 100%;
}

.drive-search-container>button.clear {
  position: absolute;
  right: var(--padding-sm);
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  background-color: var(--black-light);
  color: var(--white-light);
  border: none;
  border-radius: 50%;
  height: 18px;
  width: 18px;
  font-size: 10px;
  line-height: 1;
  cursor: pointer;
}

.resources .resource-header {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 600;
  padding: 5px 10px;
}

.resource-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.resource-list .resource {
  align-self: flex-end;
  width: 25%;
}

a.resource-link {
  display: block;
  text-decoration: none;
  color: var(--black-color);
  margin: 10px;
  border-radius: 4px;
  border: 1px solid var(--grey-light);
}

.resource-link:hover {
  box-shadow: 0 0 0 2px var(--primary-color);
}

.resource-link .file-view {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  overflow: hidden;
}

.file-view .file-details {
  display: flex;
  flex: 1 1 auto;
  align-items: center;
}

.file-view .file-details .file-icon {
  flex: 0 0 auto;
  padding: 12px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.file-view .file-thumbnail img {
  object-fit: cover;
  width: 100%;
  height: var(--drive-file-thumbnail-height);
}

.file-view .file-thumbnail-placeholder img {
  height: 30px;
  width: 30px;
}

.file-view .file-details .file-icon img {
  height: 18px;
  width: 18px;
}

.file-view .file-details .file-name {
  align-self: center;
  flex: 1 1 auto;
  font-weight: 500;
  overflow: hidden;
  padding-right: 12px;
  position: relative;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: -webkit-calc(100% - 56px);
  width: calc(100% - 56px);
}

.file-view .file-thumbnail-placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
  height: var(--drive-file-thumbnail-height);
  width: 100%;
}

@media (max-width: 991px) {
  .resource-list .resource {
    width: 50%;
  }

  .resources-header {
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
    grid-template-areas:
      "title courses"
      "search search";
    margin-bottom: 15px;
  }
}

@media (max-width: 479px) {
  .resource-list .resource {
    width: 100%;
  }
}