.panel {
  border-radius: var(--standard-border-radius);
}

.panel-group .panel {
  border-radius: 0;
}

.panel-group .panel:not(:last-child) {
  border-bottom: none;
}

.panel-group .panel + .panel {
  margin-top: 0;
}

.panel > .panel-heading {
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.panel > .panel-heading.collapse-toggle {
  cursor: pointer;
}

.panel > .panel-body {
  border-top: 2px solid transparent;
}

.panel > .panel-body.collapse:not(.in) {
  border-top: none;
}

.panel.panel-default {
  border-color: var(--standard-border-color);
}

.panel.panel-default > .panel-heading {
  color: var(--black-color);
  border-color: var(--standard-border-color);
}

.panel.panel-default > .panel-body {
  border-top-color: var(--standard-border-color);
}

.panel.panel-primary {
  border-color: var(--primary-color);
}

.panel.panel-primary > .panel-heading {
  color: var(--primary-color);
  border-color: var(--primary-color);
}

.panel.panel-primary > .panel-body {
  border-top-color: var(--primary-color);
}

.panel.panel-error {
  border-color: var(--error-color);
}

.panel.panel-error > .panel-heading {
  color: var(--error-color);
  border-color: var(--error-color);
}

.panel.panel-error > .panel-body {
  border-top-color: var(--error-color);
}