:root {
  --background-color: #fafafa;
  --foreground-color: #fff;

  --white-light: #ffffff;
  --white-base: #f6f6f6;
  --white-dark: #f0f0f0;

  --grey-light: #dadada;
  --grey-base: #acacac;
  --grey-dark: #606060;

  --black-light: #222425;
  --black-base: #1b1c1d;
  --black-dark: #000000;

  --blue-light: #5AB9F9;
  --blue-base: #2196F1;
  --blue-dark: #0461A0;

  --pink-light: #DB94E0;
  --pink-base: #DB64E0;
  --pink-dark: #aa34b3;

  --teal-light: #8AFFE3;
  --teal-base: #48F0CB;
  --teal-dark: #4aa590;

  --primary-color: var(--blue-base);
  --secondary-color: var(--pink-base);
  --tertiary-color: var(--teal-base);
  --black-color: var(--black-base);
  --white-color: var(--white-base);
  --grey-color: var(--grey-base);
  --error-color: #da0049;
  --warning-color: #f6a700;
  --success-color: #308f5d;
  --info-color: #2370ad;
  --flash-color: #FFFBCC;
  --font-color: #4b4b4b;

  --chart-1-color: #85293D;
  --chart-2-color: #C03A59;
  --chart-3-color: #D57389;
  --chart-4-color: #70D4BD;
  --chart-5-color: #33BFA0;
  --chart-6-color: #299A7F;

  --shadow-sm: 0px 5px 10px rgba(27, 28, 29, 0.045), 0px 0px 5px rgba(27, 28, 29, 0.035);
  --shadow-md: 0px 12px 30px rgba(27, 28, 29, 0.125), 0px 0px 5px rgba(27, 28, 29, 0.035);
  --shadow-lg: 0px 20px 65px rgba(27, 28, 29, 0.45), 0px 0px 5px rgba(27, 28, 29, 0.35);

  --horizontal-gradient: linear-gradient(90deg, var(--tertiary-color) 0%, var(--primary-color) 50%, var(--secondary-color) 100%);
  --vertical-gradient: linear-gradient(0deg, var(--secondary-color) 0%, var(--primary-color) 50%, var(--tertiary-color) 100%);

  --mask-color: rgba(255, 255, 255, 0.5);
  --dark-mask-color: rgba(255, 255, 255, 0.95);

  --primary-highlight-color: rgba(33, 150, 241, 0.2);
  --error-highlight-color: rgba(218, 0, 73, 0.2);
  --warning-highlight-color: rgba(246, 167, 0, 0.2);
  
  --maple-purple: #441f4b;
  --maple-red: #e47862;

}

body {
  color: var(--font-color);
  background-color: var(--background-color);
}

.medium {
  color: var(--black-light);
}

.light {
  color: var(--grey-color);
}

.primary {
  color: var(--primary-color);
}

.primary-dark {
  color: var(--blue-dark);
}

.yellow,
.warning {
  color: var(--warning-color);
}

.green,
.success {
  color: var(--success-color);
}

.red,
.error,
.danger {
  color: var(--error-color);
}

.bg-error,
.bg-red {
  background-color: var(--error-color);
}

.bg-warning,
.bg-yellow {
  background-color: var(--warning-color);
}

.bg-success,
.bg-green {
  background-color: var(--success-color);
}
