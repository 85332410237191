.tooltip-container {
  padding: 3px;
}

.tooltip-container .tooltip-content {
  border-radius: 6px;
  border: 1px solid var(--black-light);
  background-color: var(--black-dark);
  box-shadow: var(--shadow-sm);
  padding: 3px 10px;
  color: var(--white-color);
  font-size: 14px;
}

.tooltip-container .tooltip-content.primary {
  background-color: var(--primary-color);
  border-color: var(--blue-light);
}

.tooltip-container .tooltip-content.secondary {
  background-color: var(--secondary-color);
  border-color: var(--pink-light);
}

.tooltip-container .tooltip-content.tertiary {
  background-color: var(--tertiary-color);
  color: var(--black-color);
  border-color: var(--teal-light);
}