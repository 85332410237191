:root {

  --margin-xs: 3px;
  --margin-sm: 5px;
  --margin-md: 10px;
  --margin-lg: 15px;
  --margin-xl: 20px;
  
  --padding-xs: 3px;
  --padding-sm: 5px;
  --padding-md: 10px;
  --padding-lg: 15px;
  --padding-xl: 20px;

}

html,
body,
#root {
  height: 100%;
  width: 100%;
}

* {
  box-sizing: border-box;
}
