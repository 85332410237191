.tag {
  font-size: 12px;
  line-height: 1;
  font-weight: 600;
  margin-bottom: 5px;
  margin-top: 5px;
  padding: 3px 5px;
  border-radius: 4px;
}

.tag.primary {
  color: #fff;
  background-color: var(--primary-color);
}

.tag.dark {
  color: #fff;
  background-color: var(--black-color);
}

.tag.light {
  color: var(--primary-color);
  background-color: var(--white-dark);
}

.tag.danger {
  color: #fff;
  background-color: var(--error-color);
}

.tag.warning {
  color: #fff;
  background-color: var(--warning-color);
}

.tag.success {
  color: #fff;
  background-color: var(--success-color);
}

.tag-group {
  display: flex;
  flex-wrap: wrap;
}

td > .tag-group > .tag {
  margin-bottom: 0px;
}

.tag-group .tag:not(:last-child) {
  margin-right: 5px;
  white-space: nowrap;
}
