.password-reset-request h1,
.password-reset h1 {
  text-align: center;
}

.password-reset-request-success {
  overflow: hidden;
}

.password-reset-request-success a {
  @extend .caption;
  float: right;
}

form.password-reset-request-form .form-footer {
  display: flex;
  align-items: center;
}

form.password-reset-request-form .form-footer>a {
  @extend .caption;
  flex: 1;
  margin-right: var(--margin-lg);
  text-align: right;
}