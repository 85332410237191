:root {
  --popover-box-shadow: var(--shadow-md);
}

.popover-container {
  z-index: 1000;
}

.popover-container .popover-content {
  border-radius: 4px;
  background-color: #fff;
  min-height: 10px;
  overflow: scroll;
  border: 1px solid var(--grey-light);
  padding: 0;
  box-shadow: var(--popover-box-shadow);
  font-size: var(--semi-small-font-size);
  line-height: var(--semi-small-line-height);
}