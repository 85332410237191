@media print {

  .voms-test-display button.help-button {
    display: none;
  }

  .voms-test-display table > tbody > tr > td {
    break-inside: avoid;
  }

}
