.quick-actions>h3 {
  @extend .caption;
  font-size: 14px;
  margin: 0;
  line-height: var(--body-line-height);
  color: var(--black-light);
}

.quick-actions>.quick-actions-content {
  padding: var(--padding-lg);
}

.quick-actions>.quick-actions-content>ul>li {
  margin-bottom: var(--margin-lg);
}

.quick-actions>.quick-actions-content>ul>li .btn {
  min-width: 250px;
}