:root {
  --otp-input-font-size: 20px;
  --otp-input-size: 3.75rem;
  --otp-caret-size: calc(var(--otp-input-font-size) + 6px);
}

.otp-input-container .otp-inputs {
  display: flex;
  align-items: stretch;
  flex-direction: row;
  gap: var(--padding-sm);
}

.otp-input-container .otp-inputs .otp-input {
  position: relative;
  width: var(--otp-input-size);
  height: var(--otp-input-size);
  font-size: var(--otp-input-font-size);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s linear;
  border: 2px solid var(--input-border-color);
  border-radius: var(--radius-lg);
  box-shadow: none;
}

.otp-input-container .otp-inputs .otp-input[data-focus="true"] {
  border-color: var(--primary-color);
  box-shadow: var(--input-box-shadow);
}

@keyframes caret-blink {
  0%, 70%, 100% { opacity: 1; }
  20%,50% { opacity: 0; }
}

.otp-input-container .otp-inputs .otp-input .fake-caret {
  position: absolute;
  pointer-events: none;
  display: flex; 
  align-items: center;
  justify-content: center;
  animation: caret-blink 1s ease-out infinite;
}

.otp-input-container .otp-inputs .otp-input .fake-caret .cursor {
  width: 1px;
  height: var(--otp-caret-size);
  background-color: var(--grey-dark);
}