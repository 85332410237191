.error-boundary {
  height: 100%;
  max-width: 620px;
  margin-left: auto;
  margin-right: auto;
  padding: var(--padding-lg);
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.error-boundary>.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: var(--margin-lg);
}

/*
 * Sentry overrides. !important is needed as styles are defined
 * in <style> tag and rendered below the css include tag.
 */
.sentry-error-embed-wrapper .sentry-error-embed * {
  font-family: var(--primary-font-family) !important;
}

.sentry-error-embed h2,
.sentry-error-embed header p,
.sentry-error-embed form .form-content {
  color: var(--black-color) !important;
}

.sentry-error-embed form .form-content .form-field input,
.sentry-error-embed form .form-content .form-field textarea {
  border-color: var(--input-border-color) !important;
  border-radius: var(--input-border-radius) !important;
  border-width: var(--input-border-width) !important;
  padding: var(--input-padding) !important;
  background-color: var(--input-background-color) !important;
}

.sentry-error-embed form .form-content .form-field textarea {
  max-width: 100% !important;
  min-width: 100% !important;
  min-height: 34px !important;
}

.sentry-error-embed form .form-submit button.btn {
  background-color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.sentry-error-embed form .form-submit button.close {
  color: var(--black-color) !important;
  opacity: unset;
}