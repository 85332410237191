.rdrDateDisplayWrapper {
  background-color: var(--foreground-color);
}

.rdrDateDisplayItem {
  box-shadow: none;
}

.rdrDateDisplay {
  margin: 0 0.833em;
}

.rdrMonthAndYearWrapper {
  height: auto;
}

.rdrMonthAndYearPickers select {
  padding: 5px 30px 5px 10px;
}

.rdrDay,
.rdrStaticRange {
  margin-left: 0px !important;
}

.rdrInputRanges:empty {
  display: none;
}

.rdrStaticRange.rdrStaticRangeSelected .rdrStaticRangeLabel {
  background: var(--white-dark);
}

.date-range-menu {
  border-radius: var(--dropdown-menu-border-radius);
  background-color: var(--background-color);
  box-shadow: var(--dropdown-box-shadow);
  display: inline-flex;
}

.date-range-menu .rdrDefinedRangesWrapper {
  width: 180px;
}

.date-range-menu .date-range-footer {
  display: flex; 
  justify-content: flex-end;
  align-items: center;
  border-top: 1px solid var(--white-dark);
  padding: 5px 10px 0 10px;
}

.date-range-menu .date-range-footer button.btn {
  margin-left: 10px;
}

.date-range-view {
  font-size: 14px;
}

.date-range-view > * {
  margin-right: 5px;
}
