.mobile-overlay {
  position: fixed;
  transform: translateY(100%);
  bottom: 0;
  left: 0;
  right: 0;
  transition: transform 0.4s ease-in-out;
  background-color: #fff;
  z-index: 1010;
  height: 100%;
}

.mobile-overlay.opened {
  transform: translateY(0);
  display: block;
  top: 0;
}

.mobile-overlay .mobile-app-inner {
  height: 0;
  overflow: hidden;
}

.mobile-overlay.opened .mobile-app-inner {
  height: 100%;
  position: relative;
  padding: var(--padding-sm) var(--padding-lg);
}

.mobile-overlay.opened .mobile-app-inner .continue {
  position: absolute;
  top: 0;
  right: 0;
  margin: var(--margin-sm) var(--margin-lg);
}

.mobile-overlay.opened .mobile-app-inner .continue .edit-button {
  text-decoration: underline;
}

.mobile-overlay.opened .mobile-app-inner .mobile-app-header {
  margin-top: 100px;
}

.mobile-overlay.opened .mobile-app-inner .mobile-app-header .brand {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: calc(var(--margin-lg) * 2);
}

.mobile-overlay.opened .mobile-app-inner .mobile-app-header .brand>img {
  width: 70%;
}

.mobile-overlay.opened .mobile-app-inner .app-download-links {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: calc(var(--margin-lg) * 2);
}

.app-download-links .app-link.ios img {
  width: 135px;
  height: 40px;
}

.app-download-links .app-link.android img {
  width: 160px;
  height: 60px;
}