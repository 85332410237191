:root {
  --score-view-score-font-size: var(--h2-font-size);
  --score-view-score-line-height: var(--h2-line-height);
}

.score-view {
  display: flex;
  flex: 1;
  align-items: baseline;
  flex-basis: 100%;
}

.score-view>label {
  text-align: right;
  width: 25%;
  margin: var(--margin-sm);
  @extend .caption;
  font-size: var(--semi-small-font-size);
}

.score-view-score {
  flex: 0;
  color: var(--primary-color);
  font-size: var(--score-view-score-font-size);
  line-height: var(--score-view-score-line-height);
  font-weight: bold;
  padding-left: var(--padding-lg);
}