.drawer {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1000;
}

.drawer .drawer-content {
  position: fixed;
  z-index: 1001;
  transition: transform 200ms ease-in-out;
}

.drawer .drawer-content.left {
  left: 0;
  transform: translateX(-100%);
}

.drawer .drawer-content.right {
  right: 0;
  transform: translateX(100%);
}

.drawer .drawer-content.top {
  top: 0;
  transform: translateY(-100%);
}

.drawer .drawer-content.bottom {
  bottom: 0;
  transform: translateY(100%);
}

.drawer .drawer-content.left,
.drawer .drawer-content.right {
  top: 0;
  bottom: 0;
}

.drawer .drawer-content.top,
.drawer .drawer-content.bottom {
  right: 0;
  left: 0;
}

.drawer .drawer-content.fade {
  opacity: 0;
  transition: opacity 0.3s linear;
}

.drawer.open .drawer-content.fade {
  opacity: 1;
}

.drawer.open .drawer-content.left,
.drawer.open .drawer-content.right {
  transform: translateX(0);
}

.drawer.open .drawer-content.top,
.drawer.open .drawer-content.bottom {
  transform: translateY(0);
}

.drawer.open .drawer-content .drawer-close {
  position: absolute;
  top: 0;
}

.drawer.open .drawer-content .drawer-close button {
  padding: var(--padding-lg);
}

.drawer.open .drawer-content .drawer-close.large button {
  font-size: 26px;
}

.drawer.open .drawer-content .drawer-close.xlarge button {
  font-size: 32px;
}

.drawer.open .drawer-content.left .drawer-close,
.drawer.open .drawer-content.bottom .drawer-close {
  right: 0
}

.drawer.open .drawer-content.right .drawer-close {
  left: 0
}