@import './grid';
@import './page';
@import './typography';
@import './panels';
@import './user-baseline-detail';
@import './symptoms-list.css';
@import './voms';
@import './user-profile';
@import './blackhawks';
@import './soap-notes';
@import './post-injury-assessment-display';
@import './injury-reports';
@import './print-meta.css';
@import './clinic-deactivated-banner';
@import './invoices';

.print-only {
  display: none;
  visibility: hidden;
}

@media print {
  .print-only {
    display: block;
    visibility: visible;
  }

  button.btn,
  .no-print,
  .breadcrumb {
    display: none;
  }

  .no-print > * {
    visibility: hidden;
  }
}
