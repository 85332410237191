:root {

  --dashboard-card-background-color: var(--background-color);
  --dashboard-card-icon-color: var(--foreground-color);

  --dashboard-card-circle-diameter: 102px;
  --dashboard-card-circle-border-width: 12px;

  --dashboard-card-icon-font-size: 36px;

}

.dashboard-content {
  padding-top: var(--padding-lg);
}

/* *************** */
/* DASHBOARD CARDS */
/* *************** */

.dashboard-card {
  width: 100%;
  margin-bottom: var(--margin-lg);
  background-color: var(--dashboard-card-background-color);
}

.dashboard-card .dashboard-card-header {
  display: flex;
  height: calc(var(--dashboard-card-circle-diameter) / 2);
  z-index: 1;
}

.dashboard-card .dashboard-card-header .dashboard-card-badge {
  flex: 1;
  display: flex;
  align-items: flex-end;
}

.dashboard-card .dashboard-card-header .dashboard-card-badge:last-child {
  justify-content: flex-end;
}

.dashboard-card .dashboard-card-header .dashboard-card-badge a {
  @extend .caption;
}

.dashboard-card .dashboard-card-header .dashboard-card-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--dashboard-card-circle-diameter);
  height: var(--dashboard-card-circle-diameter);
  border-width: var(--dashboard-card-circle-border-width);
  border-radius: 50%;
  border-style: solid;
  border-color: var(--dashboard-card-background-color);
  background-color: var(--primary-color);
  z-index: 1;
}

.dashboard-card.axia-dashboard-card .dashboard-card-header .dashboard-card-circle {
  background-color: var(--white-light);
}

.dashboard-card.axia-dashboard-card .dashboard-card-header .dashboard-card-circle .axia-logo-container {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  border: 2px solid var(--primary-color);
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboard-card.axia-dashboard-card .dashboard-card-header .dashboard-card-circle .axia-logo-container .axia-logo {
  object-fit: cover;
  object-position: left;
  width: 50px;
  height: 50px;
}

.dashboard-card .dashboard-card-header .dashboard-card-circle .fa {
  font-size: 36px;
  color: var(--dashboard-card-icon-color);
}

.dashboard-card .dashboard-card-content {
  position: relative;
  height: 160px;
  padding: var(--padding-lg);
  padding-top: calc(var(--dashboard-card-circle-diameter) / 2);
  border-width: var(--standard-border-width);
  border-bottom-width: 0;
  border-style: var(--standard-border-style);
  border-color: var(--grey-light);
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
  background-color: var(--foreground-color);
}

.dashboard-card .dashboard-card-content .dashboard-card-badge {
  position: absolute;
  top: var(--margin-lg);
  right: var(--margin-lg);
}

.dashboard-card .dashboard-card-content h1 {
  margin: 0;
  margin-bottom: var(--margin-lg);
  text-align: center;
  color: var(--black-color);
  font-size: 28px;
}

.dashboard-card .dashboard-card-content p {
  margin: 0;
  text-align: center;
  color: var(--grey-dark);
}

.dashboard-card .dashboard-card-footer {
  display: flex;
  justify-content: center;
  padding: var(--padding-lg);
  padding-top: 0;
  border-width: var(--standard-border-width);
  border-top-width: 0;
  border-style: var(--standard-border-style);
  border-color: var(--grey-light);
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
  background-color: var(--foreground-color);
}

.dashboard-card .dashboard-card-footer button {
  width: 75%;
}

/* DASHBOARD PREVIEW */

.dashboard-preview .preview-content {
  display: flex;
  flex-wrap: wrap;
  width: 460px;
  padding: var(--padding-sm);
}

.dashboard-preview .preview-content .dashboard-item {
  flex: 1 0 33.3%;
  flex-direction: column;
  width: 33.3%;
  max-width: 33.3%;
  padding: 5px;
}

.dashboard-preview .preview-content .dashboard-item a.dashboard-link {
  /* height: 122px; */
  width: 100%;
  border: 1px solid var(--white-dark);
  border-radius: var(--radius-md);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  padding: var(--padding-sm);
}

.dashboard-preview .preview-content .dashboard-item a.dashboard-link:hover,
.dashboard-preview .preview-content .dashboard-item a.dashboard-link.active {
  border-color: var(--primary-color);
}

.dashboard-preview .preview-content .dashboard-item a.dashboard-link:hover,
.dashboard-preview .preview-content .dashboard-item a.dashboard-link:active {
  background-color: var(--white-dark);
}

.dashboard-preview .preview-content .dashboard-item a.dashboard-link.active {
  background-color: var(--white-base);
}

.dashboard-preview .preview-content .dashboard-item a.dashboard-link .icon {
  font-size: 22px;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-color);
  color: #fff;
  margin-bottom: 10px;
}

.dashboard-preview .preview-content .dashboard-item a.dashboard-link .title {
  padding-bottom: 10px;
  font-size: 14px;
  font-family: var(--heading-font-family);
}

.dashboard-preview .preview-footer {
  width: 100%;
  background-color: var(--white-dark);
  padding: 10px;
}

.dashboard-preview .preview-footer .btn {
  width: 100%;
  margin-bottom: 0;
  font-size: 12px;
  font-weight: 600;
  font-family: var(--heading-font-family);
  text-transform: uppercase;
}

@media screen and (max-width: 550px) {
  .dashboard-preview .preview-content {
    width: 100vw;
  }
}