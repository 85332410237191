:root {
  --injury-stage-image-dimension: 75px;
}

.injury-stage-view {
  display: flex;
  padding: var(--padding-lg);
}

.injury-stage-view>.stage-view-image {
  padding-right: var(--padding-lg);
  display: flex;
  justify-content: center;
  align-items: center;
}

.injury-stage-view>.stage-view-image>img {
  width: var(--injury-stage-image-dimension);
  height: var(--injury-stage-image-dimension);
  border-radius: var(--standard-border-radius);
}

.injury-stage-view .stage-view-content>label,
.injury-stage-view .stage-view-content>span {
  margin-right: var(--margin-sm);
}