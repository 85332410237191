form.clinic-search-form.compact {
  display: flex;
  align-items: flex-end;
}

form.clinic-search-form.compact > .search-input-group {
  flex: 1;
  margin-bottom: 0;
}

form.clinic-search-form.compact > .search-input-group > label {
  margin: 0;
}

form.clinic-search-form.compact > .search-input-group input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

form.clinic-search-form.compact > button {
  /* height: 34px; matching Bootstrap .form-control height */
  padding: var(--input-padding);
  margin: 0;
  border-radius: 0;
  font-size: var(--input-font-size);
  font-weight: var(--input-font-weight);
  line-height: var(--input-line-height);
}

form.clinic-search-form.compact > button:last-of-type {
  border-top-right-radius: var(--input-border-radius);
  border-bottom-right-radius: var(--input-border-radius);
}
