:root {

  --radius-xs: 1px;
  --radius-sm: 3px;
  --radius-md: 6px;
  --radius-lg: 8px;
  --radius-xl: 10px;

  --standard-border-width: 1px;
  --standard-border-radius: var(--radius-sm);
  --standard-border-style: solid;
  --standard-border-color: var(--grey-color);
  --light-border-color: var(--white-dark);

  
}

hr.brand-separator {
  width: 100px;
  height: 2px;
  margin: 0;
  background: var(--horizontal-gradient);
}
