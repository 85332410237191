.voms-test-header {
  margin-bottom: var(--margin-lg);
}

.voms-test-form input[type="checkbox"] {
  margin: 0;
  padding: 0;
  margin-top: 10px;
}

.voms-test-form select,
.voms-test-form input[type="number"] {
  min-width: 70px;
}

.voms-test-form textarea {
  min-width: 120px;
}

.voms-test-form .radio-group-container {
  flex-wrap: wrap;
}

.voms-test-form .radio-group-container .radio-input-group {
  width: 100%;
}

.voms-test-form .radio-group-container .radio-input-group:not(:last-child) {
  margin-right: 0;
}

.voms-test-form .radio-input-group label {
  font-size: var(--body-font-size);
  line-height: var(--body-line-height);
}

.voms-test-form .radio-input-group label>span {
  flex: 1;
}

.voms-test-form table>tbody>tr>td:first-child,
.voms-test-display table>tbody>tr>td:first-child {
  white-space: nowrap;
}

.voms-test-form .table>thead>tr>th {
  padding-top: calc(var(--padding-sm) * 2);
  background-color: var(--background-color);
}

.voms-test-display .test-header {
  margin-bottom: calc(var(--margin-lg) * 2);
}
