.user-baselines {
  display: flex;
  height: 100%;
}

.user-baselines .user-baselines-container {
  flex: 1;
  padding-right: var(--padding-xl);
}

.user-baselines .user-baselines-container .archived-baselines-header {
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--grey-light);
  margin-bottom: var(--margin-md);
}

.user-baselines .user-baselines-container ul.user-baselines-list {
  margin: 0;
  list-style: none;
  padding: 0;
}

.user-baselines .user-baselines-container ul.user-baselines-list > li {
  border: 1px solid var(--grey-base);
  background-color: var(--white-dark);
}

.user-baselines .user-baselines-container ul.user-baselines-list > li:not(:first-child) {
  border-top: none;
}

.user-baselines .user-baselines-container ul.user-baselines-list .baseline-list-item {
  padding: var(--padding-md) var(--padding-lg);
}

.user-baselines .user-baselines-container ul.user-baselines-list .baseline-list-item .baseline-details {
  display: flex;
}

.baseline-list-item .baseline-details .baseline-header {
  display: flex;
  align-items: center;
  margin-bottom: var(--margin-sm);
  flex: 1;
}

.baseline-list-item .baseline-details .baseline-header h3 {
  margin: 0;
}

.baseline-list-item .baseline-details .baseline-header .label {
  margin-left: var(--margin-md);
}

.baseline-list-item .performed-by {
  font-size: var(--semi-small-font-size);
}

.baseline-list-item .baseline-details .baseline-actions .baseline-actions-button {
  border: 1px solid var(--grey-base);
  border-radius: 50%;
  background-color: var(--grey-light);
  padding: 0;
  margin: 0;
  height: 30px;
  width: 30px;
  transition: all 0.2s linear;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.baseline-list-item .baseline-details .baseline-actions .baseline-actions-button.selected {
  background-color: var(--black-color);
  color: var(--white-color);
}

.baseline-list-item .baseline-details .baseline-actions .baseline-actions-button:not(.selected):hover {
  background-color: var(--grey-base);
} 

.baseline-list-item .archived-reason {
  margin-top: var(--margin-md);
  font-size: var(--semi-small-font-size);
}

.baseline-list-item .archived-reason .reason {
  white-space: pre-wrap;
}