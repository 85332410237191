.im-info-section {
  display: flex;
  justify-content: space-between;
  flex: 1;
  flex-basis: 100%;
}

.im-info-section>div {
  max-width: calc(50% - var(--margin-sm));
}

.immediate-memory-score {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex: 1;
  flex-basis: 100%;
}

.im-word-count {
  flex: 1;
  padding-left: var(--padding-lg);
  padding-right: var(--padding-lg);
}

.im-content {
  flex: 1;
  flex-basis: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.im-content-left,
.im-trials {
  flex: 1;
  padding: var(--padding-lg);
}

.im-word-list {
  text-align: center;
}

.im-word-list>ul {
  padding: 0;
  list-style: none;
}

.im-word-list>ul>li {
  font-weight: var(--bold-font-weight);
}