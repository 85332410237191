.tandem-gait-test table > tbody > tr > td {
  vertical-align: middle;
}

.tandem-gait-timer-input {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tandem-gait-timer-input .timer-controls {
  margin-left: 10px;
}

.tandem-gait-timer-input .timer-controls .btn {
  margin-bottom: 0;
}

.tandem-gait-timer-input .form-input.form-control {
  text-align: center;
  min-width: 60px;
  width: auto;
}

.tandem-gait-timer-input .form-input.form-control,
.tandem-gait-timer-input .input-group .input-group-addon {
  padding-top: 5px;
  padding-bottom: 5px;
}

.tandem-gait-timer-input .input-group .input-group-addon {
  font-size: 10px;
  padding-right: 5px;
  padding-left: 5px;
  background-color: var(--white-base);
}

.tandem-gait-timer-input .form-input.form-control:not(:disabled) {
  background-color: var(--white-light);
}
