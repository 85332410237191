.specialist-notes-list .specialist-notes-list-header {
  margin-bottom: var(--margin-lg);
  border-bottom-width: var(--standard-border-width);
  border-bottom-style: var(--standard-border-style);
  border-bottom-color: var(--standard-border-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.page.specialist-notes .card-container .card-container-body {
  padding-left: var(--padding-lg);
  padding-right: var(--padding-lg);
}

.page.specialist-notes .card-container .card-container-body .add-symptoms-view {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.specialist-note-panel .panel-heading.collapse-toggle {
  background-color: var(--white-dark);
  z-index: 1
}

.specialist-note-panel .panel-heading.opened {
  background-color: var(--grey-light);
}

.specialist-note-panel>.panel-heading.sticky {
  position: sticky;
  top: var(--navigation-bar-height);
}

.specialist-note-panel .panel-header-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  min-height: 57px;
}

.specialist-note-panel .panel-header-container .header-row {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
}

.specialist-note-panel .panel-header-container .header-row .header-title {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.specialist-note-panel .panel-header-container .header-row .header-title h2 {
  margin-top: 0;
  margin-bottom: 0;
}

.specialist-note-panel .panel-header-container .header-row .header-title .fa {
  font-size: 16px;
  margin-right: 10px;
}

.specialist-note-panel .panel-header-container .panel-header-test-badge {
  margin-left: 10px;
}

.specialist-note-display h2 {
  border-bottom: 1px solid var(--grey-color);
}

.specialist-note-display .symptom-scores .symptom-score-row {
  padding-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.specialist-note-display .symptom-scores .score-view {
  text-transform: uppercase;
}