@media print {

  .injury-report-display main>.row>.col-md-8 {
    width: 100%;
  }

  .injury-report-display main>.row>.col-md-4,
  .injury-report-display .tabs-bar,
  .injury-report-display .edit-button,
  .btn-start-assessment {
    display: none;
  }

  .injury-report-display .patient-meta {
    page-break-inside: avoid;
    margin-bottom: var(--margin-lg);
  }

}