@media print {

  body {
    padding: 0;
    margin: 0;
  }

  .page,
  .page.nav-visible {
    padding: 0;
    width: 100%;
  }

  .page header,
  .page footer {
    display: none;
  }

  .page main > .row {
    margin: 0;
  }

  .btn-print {
    display: none;
  }

  /* Override bootstraps a[href]:after { content: " (" attr(href) ")" } 
   * that shows the path after the link text
   */
  a[href]:after {
    content: none;
  }

  table {
    display: block;
  }

  .popover-container {
    display: none;
  }
}
