:root {
  --courses-list-item-img-height: 200px;
  --courses-list-item-width: 330px;
}

/* Courses List */
.courses-list {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}

.courses-list .courses-list-item {
  flex: 1;
  max-width: var(--courses-list-item-width);
  min-width: var(--courses-list-item-width);
  margin-right: var(--margin-lg);
  margin-bottom: var(--margin-lg);
} 

.courses-list .courses-list-item a {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  width: 100%;
  border-radius: var(--radius-lg);
  border: 1px solid var(--grey-light);
  height: 100%;
  color: inherit;
  overflow: hidden;
}

.courses-list .courses-list-item a:hover {
  border-color: var(--primary-color);
  box-shadow: var(--shadow-sm);
}

.courses-list .courses-list-item a .course-img {
  width: 100%;
  height: var(--courses-list-item-img-height);
  border-bottom: 1px solid var(--grey-light);
}

.courses-list .courses-list-item a .course-img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.courses-list .courses-list-item a .course-details {
  padding: var(--padding-lg) var(--padding-md);
  display: flex;
  height: calc(100% - var(--courses-list-item-img-height));
  flex-direction: column;
  background-color: var(--white-base);
  justify-content: space-between;
  overflow: hidden;
}

.courses-list .courses-list-item a:hover .course-details {
  background-color: var(--white-dark);
}

.courses-list .courses-list-item a .course-details .title {
  margin-bottom: var(--margin-sm);
  font-family: var(--heading-font-family);
  font-weight: bold;
  text-transform: uppercase;
  font-size: var(--semi-small-font-size);
  line-height: 1.2;
}

/* Course Videos */
.course-videos {
  display: flex;
  height: 100%;
  padding-top: var(--padding-lg);
  padding-bottom: 200px;
}

.course-module-sections {
  flex: 1;
  padding-right: var(--padding-lg);
}

.course-module-sections .module-section {
  padding: var(--padding-md) 0;
}

.course-module-sections .module-section .title {
  color: inherit;
  font-size: 20px;
  font-weight: var(--semi-bold-font-weight);
  margin-bottom: var(--margin-lg);
  border-bottom: 1px solid var(--white-dark);
  padding-bottom: var(--padding-xs);
}

.module-scroll-spy {
  height: 100%;
  top: 90px;
  position: sticky;
  width: 340px;
  border-left: 1px solid var(--grey-light);
}

.module-scroll-spy ul > li {
  padding: var(--padding-sm) 0;
}

.module-scroll-spy a {
  display: block;
  line-height: 1.2;
  text-decoration: none;
  color: var(--grey-dark);
  font-size: var(--semi-small-font-size);
  cursor: pointer;
  padding-left: var(--padding-lg);
  border-left: 3px solid transparent;
  transition: all 0.15s linear;
}

.module-scroll-spy a:hover {
  border-left-color: var(--grey-light);
  color: var(--black-dark);
}

.module-scroll-spy a.active {
  font-weight: bolder;
  color: var(--primary-color);
  border-left-color: var(--primary-color);
}

@media (max-width: 992px) {
  .module-scroll-spy {
    display: none;
  }
}

/* Module Videos */
.module-section .videos-list {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}

.module-section .videos-list .module-video {
  padding: var(--padding-md);
  width: 50%;
}

@media (max-width: 637px) {
  .module-section .videos-list .module-video {
    width: 100%;
  }
}

.vimeo-video {
  padding-bottom: var(--padding-md);
}

.vimeo-video .video-container {
  padding: 62.5% 0 0 0;
  position: relative;
  margin-bottom: var(--padding-lg);
}

.vimeo-video .video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}