.baseline-step-wizard {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.baseline-step-wizard-side-bar {
  flex: 1;
  max-width: calc(25% - var(--margin-lg));
}

.baseline-step-wizard-test {
  flex: 2;
  max-width: calc(75% - var(--margin-lg));
  margin-bottom: var(--margin-lg);
}

.baseline-step-wizard.steps-hidden .baseline-step-wizard-test {
  max-width: 100%;
}

.baseline-player-badge-row {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: baseline;
}

.baseline-player-badge-row > .skip-button {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin-left: var(--margin-lg);
}

@media (max-width: 768px) {
  .baseline-step-wizard-side-bar {
    display: none;
  }

  .baseline-step-wizard-test {
    max-width: 100%;
  }

}

