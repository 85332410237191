@media print {
  .soap-notes-list-view .soap-notes-list-section,
  .soap-notes-list {
    width: 100% !important;
  }

  .soap-notes-display main>.row>.col-md-8 {
    width: 100%;
  }

  .soap-notes-display main>.row>.col-md-4,
  .soap-notes-display .tabs-bar {
    display: none;
  }

  .soap-notes-display .patient-meta {
    page-break-inside: avoid;
    margin-bottom: var(--margin-lg);
    page-break-before: avoid;
  }

  .soap-notes-display .card-container {
    page-break-inside: avoid;
  }

  .soap-notes-display .card-container>.card-container-header {
    padding-bottom: var(--padding-sm);
    margin-bottom: var(--margin-lg);
    border-bottom-width: var(--standard-border-width);
    border-bottom-style: var(--standard-border-style);
    border-bottom-color: var(--standard-border-color);
  }

  .soap-notes-display .card-container>.card-container-header h2 {
    color: var(--black-color);
    top: 0;
    padding: 0;
    margin: 0;
  }

  .soap-notes-display .card-container>.card-container-body {
    padding: 0;
    border-width: 0;
  }

  .soap-notes-display .card {
    padding: 0;
  }

  .soap-notes-display .card p {
    padding-bottom: var(--margin-lg);
  }

  .soap-notes-display .card.card-show .card-row label {
    text-align: left;
  }

  .soap-notes-display .soap-notes-header {
    border-bottom: none;
  }

  .soap-notes-display .soap-symptom-display>div>div>div {
    padding-bottom: 0;
  }

  .soap-panels .panel {
    page-break-before: always;
  }

  .soap-panels .panel .panel-body {
    padding: 0;
  }

  .soap-panels .panel .panel-body .soap-content .soap-symptom-display.has-symptoms {
    page-break-after: always;
  }

  .soap-panels .panel .panel-body .soap-content .stage-view-image {
    display: none;
  }

  .soap-panels .panel.soap-panel-collapsed {
    display: none;
  }

  a.edit-button:not(.no-print) {
    display: block;
    color: var(--black-color);
  }

  .soap-panels .panel>.panel-heading.sticky {
    position: relative;
    top: 0 !important;
  }

  .soap-panels .soap-panel-header {
    font-weight: bold;
    font-size: var(--h1-font-size);
    line-height: var(--h1-line-height);
  }

  .soap-form-display .soap-note-test {
    page-break-inside: avoid;
  }

}