.payment-fail h1,
.payment-fail h2 {
  color: var(--error-color);
  margin: auto;
  margin-bottom: var(--margin-lg);
  margin-top: var(--margin-lg);
  text-align: center;
}

.payment-success h1,
.payment-success h2 {
  margin-bottom: var(--margin-lg);
  margin-top: var(--margin-lg);
  text-align: center;
}

.payment-fail table,
.payment-success table {
  margin: auto;
  margin-bottom: var(--margin-lg);
  text-align: center;
}

.payment-success .fa,
.payment-fail .fa {
  padding-right: var(--padding-sm);
}

.payment-fail button,
.payment-success button {
  margin-top: var(--margin-lg);
}

.recieve-payment-drawer {
  width: 450px;
  background-color: var(--foreground-color);
  height: 100%;
  padding: var(--padding-lg);
  display: flex;
  flex-direction: column;
  justify-content: center;
}


/** Payment Banner **/
.invoice-payment-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 15px 0;
  border-top: 1px solid var(--grey-light);
  background-color: var(--foreground-color);
}

.invoice-payment-banner .container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.invoice-payment-banner .container .payment-balance {
  padding-right: 20px;
}

.invoice-payment-banner .container .payment-balance h4 {
  margin-top: 0;
}

/* Payment Popover */
.popover-container.payments-popover {
  overflow: hidden;
}

.payments-popover .payments-content {
  position: relative;
  width: 340px;
  background-color: var(--background-color);
}

.payments-popover .payments-content .content-header {
  position: sticky;
  top: 0;
  padding: var(--padding-sm);
  border-bottom: 1px solid var(--grey-color);
  background-color: var(--background-color);
  color: var(--primary-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.payments-popover .payments-content .content-header>h3 {
  margin: 0;
}

.payments-popover .payments-content .payment-details .details-header {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding: var(--padding-md);
}

.payments-popover .payments-content .payment-details.expanded .details-header .fa {
  transform: rotate(90deg);
}

.payments-popover .payments-content .payment-details.expanded .details-header,
.payments-popover .payments-content .payment-details .details-header:hover {
  background-color: var(--primary-highlight-color);
}

.payments-popover .payments-content .payment-details .details {
  height: 0;
  overflow: hidden;
  visibility: hidden;
}

.payments-popover .payments-content .payment-details.expanded .details {
  height: auto;
  overflow: visible;
  visibility: visible;
  padding: var(--padding-md);
}

.payments-popover .payments-content .payment-details.expanded:not(:last-child) .details {
  border-bottom: 1px solid var(--primary-highlight-color);
}

.payment-form button.btn[type=submit] {
  width: 100%;
  padding: 8px;
  margin-top: var(--margin-lg);
  margin-bottom: var(--margin-md);
}

.select-amount-modal .radio-group-container {
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.select-amount-modal .radio-group-container>label {
  font-weight: 600;
  margin-bottom: 10px;
}

.select-amount-modal .radio-group-container .radio-input-group {
  width: 100%;
}

.select-amount-modal .radio-group-container .radio-input-group>label {
  padding: 10px 15px;
  border: 1px solid var(--grey-color);
  border-radius: 4px;
  margin-bottom: 10px;
}

.select-amount-modal .radio-group-container .radio-input-group>label:hover {
  border-color: var(--primary-color);
}

.payment-status-view {
  text-align: center;
}

.payment-status-view .payment-status-icon {
  font-size: 50px;
  margin-bottom: 15px;
}

.payment-status-view .action-group {
  display: flex;
  justify-content: center;
  align-items: center;
}

.payment-amount-label {
  margin-bottom: var(--margin-lg);
}

.payment-amount-label .payment-amount-value {
  font-weight: bold;
  font-size: 28px;
  margin-top: var(--padding-sm);
}

.payment-amount-label .payment-amount-value>button {
  margin-left: var(--margin-md);
}