/************************/
/* MFA Profile Tab View */
/************************/

.mfa-profile-tab-view {
  margin-top: var(--margin-md);
  background-color: var(--foreground-color);
  padding: var(--padding-lg);
  border-radius: var(--radius-md);
}

.mfa-profile-tab-view .mfa-profile-tab-view-header {
  border-bottom: 1px solid var(--white-dark);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: var(--padding-md);
  margin-bottom: var(--margin-lg);
}

.mfa-profile-tab-view .mfa-profile-tab-view-header .mfa-profile-tab-view-title {
  font-weight: 700;
  font-size: var(--h3-font-size);
}

.mfa-profile-tab-view .mfa-profile-tab-view-header .mfa-status.verified .fa {
  color: var(--success-color);
}

.mfa-profile-tab-view .mfa-profile-tab-view-header .mfa-status.disabled .fa {
  color: var(--error-color);
}

.mfa-profile-tab-view .mfa-profile-tab-view-header .mfa-status.pending .fa {
  color: var(--warning-color);
}

/*******************/
/* MFA Method Card */
/*******************/

.mfa-method-card {
  position: relative;
  overflow-x: hidden;
  border: 1px solid var(--white-dark);
  border-radius: var(--radius-md);
  padding: var(--padding-lg) var(--padding-xl);
  background-color: var(--white-light);
}

.mfa-method-card .mfa-method-card-inner {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  gap: var(--padding-md);
}

.mfa-method-card .mfa-method-card-inner .mfa-method-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.mfa-method-card .mfa-method-card-inner .mfa-method-header .method-title {
  font-weight: 700;
  font-size: 18px;
}

.mfa-method-card .mfa-method-card-inner .mfa-method-header .status.verified .fa {
  color: var(--success-color);
}

.mfa-method-card .mfa-method-card-inner .mfa-method-header .status.pending .fa {
  color: var(--warning-color);
}

.mfa-method-card .mfa-method-card-inner .mfa-method-card-actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: var(--margin-md);
}

/******************/
/* MFA Setup View */
/******************/

.mfa-setup-view {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: var(--padding-lg);
}

.mfa-setup-view .mfa-options {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  gap: var(--padding-md);
  margin-bottom: var(--margin-md);
}

.mfa-setup-view .mfa-options .mfa-option {
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: var(--padding-lg);
  padding: var(--padding-md);
}

.mfa-setup-view .mfa-options .mfa-option > .fa {
  font-size: 32px;
}

.mfa-setup-view .mfa-options .mfa-option > .fa.fa-qrcode {
  color: var(--secondary-color);
}

.mfa-setup-view .mfa-options .mfa-option > .fa.fa-at {
  color: var(--grey-color);
}

.mfa-setup-view .mfa-options .mfa-option .mfa-option-details {
  flex: 1;
}

.mfa-setup-view .mfa-options .mfa-option .mfa-option-details .mfa-option-title {
  font-weight: 700;
  margin-bottom: var(--margin-xs);
}

.mfa-setup-view .mfa-options .mfa-option .mfa-option-details .mfa-option-description {
  font-size: var(--semi-small-font-size);
}

.mfa-setup-view .mfa-options .mfa-options-divider {
  border-inline-start: 1px solid #dee2e6;
  height: auto;
  align-self: stretch;
}

.mfa-setup-view .mfa-actions-btns {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--padding-md);
}

.mfa-setup-view .mfa-actions-btns .btn {
  font-weight: 700;
}

.mfa-actions-btns .btn-link {
  background-color: transparent;
  border-color: transparent;
  color: var(--black-color);
}

.mfa-actions-btns .btn-link:hover {
  text-decoration: none;
}

/*************************/
/* MFA Setup Walkthrough */
/*************************/

.mfa-setup-walkthrough {
  max-width: 720px;
  padding: var(--padding-xl) var(--padding-lg);
  margin: 0 auto;
  overflow-x: hidden;
}

.mfa-setup-walkthrough .container-sm {
  max-width: 540px;
  margin: 0 auto;
}

/*********************/
/* MFA Select Method */
/*********************/

.mfa-select-method-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  gap: var(--padding-lg);
}

.mfa-select-method-container > h2 {
  font-weight: 700;
  font-size: 26px;
  margin: 0;
}

.mfa-select-method-container .mfa-methods-container {
  margin: var(--margin-lg) 0;
  border: 1px solid var(--white-dark);
  border-radius: var(--radius-lg);
  overflow: hidden;
  background-color: #fff;
}

.mfa-select-method-container .mfa-methods-container label.method-option-label {
  padding-left: var(--padding-lg);
  display: flex;
  font-weight: normal;
  gap: var(--padding-lg);
  transition: background-color 0.2s linear;
  margin: 0;
}

.mfa-select-method-container .mfa-methods-container label.method-option-label .alert {
  margin-bottom: 0;
  margin-top: 5px;
  padding: 5px 10px;
  text-align: center;
}

.mfa-select-method-container .mfa-methods-container label.method-option-label.disabled {
  opacity: 0.6;
  background-color: var(--white-base);
}

.mfa-select-method-container .mfa-methods-container label.method-option-label:not(.disabled).selected {
  background-color: var(--primary-highlight-color);
}

.mfa-select-method-container .mfa-methods-container label.method-option-label:not(.selected):not(.disabled):hover {
  cursor: pointer;
  background-color: var(--white-dark);
}

.mfa-select-method-container .mfa-methods-container label.method-option-label .radio-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.mfa-select-method-container .mfa-methods-container label.method-option-label .radio-wrapper > input[type="radio"] {
  appearance: none;
  height: 18px;
  width: 18px;
  border-radius: 50%;
  outline: none;
  box-shadow: 0 0 0 1px var(--grey-light);
  background-color: var(--white-light);
}

.mfa-select-method-container .mfa-methods-container label.method-option-label .radio-wrapper > input[type="radio"]:checked {
  box-shadow: 0 0 0 1px var(--primary-color);
}

.mfa-select-method-container .mfa-methods-container label.method-option-label .radio-wrapper > input[type='radio']:before {
  content: '';
  display: block;
  width: 60%;
  height: 60%;
  margin: 20% auto;
  border-radius: 50%;
}

.mfa-select-method-container .mfa-methods-container label.method-option-label .radio-wrapper > input[type='radio']:checked:before {
  background: var(--primary-color);
}

.mfa-select-method-container .mfa-methods-container label.method-option-label:not(.selected) .radio-wrapper > input[type="radio"]:hover {
  cursor: pointer;
}

.mfa-select-method-container .mfa-methods-container label.method-option-label .option-details-container {
  flex: 1;
  padding: var(--padding-lg) var(--padding-md) var(--padding-lg) 0;
}

.mfa-select-method-container .mfa-methods-container label.method-option-label .option-details-container .fa {
  font-size: 24px;
}

.mfa-select-method-container .mfa-methods-container label.method-option-label .option-details-container .option-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--padding-md);
  margin-bottom: var(--margin-xs);
}

.mfa-select-method-container .mfa-methods-container label.method-option-label .option-details-container .option-title .option-title-text {
  font-weight: 700;
  font-size: 18px;
}

.mfa-select-method-container .mfa-methods-container .options-separator {
  border-top: 1px solid var(--white-dark);
}

.mfa-select-method-container .mfa-select-actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

/****************************/
/* MFA Email Setup Complete */
/****************************/

.mfa-email-setup-complete {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: var(--padding-xl);
  gap: var(--padding-lg);
}

.mfa-email-setup-complete > .fa {
  color: var(--success-color);
  font-size: 60px;
}

.mfa-email-setup-complete > h2 {
  margin: 0;
  font-weight: 600;
}

/*****************************/
/* MFA Auth App Verification */
/*****************************/

.mfa-auth-app-verification {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  gap: var(--padding-lg);
}

.mfa-auth-app-verification .code-setup {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  gap: var(--padding-xl);
}

.mfa-auth-app-verification .step-title {
  font-size: 20px;
  font-weight: 600;
}

.mfa-auth-app-verification hr {
  border-top-color: var(--grey-light);
  width: 100%
}

.mfa-auth-app-verification .step-stack {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  gap: var(--padding-md);
}

.mfa-auth-app-verification .otp-verification-input {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: var(--margin-sm);
}

.mfa-auth-app-verification .verify-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: var(--margin-md);
}

/*******************************/
/* MFA Auth App Recovery Codes */
/*******************************/

.mfa-auth-app-recovery-codes {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;
  gap: var(--padding-xl);
}

.mfa-auth-app-recovery-codes > h2 {
  margin: 0;
}

.mfa-auth-app-recovery-codes > h2 > .fa {
  color: var(--success-color);
}

.mfa-auth-app-recovery-codes .recovery-codes-list > code {
  width: 100%;
  display: block;
  padding: var(--padding-lg);
  text-align: center;
  margin-bottom: var(--margin-md);
  background-color: var(--white-dark);
}

.mfa-auth-app-recovery-codes .recovery-codes-list .download-btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mfa-auth-app-recovery-codes .recovery-codes-list .download-btn-container a {
  font-size: 18px;
  font-weight: 600;
  text-decoration: none;
}

.mfa-auth-app-recovery-codes .recovery-codes-list > code > *:not(:last-child) {
  margin-bottom: var(--margin-xs);
}

.mfa-auth-app-recovery-codes .recovery-codes-confirmation > label {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: var(--padding-md);
}

.mfa-auth-app-recovery-codes .recovery-codes-confirmation > label > input {
  margin: 0;
  transform: scale(1.3);
}

/*******************/
/* MFA Setup Modal */
/*******************/

.mfa-setup-modal .dialog .dialog-header {
  border-bottom: none;
}

.mfa-setup-modal .dialog .dialog-header h1 .fa {
  color: var(--success-color);
}

/********************/
/* Remove MFA Modal */
/********************/

.remove-mfa-modal.modal .dialog {
  max-width: 600px;
}

.remove-mfa-modal .actions-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: var(--margin-md);
}

/****************************/
/* Remove MFA Devices Modal */
/****************************/

.mfa-remove-devices.modal .dialog {
  max-width: 600px;
}
