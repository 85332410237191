.user-profile.inactive-profile main {
  margin-top: var(--navigation-bar-height);
}

.patient-info h2 {
  padding-left: var(--padding-lg);
}

.patient-info .form-footer {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-right: var(--padding-lg);
}

.patients-navigation-button {
  margin-top: calc(var(--margin-sm) * 5);
  position: fixed;
  top: calc(var(--navigation-bar-height) * 2);
  left: 0;
  margin-left: 3%;
  margin-bottom: var(--margin-sm);
  z-index: 10;
}

.patients-navigation-button .btn {
  font-size: var(--h2-font-size);
  line-height: var(--h2-line-height);
}

.patients-navigation-button .btn:hover>.fa {
  margin-right: var(--margin-sm);
}

.patients-navigation-button .btn:hover:after {
  content: attr(data-title);
}

.patient-profile-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}

.patient-profile-header .patient-meta>h1 {
  margin-top: 0;
}

.patient-profile-header .profile-header-actions {
  margin-top: var(--margin-md);
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.patient-profile-header .profile-header-actions button {
  margin-left: 10px;
}

.tabs.patient-profile-tabs>.tabs-bar {
  flex-wrap: nowrap;
  overflow-y: scroll;
  width: 100%;
}

.tabs.patient-profile-tabs>.tabs-bar .tab-button {
  white-space: nowrap;
}

@media screen and (max-width: 850px) {
  .patients-navigation-button {
    position: relative;
    margin-top: 0;
    top: 0;
    left: 0;
    margin-left: 0;
  }
}