:root {
  --symptoms-scrollbar-height: 5px;
  --symptoms-key-column-width: 250px;
  --symptoms-key-column-min-width: 210px;
  --symptoms-score-column-min-width: 140px;
  --column-header-border-width: 2px;
}

.patient-symptom-updates .test-header {
  margin-bottom: var(--margin-lg);
  padding-bottom: var(--padding-lg);
  border-bottom: var(--standard-border-width) solid var(--grey-color);
}

.symptoms-update-table {
  width: 100%;
  display: flex;
  margin-top: var(--margin-lg);
  margin-bottom: var(--margin-lg);
}

.symptoms-update-table .symptom-keys {
  width: var(--symptoms-key-column-width);
  min-width: var(--symptoms-key-column-min-width);
  border-right: var(--standard-border-width) solid var(--primary-color);
}

.symptoms-update-table .symptoms-scores {
  overflow-x: auto;
}

.symptoms-update-table .symptoms-scores::-webkit-scrollbar-track {
  background-color: var(--mask-color);
}

.symptoms-update-table .symptoms-scores::-webkit-scrollbar {
  height: var(--symptoms-scrollbar-height);
  background-color: var(--grey-color);
}

.symptoms-update-table .symptoms-scores::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
}

.symptoms-update-table .submitted-update-scores {
  display: flex;
}

.symptoms-update-table .submitted-update-scores .symptoms-update-column {
  min-width: var(--symptoms-score-column-min-width);
}

.symptoms-update-table .symptoms-update-column .symptoms-update-scores {
  text-align: center;
}

.symptoms-update-table .symptoms-update-column .symptoms-column-header {
  font-weight: var(--bold-font-weight);
  padding-top: var(--padding-sm);
  padding-left: var(--padding-sm);
  padding-bottom: var(--padding-sm);
  border-bottom: var(--column-header-border-width) solid var(--primary-color);
}

.symptoms-update-table .submitted-update-scores .symptoms-update-column .symptoms-column-header {
  padding-left: var(--padding-lg);
  padding-right: var(--padding-lg);
  text-align: center;
}

.symptoms-update-table .symptoms-update-column .symptom-keys-column>*,
.symptoms-update-table .symptoms-update-column .symptoms-update-scores>* {
  padding: var(--padding-sm);
  border-bottom: var(--standard-border-width) solid var(--grey-light);
}

.symptoms-update-table .symptoms-update-column .symptom-keys-column>*:last-child,
.symptoms-update-table .symptoms-update-column .symptoms-update-scores>*:last-child {
  border-bottom: none;
}


.symptoms-update-table .submitted-update-scores .symptoms-update-column:nth-child(odd) {
  background-color: var(--white-dark);
}

.symptoms-update-table .symptoms-update-column .symptoms-update-total {
  font-weight: var(--bold-font-weight);
}

.symptoms-update-table .symptoms-update-column .symptom-keys-column .symptoms-update-total {
  text-align: right;
}

.symptoms-update-table .symptoms-update-column .symptoms-update-scores .symptoms-update-total {
  color: var(--primary-color);
}