.injury-history > li > h3 {
  padding-bottom: var(--padding-sm);
  border-bottom-width: var(--standard-border-width);
  border-bottom-style: var(--standard-border-style);
  border-bottom-color: var(--standard-border-color);
}

.injury-history > li {
  margin-bottom: calc(var(--margin-lg) * 2);
}

.injury-history .injury-status-selector {
  display: inline-block;
  width: auto;
}

.injury-history > li > .injury-history-section.injury-history-meta {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
}

.injury-history > li > .injury-list-item-header h3 .fa.fa-folder-open-o {
  color: var(--secondary-color);
}

.injury-history>li>.injury-history-section.injury-history-meta>.injury-status {
  flex: 1;
}

.injury-history>li>.injury-history-section.injury-history-meta>.referral-section {
  text-align: center;
}

.injury-history>li>.injury-history-section.injury-history-meta>.referral-section .referrals-link {
  display: flex;
  align-items: center;
}

.injury-history>li>.injury-history-section.injury-history-meta>.referral-section .referrals-link .badge {
  margin-left: var(--margin-sm);
}

.injury-history>li>.injury-history-section {
  margin-left: var(--margin-lg);
}

.injury-history>li>.injury-history-section>ul {
  margin-bottom: var(--margin-lg);
}

.injury-history>li>.injury-history-section>ul>li>.fa {
  display: inline-block;
  width: 18px;
}

.injury-history .injury-history-section.injury-history-diagnosis h3 {
  margin-top: 0;
}

.injury-history .injury-history-section.injury-history-diagnosis ul {
  margin-bottom: var(--margin-lg);
}

.user-injury-history .concussion-count .count-total {
  margin-right: var(--margin-sm);
  margin-left: var(--margin-sm);
}

.injury-list-item-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--grey-light);
  margin-bottom: var(--margin-lg);
}

.injury-list-item-header>h3 {
  margin-bottom: var(--margin-sm);
  margin-top: var(--margin-sm);
}

.injury-list-item-header>span {
  font-size: var(--semi-small-font-size);
  line-height: var(--semi-small-line-height);
}