@media print {

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: var(--margin-sm);
  }

  p {
    margin-bottom: var(--margin-sm);
  }

  a {
    color: var(--black-color);
  }

}