.image-cropper {
  margin-bottom: var(--margin-lg);
}

.image-cropper .image-cropper-content {
  display: flex;
  justify-content: center;
  padding: var(--padding-sm);
  margin-bottom: var(--margin-sm);
  border-width: var(--input-border-width);
  border-radius: var(--input-border-radius);
  border-style: var(--input-border-style);
  border-color: var(--input-border-color);
  background-color: rgba(0, 0, 0, 0.5);
}

.image-cropper .image-cropper-content .image-cropper-view {
  margin-bottom: 0;
  border-width: 0;
}

.image-cropper .image-cropper-content .image-cropper-view .cropper-container {
  background-color: #FFF;
}

.image-cropper .image-cropper-controls button {
  display: block;
  width: 100%;
}